<div class="address-edit-plugin">
    <h2>Éditer l'adresse</h2>
    <form [formGroup]="addressForm" (ngSubmit)="save()">
      <div class="form-group">
        <label for="deliveryPoint">Point de livraison:</label>
        <input id="deliveryPoint" formControlName="deliveryPoint" type="text" />
      </div>
  
      <div class="form-group">
        <label for="buildingLocation">Emplacement du bâtiment:</label>
        <input id="buildingLocation" formControlName="buildingLocation" type="text" />
      </div>
  
      <div class="form-group">
        <label for="streetNumber">Numéro de rue:</label>
        <input id="streetNumber" formControlName="streetNumber" type="text" required />
      </div>
  
      <div class="form-group">
        <label for="streetType">Type de rue:</label>
        <input id="streetType" formControlName="streetType" type="text" />
      </div>
  
      <div class="form-group">
        <label for="streetName">Nom de rue:</label>
        <input id="streetName" formControlName="streetName" type="text" required />
      </div>
  
      <div class="form-group">
        <label for="deliveryService">Service de livraison:</label>
        <input id="deliveryService" formControlName="deliveryService" type="text" />
      </div>
  
      <div class="form-group">
        <label for="postalCode">Code postal:</label>
        <input id="postalCode" formControlName="postalCode" type="text" required />
      </div>
  
      <div class="form-group">
        <label for="city">Ville:</label>
        <input id="city" formControlName="city" type="text" required />
      </div>
  
      <div class="form-group">
        <label for="country">Pays:</label>
        <input id="country" formControlName="country" type="text" required />
      </div>
  
      <div class="form-group">
        <label for="timezone">Fuseau horaire:</label>
        <input id="timezone" formControlName="timezone" type="text" />
      </div>
  
      <div class="action-buttons">
        <button type="submit" class="btn-save">Sauvegarder</button>
        <button type="button" class="btn-cancel" (click)="cancel()">Annuler</button>
      </div>
    </form>
  </div>
  