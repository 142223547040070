import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ILabelDto } from '../../label.dto';
import { LabelService } from '../../label.service';

@Component({
  selector: 'plugin-label-edit',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
  ],
  templateUrl: './label-edit.plugin.html',
  styleUrl: './label-edit.plugin.scss'
})
export class LabelEditPlugin implements OnInit, OnChanges {
  @Input() selectedLabel: ILabelDto | null = null;
  @Input() isNew: boolean = false; // Ajout de cet input
  @Output() saved = new EventEmitter<ILabelDto | null>();
  @Output() cancelled = new EventEmitter<void>();

  editLabel: ILabelDto = {
    id: undefined,
    group: '',
    code: '',
    labelLong: '',
    defaultLabel: false
  };

  constructor(private labelService: LabelService) {}

  ngOnInit(): void {
    this.resetForm();
  }

  ngOnChanges(): void {
    if (this.selectedLabel) {
      this.editLabel = { ...this.selectedLabel };
    } else {
      this.resetForm();
    }
  }

  resetForm(): void {
    this.editLabel = {
      id: undefined,
      group: '',
      code: '',
      labelLong: '',
      defaultLabel: false
    };
  }

  saveLabel(): void {
    if (this.isNew) {
      this.createLabel();
    } else if (this.editLabel.id !== undefined) {
      this.updateLabel();
    }
  }

  private createLabel(): void {
    this.labelService.createLabel(this.editLabel).subscribe({
      next: (createdLabel) => {
        this.saved.emit(createdLabel);
        console.log('Label créé:', createdLabel);
        this.resetForm(); // Réinitialiser le formulaire après création
      },
      error: (error) => {
        console.error('Erreur lors de la création du label:', error);
        // Gérer l'erreur, par exemple en affichant un message à l'utilisateur
      }
    });
  }

  private updateLabel(): void {
    if (this.editLabel.id !== undefined) {
      this.labelService.updateLabel(this.editLabel.id, this.editLabel).subscribe({
        next: (updatedLabel) => {
          this.saved.emit(updatedLabel);
          console.log('Label mis à jour:', updatedLabel);
          // Vous pouvez choisir de réinitialiser le formulaire ou non après la mise à jour
        },
        error: (error) => {
          console.error('Erreur lors de la mise à jour du label:', error);
          // Gérer l'erreur, par exemple en affichant un message à l'utilisateur
        }
      });
    }
  }

  cancelEdit(): void {
    this.resetForm();
  }
}
