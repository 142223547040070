<div *ngIf="lease && lease.id && lease.id !== 0">
    <div class="lease-status-manager">
      <div class="current-status">
        <p>État actuel du bail : {{ lease.status }}</p>
      </div>
      
      <div class="status-selector">
        <label for="statusSelect">Changer l'état :</label>
        <select id="statusSelect" [(ngModel)]="nextStatus">
          <option *ngFor="let status of listNextStatus" [value]="status">{{ status }}</option>
        </select>
      </div>
  
      <!-- Case à cocher pour afficher tous les statuts -->
      <div class="show-all-statuses">
        <label>
          <input type="checkbox" [(ngModel)]="showAllStatuses" (change)="refreshStatusList()" />
          Afficher tous les statuts
        </label>
      </div>
      
      <button (click)="changeStatus()" [disabled]="!nextStatus || nextStatus === lease.status">
        Appliquer le changement
      </button>
    </div>
  </div>
  