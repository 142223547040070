import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'plugin-rent-receipt-dl',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './rent-receipt-download.plugin.html',
  styleUrl: './rent-receipt-download.plugin.scss'
})
export class RentReceiptDownloadPlugin {
  @Input() idOperation!: number;
  @Output() onPrint = new EventEmitter<number>();

  downloadReceipt() {
    console.log(`Demande de téléchargement de la quittance pour l'opération ID: ${this.idOperation}`);
    this.onPrint.emit(this.idOperation);
  }
}
