import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IAccommodationDto } from '../../accommodation.dto';
import { Accommodation } from '../../accommodation.class';
import { CommonModule } from '@angular/common';
import { AccommodationEditPlugin } from '../edit/accommodation-edit.plugin';

@Component({
  selector: 'plugin-accommodation-view',
  standalone: true,
  imports: [
    CommonModule,
    AccommodationEditPlugin,
  ],
  templateUrl: './accommodation-view.plugin.html',
  styleUrl: './accommodation-view.plugin.scss'
})
export class AccommodationViewPlugin {
  public accommodation: Accommodation = new Accommodation();

  @Input()
  set accommodationDto(value: IAccommodationDto) {
    this.loadDto(value);
  }

  constructor() {}

  loadDto(dto: IAccommodationDto) {
    console.log('on charge view', dto);
    this.accommodation = new Accommodation(dto);
  }
}
