<div>
    <p class="lease-details">{{lease.id}} {{lease.accommodation?.name}}</p>
    <div class="operations-table-container">
        <table class="operations-table">
            <ng-container *ngFor="let op of list">
                <tr (click)="click(op)" (dblclick)="dblClick(op)" [class.selected]="isSelected(op)">
                    <td rowspan="2">{{ op.refYear }}-{{ op.refMonth }}</td>
                    <td>{{ op.startLocale?.date }}</td>
                    <td>{{ op.endLocale?.date }}</td>
                    <!-- <td>{{ op.status }}</td> -->
                </tr>
                <tr (click)="click(op)" (dblclick)="dblClick(op)" [class.selected]="isSelected(op)">
                    <td colspan="3">
                        Rent: {{ op.rentAmount }} | Fees: {{ op.feesAmount }}
                    </td>
                </tr>
            </ng-container>
        </table>
    </div>

    <!-- Bouton Créer -->
    <button class="btn-create" [disabled]="!selectedRent" (click)="createRent()">
        Créer
    </button>
</div>
