import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContactPickerPlugin } from '../../plugin/picker/contact-picker.plugin';
import { Contact } from '../../contact.class';
import { ContactTitle } from '../../contact-title.class';
import { ContactService } from '../../contact.service';
import { IContactDto } from '../../contact.dto';


@Component({
  selector: 'route-contact-list',
  templateUrl: './contact-list.route.html',
  styleUrls: ['./contact-list.route.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ContactPickerPlugin,
  ]
})
export class ContactListRoute implements OnInit {
  contacts: Contact[] = [];
  titles: ContactTitle[] = [];

  constructor(
    private contactService: ContactService,
    private router: Router,
  ) {}

  ngOnInit(): void {}

  // clic sur bouton action d'un contact --> navigation vers le détail du contact
  viewContact(dto: IContactDto): void {
    const contact: Contact = new Contact(dto);
    this.router.navigate(['contact/view', contact.id]);
  }

  // clic sur bouton action global nouveau contact --> navigation vers le détail du contact (sans id)
   addNewContact(): void {
    this.router.navigate(['contact/new']);
  }
}
