import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'plugin-rent-receipt-gdrive',
  standalone: true,
  imports: [],
  templateUrl: './rent-receipt-gdrive.plugin.html',
  styleUrl: './rent-receipt-gdrive.plugin.scss'
})
export class RentReceiptGDrivePlugin {
  @Input() idOperation!: number;
  @Output() onSendToGDrive = new EventEmitter<number>();

  sendToGDrive() {
    console.log(`Demande d'envoi de la quittance vers Google Drive pour l'opération ID: ${this.idOperation}`);
    this.onSendToGDrive.emit(this.idOperation);
  }
}
