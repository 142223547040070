import { CommonModule, DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Observable } from 'rxjs';
import { ContactSelectPlugin } from '../../../../contact/plugin/select/contact-select.plugin';
import { IInvoiceHeaderDto } from '../../invoice-header.dto';
import { ITagDto } from '../../../../tag/tag.dto';
import { InvoiceHeader } from '../../invoice-header.class';
import { ILabelDto } from '../../../../global/label/label.dto';
import { InvoiceService } from '../../invoice-header.service';
import { LabelService } from '../../../../global/label/label.service';
import { constants } from '../../../../config';
import { Tag } from '../../../../tag/tag.class';
import { OccupancySelectWidget } from '../../../../contact/occupancy/widget/occupancy-select/occupancy-select.widget';

@Component({
  selector: 'plugin-invoice-edit',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ContactSelectPlugin,
    OccupancySelectWidget 
  ],
  providers: [DatePipe],
  templateUrl: './invoice-edit.plugin.html',
  styleUrl: './invoice-edit.plugin.scss'
})
/**
 * Le tag BU définit le filtre à appliquer aux listes de contacts
 * et à la création d'une invoice
 */
export class InvoiceEditPlugin implements OnInit {
  @Input() invoiceId: number | undefined;
  @Input() tagBUDto: ITagDto | undefined;
  @Output() saved = new EventEmitter<IInvoiceHeaderDto>();
  @Output() onCancel = new EventEmitter<void>();
  invoice: InvoiceHeader = new InvoiceHeader();
  isNewInvoice: boolean = true;
  invoiceTypes: ILabelDto[] = [];
  tagBU: Tag | undefined;

  constructor(
    private invoiceService: InvoiceService,
    private datePipe: DatePipe,
    private labelService: LabelService,
  ) {}

  ngOnInit(): void {
    this.loadInvoiceTypes();
    if (this.tagBUDto) {
      this.tagBU = new Tag(this.tagBUDto);
      console.log('Tag BU initialized:', this.tagBU);
    }
    if (this.invoiceId) {
      this.loadInvoice();
    } else {
      this.isNewInvoice = true;
      if (this.tagBU) {
        this.invoice.tags = [this.tagBU]; // Ajouter le tag BU à une nouvelle facture
      }
    }
  }

  onIssuerChange(): void {
    this.invoice.idIssuerOccupancy = undefined; // Réinitialiser quand l’émetteur change
  }

  onRecipientChange(): void {
    this.invoice.idRecipientOccupancy = undefined; // Réinitialiser quand le destinataire change
  }
    
  private loadInvoiceTypes(): void {
    this.labelService.getLabels(constants.invoiceTypeGroup).subscribe({
      next: (types) => {
        this.invoiceTypes = types;
        console.log('Types de factures chargés:', this.invoiceTypes);
      },
      error: (error) => console.error('Erreur lors du chargement des types de factures:', error)
    });
  }

  private loadInvoice(): void {
    if (this.invoiceId) {
      this.invoiceService.get(this.invoiceId, { loadIssuer: true, loadRecipient: true }).subscribe(
        (invoice: InvoiceHeader | null) => {
          if (invoice) {
            this.invoice = invoice;
            this.isNewInvoice = false;
            console.log('Loaded invoice:', this.invoice);
          } else {
            console.error('Invoice not found with id:', this.invoiceId);
          }
        },
        error => console.error('Error loading invoice:', error)
      );
    }
  }

  saveInvoice(): void {
    if (this.invoice) {
      console.log('Saving invoice:', this.invoice);
      console.log('Is new invoice:', this.isNewInvoice);

      // Assurez-vous que le tag BU est présent dans les tags de l'invoice
      if (this.tagBU && !this.invoice.tags?.some(tag => tag.label === this.tagBU!.label)) {
        if (!this.invoice.tags) this.invoice.tags = [];
        this.invoice.tags.push(this.tagBU);
        console.log('Tag BU added to invoice:', this.tagBU);
      }

      let operation: Observable<InvoiceHeader>;
      
      if (this.isNewInvoice) {
        operation = this.invoiceService.create(this.invoice);
        console.log('Creating new invoice with DTO:', this.invoice.getDto());
      } else {
        operation = this.invoiceService.update(this.invoice.invoiceId, this.invoice);
        console.log('Updating invoice with DTO:', this.invoice.getDto());
      }

      operation.subscribe(
        (resultInvoice: InvoiceHeader) => {
          console.log('Saved invoice result:', resultInvoice);
          this.saved.emit(resultInvoice.getDto());
          this.invoice = resultInvoice;
        },
        error => console.error('Error saving invoice:', error)
      );
    }
  }

  cancel(): void {
    this.onCancel.emit();
  }
}