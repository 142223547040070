<div class="accommodation-edit">
  <h2>Éditer l'hébergement</h2>

  <!-- Formulaire d'édition d'hébergement -->
  <form (ngSubmit)="save()">
    <div>
      <label for="name">Nom :</label>
      <input id="name" [(ngModel)]="accommodation.name" name="name" type="text" />
    </div>

    <div>
      <label for="inlineDescription">Description rapide :</label>
      <textarea id="inlineDescription" [(ngModel)]="accommodation.inlineDescription" name="inlineDescription"></textarea>
    </div>

    <div>
      <label for="fullDescription">Description complète :</label>
      <textarea id="fullDescription" [(ngModel)]="accommodation.fullDescription" name="fullDescription"></textarea>
    </div>

    <div>
      <label for="excludedParts">Parties exclues :</label>
      <textarea id="excludedParts" [(ngModel)]="accommodation.excludedParts" name="excludedParts"></textarea>
    </div>

    <div>
      <label for="recentWorkDescription">Derniers travaux :</label>
      <textarea id="recentWorkDescription" [(ngModel)]="accommodation.recentWorkDescription" name="recentWorkDescription"></textarea>
    </div>

    <div>
      <label for="previousRentInformation">Informations sur les loyers précédents :</label>
      <textarea id="previousRentInformation" [(ngModel)]="accommodation.previousRentInformation" name="previousRentInformation"></textarea>
    </div>

    <div>
      <label for="rentByMonthReference">Loyer de référence :</label>
      <input id="rentByMonthReference" [(ngModel)]="accommodation.rentByMonthReference" name="rentByMonthReference" type="number" />
    </div>

    <div>
      <label for="feesByMonthReference">Frais mensuels de référence :</label>
      <input id="feesByMonthReference" [(ngModel)]="accommodation.feesByMonthReference" name="feesByMonthReference" type="number" />
    </div>

    <!-- Adresse : utilisation du plugin address-instanciate -->
    <h3>Adresse</h3>
    <div *ngIf="!addressEditMode">
      <plugin-address-view *ngIf="accommodation.address" [addressDto]="accommodation.address.getDto()"></plugin-address-view>
      <p *ngIf="!accommodation.address">Aucune adresse disponible.</p>
      <button type="button" (click)="toggleAddressEditMode()">Modifier l'adresse</button>
    </div>

    <!-- Si on est en mode édition de l'adresse -->
    <div *ngIf="addressEditMode">
      <plugin-address-instanciate
        [addressDto]="addressDto"
        (onSave)="saveAddress($event)">
      </plugin-address-instanciate>
      <button type="button" (click)="toggleAddressEditMode()">Annuler</button>
    </div>

    <!-- Boutons de sauvegarde et d'annulation -->
    <div class="button-container">
      <button type="submit">Sauvegarder</button>
      <button type="button" (click)="cancel()">Annuler</button>
    </div>
  </form>
</div>
