import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ContactEmail } from '../../contact-email.class';
import { ContactEmailService, IContactEmailRequestOptions } from '../../contact-email.service';
import { IContactEmailDto } from '../../contact-email.dto';

@Component({
  selector: 'plugin-contact-email-picker',
  standalone: true,
  imports: [
    CommonModule,
  ],
  templateUrl: './contact-email.picker.html',
  styleUrl: './contact-email.picker.scss'
})
export class ContactEmailPicker {
  public list: ContactEmail[] = [];
  private _options: IContactEmailRequestOptions = {};
  private dataLoaded: boolean = false;

  @Input()
  set options(value: IContactEmailRequestOptions) {
    this._options = value;
    this.loadData();
  }
  get options(): IContactEmailRequestOptions {
    return this._options;
  }

  @Output() onDelete: EventEmitter<IContactEmailDto> = new EventEmitter(); // New output event for deletion

  constructor(
    private contactPhoneService: ContactEmailService,
  ) {}

  loadData() {
    if (!this.dataLoaded && this.options && this.options.selectIdPerson && this.options.selectIdPerson !== 0) {
      this.contactPhoneService.list(this.options).subscribe(l => {
        if (l) {
          this.list = l;
          this.dataLoaded = true;
        }
      }, error => {
        console.error('Error loading contact phone list:', error); // Log any error from the API
      });
    }
  }

  reloadData() {
    this.dataLoaded = false;
    this.loadData();
  }

  deleteEmail(email: ContactEmail) {
    console.log('Delete email:', email);
    console.log(email.getDto());
    this.onDelete.emit(email.getDto()); // Emit the email to be deleted
  }
}
