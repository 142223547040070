<div>
  <h3>{{ isNewItem ? 'Créer l\'élément de facture' : 'Éditer l\'élément de facture' }}</h3>
  <form (ngSubmit)="saveItem()">
    <div>
      <label>Reference:</label>
      <input [(ngModel)]="item.reference" name="reference" placeholder="Reference">
    </div>
    <div>
      <label>Description:</label>
      <input [(ngModel)]="item.description" name="description" placeholder="Description">
    </div>
    <div>
      <label>Quantité:</label>
      <input type="number" [(ngModel)]="item.quantity" name="quantity" placeholder="Quantité">
    </div>

    <button type="submit" class="btn-save">Sauvegarder</button>
    <button type="button" (click)="deleteItem()" *ngIf="!isNewItem" class="btn-delete">Supprimer</button>
  </form>

  <plugin-invoice-item-vat-picker 
    [itemId]="item.id" 
    (vatSaved)="saveVat($event)" 
    (vatDeleted)="removeVat($event)">
  </plugin-invoice-item-vat-picker>

  <div>
    <p>Total HT: {{ item.totalExcludingVAT | number:'1.2-2' }} €</p>
    <p>Total TVA: {{ item.totalVAT | number:'1.2-2' }} €</p>
    <p>Total: {{ item.total | number:'1.2-2' }} €</p>
  </div>
</div>