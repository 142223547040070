import { addDays, differenceInDays, endOfMonth, getDaysInMonth, parseISO, startOfDay } from "date-fns";
import { format, formatInTimeZone } from "date-fns-tz";

export interface ILocaleDate {
    date: string,
    timezone: string,
}

export class LocaleDate implements ILocaleDate {
    _date: string;
    _timezone: string;

    constructor(date: string | ILocaleDate, timezone: string = Intl.DateTimeFormat().resolvedOptions().timeZone) {
        if (typeof date === 'string') {
            this._date = date;
            this._timezone = timezone;
        } else {
            this._date = date.date;
            this._timezone = date.timezone;
        }
    }

    set date(value: string) {
        const regex = /^(\d{4})-(\d{2})-(\d{2})$/;
        if (!regex.test(value)) {
            throw new Error("Invalid date format. Use 'AAAA-MM-JJ' format.");
        }
        this._date = value;
    }

    get date(): string {
        return this._date;
    }

    set timezone(value: string) {
        this._timezone = value;
    }

    get timezone(): string {
        return this._timezone;
    }

    formatDate(): string {
        // Parse the date string to a Date object
        const parsedDate = parseISO(this._date);
        // Format the date in the specified time zone
        return formatInTimeZone(parsedDate, this._timezone, 'yyyy-MM-dd');
    }

    get year(): number {
        return parseInt(this._date.substr(0, 4));
    }

    get month(): number {
        return parseInt(this._date.substr(5, 2));
    }

    get day(): number {
        return parseInt(this._date.substr(8, 2));
    }

    get isValid(): boolean {
        return /^(\d{4})-(\d{2})-(\d{2})$/.test(this._date);
    }

    localMidnightUTC(): string {
        const parsedDate = startOfDay(parseISO(this._date));
        return parsedDate.toISOString();
    }

    isEqualAtMidnight(other: LocaleDate): boolean {
        return this.localMidnightUTC() === other.localMidnightUTC();
    }

    getLastDayOfMonth(): LocaleDate {
        const parsedDate = endOfMonth(parseISO(this._date));
        return new LocaleDate(format(parsedDate, 'yyyy-MM-dd'), this._timezone);
    }

    addDays(numberOfDays: number): LocaleDate {
        const parsedDate = addDays(parseISO(this._date), numberOfDays);
        return new LocaleDate(format(parsedDate, 'yyyy-MM-dd'), this._timezone);
    }

    differenceInDays(other: LocaleDate, includeEndDate: boolean = false): number {
        return differenceInDays(other.dateUTC(), this.dateUTC()) + (includeEndDate ? 1 : 0);
    }

    getDto(): ILocaleDate {
        return {
            date: this.date,
            timezone: this.timezone,
        };
    }

    daysInMonth(): number {
        return getDaysInMonth(this.dateUTC());
    }

    dateUTC(): Date {
        const d: Date = new Date(Date.UTC(this.year, this.month-1, this.day));
        return d;
    }
}
