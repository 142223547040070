import { IOwnershipDto } from "./ownership.dto";

export class Ownership {
    id?: number;
    idAccommodation: number = 0;
    idPerson: number = 0;
    dtStart?: Date | null;
    dtEnd?: Date | null;
    isActive?: boolean;

    constructor(dto?: IOwnershipDto) {
        if (dto) this.loadDto(dto);
    }

    public getDto(): IOwnershipDto {
        return {
            idOwnership: this.id,
            idAccommodation: this.idAccommodation,
            idPerson: this.idPerson,
            dtStart: this.dtStart,
            dtEnd: this.dtEnd,
            isActive: this.isActive
        };
    }

    public loadDto(dto: IOwnershipDto) {
        this.id = dto.idOwnership;
        this.idAccommodation = dto.idAccommodation;
        this.idPerson = dto.idPerson;
        this.dtStart = dto.dtStart ? new Date(dto.dtStart) : null;
        this.dtEnd = dto.dtEnd ? new Date(dto.dtEnd) : null;
        this.isActive = dto.isActive;
    }
}
