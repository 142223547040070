<form class="label-edit-form">
    <div>
      <label for="group">Groupe:</label>
      <input id="group" type="text" [(ngModel)]="editLabel.group" name="group" required>
    </div>
    <div>
      <label for="code">Code:</label>
      <input id="code" type="text" [(ngModel)]="editLabel.code" name="code" required>
    </div>
    <div>
      <label for="labelLong">Libellé long:</label>
      <input id="labelLong" type="text" [(ngModel)]="editLabel.labelLong" name="labelLong">
    </div>
    <div>
      <label for="defaultLabel">Label par défaut:</label>
      <input id="defaultLabel" type="checkbox" [(ngModel)]="editLabel.defaultLabel" name="defaultLabel">
    </div>
    <button type="button" (click)="saveLabel()" [disabled]="!editLabel.group || !editLabel.code">Sauvegarder</button>
    <button type="button" (click)="cancelEdit()">Annuler</button>
  </form>