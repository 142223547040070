import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';  // Nécessaire pour les directives comme *ngIf et *ngFor
import { Lease } from '../../../../rental/lease/lease.class';
import { Rent } from '../../rent.class';
import { RentService } from '../../rent.service';
import { ILeaseDto } from '../../../../rental/lease/lease.dto';
import { IOperationDto } from '../../../../operation/operation.dto';

@Component({
  selector: 'plugin-rent-to-issue-picker',
  standalone: true,
  imports: [
    CommonModule
  ], 
  templateUrl: './rent-to-issue-picker.plugin.html',
  styleUrls: ['./rent-to-issue-picker.plugin.scss'],
})
export class RentToIssuePickerPlugin {
  @Input()
  set leaseDto(value: ILeaseDto) {
    this.loadLease(value);
  }

  @Output() selected = new EventEmitter<IOperationDto>();
  @Output() activated = new EventEmitter<IOperationDto>();

  lease: Lease = new Lease();  
  list: Rent[] = [];
  loaded: boolean = false;
  selectedRent: Rent | null = null;  

  constructor(
    private rentService: RentService,
  ) {}

  loadLease(dto: ILeaseDto) {
    if (this.loaded) {
      if (this.lease.id !== dto.idLease) {
        this.loaded = false;      // refresh
      }
    }
    if (!this.loaded) {
      this.lease = new Lease(dto);
      this.loadList();
    }
  }

  loadList() {
    if (this.lease.id && !this.loaded) {
      this.rentService.listRentToIssue(this.lease.id).subscribe(l => 
        {
          if (l) {
            this.list = l;
            this.loaded = true;
          }
          console.log('rent 2 issue list', l);
        }
      );
    }
  }

  /**
   * single click
   */
  click(rent: Rent) {
    this.selectedRent = rent;  // Met à jour l'élément sélectionné
    this.selected.emit(rent.getDto());
  }

  /**
   * double click
   */
  dblClick(rent: Rent) {
    this.selectedRent = rent; 
    this.activated.emit(rent.getDto());
  }

  isSelected(rent: Rent): boolean {
    return this.selectedRent === rent;
  }

  createRent() {
    if (this.selectedRent) {
      this.activated.emit(this.selectedRent.getDto());
    }
  }
}
