import { IInvoiceItemVatDto } from "./invoice-item-vat.dto";

export class InvoiceItemVat {
    id?: number;
    idItem?: number | null;
    codeVAT?: number | null;
    rateVAT?: number | null;
    amountExcludingVAT?: number | null;
    amountVAT?: number | null;
    amount?: number | null;
  
    constructor(dto?: IInvoiceItemVatDto) {
      if (dto) this.loadDto(dto);
    }
  
    private loadDto(dto: IInvoiceItemVatDto): void {
      for (const key in dto) {
        if (dto.hasOwnProperty(key)) {
          (this as any)[key] = (dto as any)[key];
        }
      }
    }
  
    public getDto(): IInvoiceItemVatDto {
      return {
        id: this.id,
        idItem: this.idItem,
        codeVAT: this.codeVAT,
        rateVAT: this.rateVAT,
        amountExcludingVAT: this.amountExcludingVAT,
        amountVAT: this.amountVAT,
        amount: this.amount
      };
    }
  }