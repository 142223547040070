import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, map, tap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { IAccommodationDto } from './accommodation.dto';
import { Accommodation } from './accommodation.class';
import { ApiService } from '../../api/api.service';

@Injectable({
  providedIn: 'root'
})
export class AccommodationService {
  private currentSubjet = new BehaviorSubject<IAccommodationDto | null>(null);
  public current$ = this.currentSubjet.asObservable();

  constructor(
    private apiService: ApiService,
  ) {}

  updateCurrent(dto: IAccommodationDto | null) {
    this.currentSubjet.next(dto);
  }

  list(
    options: IAccommodationRequestOptions = {},
  ): Observable<Accommodation[] | null> {
    const url = `${environment.api.url}${environment.api.endpoint.accommodationList}`;
    const params = new HttpParams()
      .set('add', options.loadAddress ? '1' : '0') 
      .set('own', options.loadOwnershipList ? '1' : '0'); 
    return this.apiService.get<IAccommodationDto[]>(url, { params }).pipe(
      tap(dtos => console.log("Received DTOs:", dtos)), // Log the raw DTOs received
      map(dtos => {
        const accommodations = dtos.map(dto => {
          const accommodation = new Accommodation(dto);
          return accommodation;
        });
        return accommodations;
      }),
    );
  }

  get(
    id: number,
    options: IAccommodationRequestOptions = {},
  ): Observable<Accommodation | null> {
    const url: string = `${environment.api.url}${environment.api.endpoint.accommodationGet}/a/${id}`;
    const params = new HttpParams()
      .set('own', options.loadOwnershipList ? +options.loadOwnershipList : 0)
      .set('add', options.loadAddress ? +options.loadAddress : 0);
    return this.apiService.get<IAccommodationDto>(url, { params }).pipe(
      map(dto => new Accommodation(dto))
    );
  }

  /**
   * Gère l'enregistrement d'une accommodation
   * une accommodation peut avoir une adresse
   * une accommodation peut être liée à une liste de ownership
   * @param data    : entité à sauvegarder ou créer
   * @param options : précise les éléments à sauvegarder
   */
  save(accommodation: Accommodation): Observable<Accommodation> {
    console.log('Début de la sauvegarde de l\'accommodation:', accommodation);
  
    const url: string = `${environment.api.url}${environment.api.endpoint.accommodationSave}`;
    const accommodationDto = accommodation.getDto();
    
    console.log('URL de la requête:', url);
    console.log('Données envoyées pour la sauvegarde:', accommodationDto);
  
    return this.apiService.post<IAccommodationDto>(url, accommodationDto).pipe(
      map(dto => {
        console.log('Réponse reçue après sauvegarde:', dto);
        return new Accommodation(dto);
      }),
      catchError(error => {
        console.error('Erreur lors de la sauvegarde:', error);
        throw error; // Relancer l'erreur après le log
      })
    );
  }
  
}

export interface IAccommodationRequestOptions {
  loadAddress?: boolean,
  loadOwnershipList?: boolean,
  selectButtonLabel?: string,
}
