<div class="contact-edit">
  <h2>{{ contact.id === 0 ? 'Créer un contact' : 'Éditer le contact' }}</h2>

  <form (ngSubmit)="saveContact()" #contactForm="ngForm">
    <div class="form-group">
      <label for="title">Titre</label>
      <select id="title" [(ngModel)]="contact.idTitle" name="title" required>
        <option *ngFor="let title of titles" [value]="title.id">{{ title.longTitle }}</option>
      </select>
    </div>

    <div class="form-group">
      <label for="firstName">Prénom</label>
      <input type="text" id="firstName" [(ngModel)]="contact.firstName" name="firstName" required />
    </div>

    <div class="form-group">
      <label for="surname">Deuxième prénom</label>
      <input type="text" id="surname" [(ngModel)]="contact.surname" name="surname" />
    </div>

    <div class="form-group">
      <label for="lastName">Nom de famille</label>
      <input type="text" id="lastName" [(ngModel)]="contact.lastName" name="lastName" required />
    </div>

    <!-- Champ pour Company -->
    <div class="form-group">
      <label for="company">Entreprise</label>
      <input type="text" id="company" [(ngModel)]="contact.company" name="company" />
    </div>

    <!-- Champ pour Siret -->
    <div class="form-group">
      <label for="siret">SIRET</label>
      <input type="text" id="siret" [(ngModel)]="contact.siret" name="siret" />
    </div>

    <div class="action-buttons">
      <button type="submit" class="btn-save">{{ contact.id === 0 ? 'Créer' : 'Sauvegarder' }}</button>
      <button type="button" class="btn-cancel" (click)="cancel()">Annuler</button>
    </div>
  </form>
</div>
