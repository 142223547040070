import { Component, Input } from '@angular/core';
import { Rent } from '../../rent.class';
import { IOperationDto } from '../../../../operation/operation.dto';

@Component({
  selector: 'plugin-rent-view',
  standalone: true,
  imports: [],
  templateUrl: './rent-view.plugin.html',
  styleUrl: './rent-view.plugin.scss'
})
export class RentViewPlugin {
  rent: Rent = new Rent();
  @Input()
  set rentOperationDto(value: IOperationDto) {
    this.rent = new Rent(value);
    this.loadData();
  }

  constructor() {}

  loadData() {}

}
