<div class="lease-picker">
  <h2>Choisissez un bail</h2>
  
  <!-- Liste des baux -->
  <ul *ngIf="list.length > 0">
    <li *ngFor="let lease of list" 
        (click)="selectItem(lease)" 
        (dblclick)="activateItem(lease)" 
        [class.selected]="lease === selectedItem">
      
      <div class="lease-details">
        <h3>{{ lease.accommodation?.name || 'Pas d\'accommodation' }}</h3>
        
        <!-- Liste des locataires, en plus gros et visible -->
        <p class="tenant-list" *ngIf="lease.tenants && lease.tenants.length > 0">
          <strong>Locataires :</strong> 
          <span *ngFor="let tenant of lease.tenants; let isLast = last">
            {{ tenant.formattedName() }}<span *ngIf="!isLast">, </span>
          </span>
        </p>
        
        <!-- Affichage du statut avec lien pour modifier -->
        <p class="status">
          <strong>Status :</strong> {{ lease.status }}
          <a class="status-link" (click)="changeStatus(lease, $event)">Modifier le statut</a>
        </p>
        
        <p><strong>Début :</strong> {{ lease.startLocale?.date | date }}</p>
        <p><strong>Fin :</strong> {{ lease.endLocale?.date | date }}</p>
      </div>
      
      <!-- Bouton de vue -->
      <button class="btn-view" (click)="viewLease(lease, $event)">Voir le bail</button>
    </li>
  </ul>

  <!-- Aucun bail disponible -->
  <p *ngIf="list.length === 0">Aucun bail disponible.</p>
</div>
