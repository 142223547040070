import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ILabelDto } from '../../label.dto';
import { LabelService } from '../../label.service';

@Component({
  selector: 'plugin-label-picker',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
  ],
  templateUrl: './label-picker.plugin.html',
  styleUrl: './label-picker.plugin.scss'
})
export class LabelPickerPlugin implements OnInit, OnChanges {
  @Input() group: string | undefined; // Le groupe pour filtrer les labels
  labels: ILabelDto[] = []; // Liste des labels à afficher
  selectedLabel: ILabelDto | null = null; // Le label sélectionné

  @Output() selectedLabelChange = new EventEmitter<ILabelDto | null>();
  @Output() refreshCompleted = new EventEmitter<void>(); // Émet après le rafraîchissement
  @Input() refresh: boolean = false; // Nouvelle input pour déclencher le refresh

  constructor(private labelService: LabelService) {}

  ngOnInit(): void {
    this.fetchLabels();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('group' in changes) {
      console.log('Group changed from:', changes['group'].previousValue, 'to:', changes['group'].currentValue);
      this.fetchLabels();
    }
    if ('refresh' in changes && changes['refresh'].currentValue === true) {
      console.log('Refresh triggered');
      this.fetchLabels();
    }
  }

  fetchLabels(): void {
    console.log('Fetching labels for group:', this.group);
    this.labelService.getLabels(this.group).subscribe({
      next: (labels) => {
        console.log('Labels fetched:', labels);
        this.labels = labels;
        this.resetSelect();
        this.refreshCompleted.emit(); // Émettre l'événement après la mise à jour
      },
      error: (error) => {
        console.error('Error fetching labels:', error);
        this.resetSelect();
        this.refreshCompleted.emit(); // Émettre même en cas d'erreur pour signaler que le refresh est terminé
      }
    });
    console.log('Label fetch request completed');
  }

  onLabelSelect(event: Event): void {
    const selectElement = event.target as HTMLSelectElement;
    const selectedCode = selectElement.value;
    this.selectedLabel = this.labels.find(label => label.code === selectedCode) || null;
    console.log('Label selected:', this.selectedLabel);
    this.selectedLabelChange.emit(this.selectedLabel); // Émettre le label sélectionné
  }

  private resetSelect(): void {
    console.log('Resetting select');
    this.selectedLabel = null; // Réinitialiser l'état interne du composant
    // Si vous avez besoin de réinitialiser l'élément DOM du select, vous pouvez le faire comme suit:
    // setTimeout permet d'assurer que le DOM est mis à jour avant de manipuler le select
    setTimeout(() => {
      const selectElement = document.querySelector('.label-select') as HTMLSelectElement;
      if (selectElement) {
        selectElement.selectedIndex = 0; // Sélectionne la première option (qui est désactivée et sélectionnée par défaut)
      }
    });
  }  
}
