import { Injectable } from '@angular/core';
import { InvoiceItem } from './invoice-item.class';
import { catchError, map, Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpParams } from '@angular/common/http';
import { ApiService } from '../../api/api.service';
import { IInvoiceItemDto } from './invoice-item.dto';

@Injectable({
  providedIn: 'root'
})
export class InvoiceItemService {

  constructor(
    private apiService: ApiService,
  ) { }

  update(id: number, invoiceItem: InvoiceItem): Observable<InvoiceItem> {
    const url: string = `${environment.api.url}${environment.api.endpoint.invoiceItemUpdate}/${id}`;
    const dto = invoiceItem.getDto();
    
    console.log('Updating invoice item with URL:', url);
    console.log('DTO to send:', dto);

    return this.apiService.put<IInvoiceItemDto>(url, dto).pipe(
      map(returnedDto => {
        console.log('DTO received from API:', returnedDto);
        const updatedItem = new InvoiceItem(returnedDto);
        console.log('Updated InvoiceItem:', updatedItem);
        return updatedItem;
      }),
      catchError((error) => {
        console.error('Error updating invoice item:', error);
        return throwError(error);
      })
    );
  }
    
  list(
    invoiceId: number,
    options: IInvoiceItemRequestOptions = {}
  ): Observable<InvoiceItem[]> {
    const url: string = `${environment.api.url}${environment.api.endpoint.invoiceItemList}/${invoiceId}`;
    let params = new HttpParams();
  
    console.log('URL for invoice items:', url);
    console.log('Parameters for request:', params);
  
    return this.apiService.get<IInvoiceItemDto[]>(url, { params }).pipe(
      map(l => {
        console.log('Received data from API:', l);
        return l.map(d => {
          const item = new InvoiceItem(d);
          console.log('Converted DTO to InvoiceItem:', item);
          return item;
        });
      }),
      catchError((error) => {
        console.error('Error fetching invoice item list:', error);
        return throwError(error);
      })
    );
  }

  create(invoiceItem: InvoiceItem): Observable<InvoiceItem> {
    const url: string = `${environment.api.url}${environment.api.endpoint.invoiceItemCreate}`;
    const dto = invoiceItem.getDto();
    
    console.log('Creating invoice item with URL:', url);
    console.log('DTO to send:', dto);

    return this.apiService.post<IInvoiceItemDto>(url, dto).pipe(
      map(returnedDto => {
        console.log('DTO received from API:', returnedDto);
        const createdItem = new InvoiceItem(returnedDto);
        console.log('Created InvoiceItem:', createdItem);
        return createdItem;
      }),
      catchError((error) => {
        console.error('Error creating invoice item:', error);
        return throwError(error);
      })
    );
  }  
}

export interface IInvoiceItemRequestOptions {

}