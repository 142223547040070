import { Injectable } from '@angular/core';
import { ApiService } from '../../api/api.service';
import { ContactPhone } from './contact-phone.class';
import { catchError, map, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpParams } from '@angular/common/http';
import { IContactPhoneDto } from './contact-phone.dto';

@Injectable({
  providedIn: 'root'
})
export class ContactPhoneService {

  constructor(
    private apiService: ApiService,
  ) {}

  // per  load person 0/1
  // idp  id person
  // ids liste des id de contact phones

  delete(id: number): Observable<void> {
    const url: string = `${environment.api.url}${environment.api.endpoint.contactPhoneDelete}/${id}`;
    return this.apiService.delete<void>(url).pipe(
      catchError(this.apiService.handleError.bind(this))
    );
  }

  create(
    phone: ContactPhone,
  ): Observable<ContactPhone | null> {
    console.log('phone', phone)
    const url: string = `${environment.api.url}${environment.api.endpoint.contactPhoneCreate}`;
    return this.apiService.post<IContactPhoneDto>(url, phone.getDto()).pipe(
      map(dto => new ContactPhone(dto)),
      catchError(this.apiService.handleError.bind(this)) 
    );
  }

  list(
    options: IContactPhoneRequestOptions,
  ): Observable<ContactPhone[] | null> {
    const url = `${environment.api.url}${environment.api.endpoint.contactPhoneList}`;
    let params = new HttpParams()
      .set('per', options.loadPerson ? +options.loadPerson : 0);
    if (options.selectIds) {
      params = params.set('ids', options.selectIds.join(','));
    }
    if (options.selectIdPerson) {
      params = params.set('idp', options.selectIdPerson);
    }
    return this.apiService.get<IContactPhoneDto[]>(url, { params }).pipe(
      map(dtos => dtos.map(dto => new ContactPhone(dto))),
      catchError(this.apiService.handleError.bind(this)),
    );
  }

  get(
    id: number, 
    options: IContactPhoneRequestOptions = {},
  ): Observable<ContactPhone | null> {
    const url:string = `${environment.api.url}${environment.api.endpoint.contactPhoneGet}/${id}`;
    let params: HttpParams = new HttpParams()
      .set('per', options.loadPerson ? 1 : 0);
    return this.apiService.get<IContactPhoneDto>(url, { params }).pipe(
      map(dto => new ContactPhone(dto)), 
      catchError(this.apiService.handleError.bind(this)),
    )
  }

}

export interface IContactPhoneRequestOptions{
  selectIds?: number[],
  selectIdPerson?: number,
  loadPerson?: boolean,

  id?: number,
}