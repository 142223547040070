import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { InvoiceItemVat } from '../../invoice-item-vat.class';
import { InvoiceItemVatService } from '../../invoice-item-vat.service';
import { IInvoiceItemVatDto } from '../../invoice-item-vat.dto';
import { CommonModule } from '@angular/common';
import { InvoiceItemVatEditPlugin } from '../edit/invoice-item-vat-edit.plugin';

@Component({
  selector: 'plugin-invoice-item-vat-picker',
  standalone: true,
  imports: [
    CommonModule,
    InvoiceItemVatEditPlugin,
  ],
  templateUrl: './invoice-item-vat-picker.plugin.html',
  styleUrl: './invoice-item-vat-picker.plugin.scss'
})
export class InvoiceItemVatPickerPlugin implements OnInit {
  @Input() itemId: number | undefined;
  @Output() vatSaved = new EventEmitter<IInvoiceItemVatDto>();
  @Output() vatDeleted = new EventEmitter<number>();
  vats: InvoiceItemVat[] = [];
  isEditingVat: boolean = false;
  selectedVatDto: IInvoiceItemVatDto | undefined;

  constructor(private invoiceItemVatService: InvoiceItemVatService) {}

  ngOnInit(): void {
    this.loadVats();
  }

  loadVats(): void {
    if (this.itemId) {
      this.invoiceItemVatService.list(this.itemId).subscribe(
        (vats) => {
          this.vats = vats;
          console.log('VATs loaded for item:', this.itemId, vats);
        },
        (error) => console.error('Error loading VATs:', error)
      );
    }
  }  

  editVat(vat?: InvoiceItemVat): void {
    console.log('Before editVat, isEditingVat:', this.isEditingVat);
    this.isEditingVat = true;
    if (vat) {
      this.selectedVatDto = vat.getDto(); // Sélectionner le VAT à éditer
      console.log('Editing existing VAT:', this.selectedVatDto);
    } else {
      this.selectedVatDto = undefined; // Ajouter un nouveau VAT
      console.log('Adding new VAT');
    }
    console.log('After editVat, isEditingVat:', this.isEditingVat, 'selectedVatDto:', this.selectedVatDto);
  }

  onVatSaved(vatDto: IInvoiceItemVatDto): void {
    console.log('VAT saved:', vatDto);
    this.isEditingVat = false;
    this.vatSaved.emit(vatDto);
    this.selectedVatDto = undefined; 
    this.loadVats();
  }

  cancelVatEdit(): void {
    console.log('VAT edit cancelled');
    this.isEditingVat = false;
    this.selectedVatDto = undefined; // Réinitialiser si annulé
  }

  deleteVat(vatId: number | undefined): void {
    if (vatId !== undefined) {
      console.log('Initiating VAT deletion, ID:', vatId);
      this.invoiceItemVatService.delete(vatId).subscribe(
        () => {
          console.log('VAT deletion successful, ID:', vatId);
          this.vatDeleted.emit(vatId);
          this.loadVats(); // Recharger la liste après suppression
        },
        (error) => console.error('Error during VAT deletion:', error)
      );
    } else {
      console.error('Cannot delete VAT: ID is undefined');
    }
  }
}
