<div class="rent-edit">
  <h1>Modifier les informations du loyer</h1>

  <!-- Formulaire pour modifier les informations du loyer -->
  <form (ngSubmit)="save()">
    
    <!-- Nom de l'opération -->
    <div class="form-group">
      <label for="name">Nom de l'opération</label>
      <input type="text" id="name" [(ngModel)]="rent.name" name="name" required />
    </div>

    <!-- Mois et année de référence -->
    <div class="form-group">
      <label for="refYear">Année de référence</label>
      <input type="number" id="refYear" [(ngModel)]="rent.refYear" name="refYear" required />
    </div>

    <div class="form-group">
      <label for="refMonth">Mois de référence</label>
      <input type="number" id="refMonth" [(ngModel)]="rent.refMonth" name="refMonth" required />
    </div>

    <!-- Montants -->
    <div class="form-group">
      <label for="rentAmount">Montant du loyer (€)</label>
      <input type="number" id="rentAmount" [(ngModel)]="rent.rentAmount" name="rentAmount" required />
    </div>

    <div class="form-group">
      <label for="feesAmount">Montant des frais (€)</label>
      <input type="number" id="feesAmount" [(ngModel)]="rent.feesAmount" name="feesAmount" required />
    </div>

    <!-- Dates -->
    <div class="form-group">
      <label for="startLocale">Date de début</label>
      <input type="date" id="startLocale" [(ngModel)]="startDate" name="startLocale" required />
    </div>

    <div class="form-group">
      <label for="endLocale">Date de fin</label>
      <input type="date" id="endLocale" [(ngModel)]="endDate" name="endLocale" [disabled]="!rent.endLocale" />
      <button type="button" class="btn" (click)="initializeEndLocale()" *ngIf="!rent.endLocale">Initialiser la date de fin</button>
    </div>

    <!-- Boutons d'action -->
    <div class="form-actions">
      <button type="submit" class="btn btn-emit">💾 Sauvegarder</button>
      <button type="button" class="btn btn-cancel" (click)="cancel()">❌ Annuler</button>
    </div>
  </form>
</div>
