import { Injectable } from '@angular/core';
import { ILabelDto } from './label.dto';
import { ApiService } from '../../api/api.service';
import { catchError, map, Observable, of, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LabelService {

  constructor(
    private apiService: ApiService,
  ) {}

  /**
   * Récupère une liste triée des différents groupes de labels.
   * @returns Observable d'un tableau de chaînes de caractères représentant les groupes.
   */
  getGroups(): Observable<string[]> {
    const url = `${environment.api.url}${environment.api.endpoint.labelGroupsList}`; // Mise à jour de l'endpoint
    return this.apiService.get<string[]>(url).pipe(
      map(groups => groups.sort()),
      catchError(error => {
        console.error('Erreur lors de la récupération des groupes de labels:', error);
        return throwError(error);
      })
    );
  }

  /**
   * Récupère tous les labels ou ceux d'un groupe spécifique.
   * @param group Le groupe pour filtrer les labels, optionnel.
   * @returns Observable d'un tableau de DTOs de labels.
   */
  getLabels(group?: string): Observable<ILabelDto[]> {
    const url = `${environment.api.url}${environment.api.endpoint.labelList}`;
    let params = new HttpParams();
    if (group) {
      params = params.set('grp', group);
    }
    return this.apiService.get<ILabelDto[]>(url, { params }).pipe(
      catchError(error => {
        console.error('Erreur lors de la récupération des labels:', error);
        return throwError(error);
      })
    );
  }

  /**
   * Crée un nouveau label.
   * @param labelDto Les données du label à créer.
   * @returns Observable du label créé ou null en cas d'erreur.
   */
  createLabel(labelDto: ILabelDto): Observable<ILabelDto | null> {
    const url = `${environment.api.url}${environment.api.endpoint.labelCreate}`;
    return this.apiService.post<ILabelDto>(url, labelDto).pipe(
      catchError(error => {
        console.error('Erreur lors de la création du label:', error);
        return of(null);
      })
    );
  }

  /**
   * Met à jour un label existant.
   * @param id L'ID du label à mettre à jour.
   * @param labelDto Les nouvelles données du label.
   * @returns Observable du label mis à jour ou null en cas d'erreur.
   */
  updateLabel(id: number, labelDto: ILabelDto): Observable<ILabelDto | null> {
    const url = `${environment.api.url}${environment.api.endpoint.labelUpdate}/${id}`;
    return this.apiService.patch<ILabelDto>(url, labelDto).pipe(
      catchError(error => {
        console.error('Erreur lors de la mise à jour du label:', error);
        return of(null);
      })
    );
  }

  /**
   * Supprime un label par son ID.
   * @param id L'ID du label à supprimer.
   * @returns Observable qui émet true si le label a été supprimé, sinon false.
   */
  deleteLabel(id: number): Observable<boolean> {
    const url = `${environment.api.url}${environment.api.endpoint.labelDelete}/${id}`;
    return this.apiService.delete(url).pipe(
      map(() => true), // Si la suppression réussit, on retourne true
      catchError(error => {
        console.error('Erreur lors de la suppression du label:', error);
        return of(false); // En cas d'erreur, on retourne false
      })
    );
  }

}
