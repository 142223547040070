import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactListPlugin } from '../list/contact-list.plugin';
import { ContactService, IContactRequestOptions } from '../../contact.service';
import { Contact } from '../../contact.class';
import { IContactDto } from '../../contact.dto';

/** 
 * Plugin de gestion d'une liste de contact
 * permet l'affichage de la liste communiquée,
 * l'ajout d'élément
 * la suppression d'élément
 * 
*/
@Component({
  selector: 'plugin-contact-multi',
  templateUrl: './contact-multi.plugin.html',
  styleUrls: ['./contact-multi.plugin.scss'],
  standalone: true,
  imports: [
    CommonModule,
  ],
})
export class ContactMultiPlugin {
  public _options: IContactRequestOptions = {};
  @Input() 
  set options(value: IContactRequestOptions) {
    this._options = value;
    this.loadData();
  }
  get options(): IContactRequestOptions {
    return this._options;
  }

  @Output() onSave: EventEmitter<IContactDto[]> = new EventEmitter();

  public list: Contact[] = [];
  public selectedContacts: Contact[] = [];
  public showFullList = false;
  private loadedData: boolean = false;

  constructor(
    private contactService: ContactService,
  ) {}

  loadData() {
    if (!this.loadedData) {
      this.options.ignoreSelectIds = this.showFullList;
      // console.log('options', this.options);
      this.contactService.list(this.options).subscribe(l => {
        this.list = l;
        this.initializeSelectedContacts();
        this.loadedData = true;
      });
    }
  }

  initializeSelectedContacts() {
    if (this._options.selectIds) {
      this.selectedContacts = this.list.filter(contact => 
        this._options.selectIds!.includes(contact.id!)
      );
    }
  }

  toggleContactSelection(contact: Contact) {
    const index = this.selectedContacts.findIndex(c => c.id === contact.id);
    if (index >= 0) {
      // Le contact est déjà sélectionné, on le désélectionne
      this.selectedContacts.splice(index, 1);
    } else {
      // Le contact n'est pas sélectionné, on l'ajoute à la sélection
      this.selectedContacts.push(contact);
    }
  }

  saveSelection() {
    const selectedDtos = this.selectedContacts.map(contact => contact.getDto());
    this.showFullList = false;
    this.loadedData = false;
    this.onSave.emit(selectedDtos);
  }

  // Vérifie si un contact est sélectionné
  isSelected(contact: Contact): boolean {
    return this.selectedContacts.some(c => c.id === contact.id);
  }

  toggleFullList() {
    this.showFullList = !this.showFullList;
    this.loadedData = false;
    this.loadData();
  }

}
