import { Component, Input } from '@angular/core';
import { Operation } from '../../../operation.class';
import { IOperationDto } from '../../../operation.dto';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'plugin-status-view',
  standalone: true,
  imports: [
    CommonModule
  ], 
  templateUrl: './status-view.plugin.html',
  styleUrl: './status-view.plugin.scss'
})
export class StatusViewPlugin {
  operation: Operation = new Operation();
  @Input()
  set operationDto(value: IOperationDto) {
    this.operation = new Operation(value);
  }

  get statusClass(): string {
    switch (this.operation.status) {
      case 'NEW':
        return 'status-new';
      case 'PENDING':
        return 'status-pending';
      case 'COMPLETED':
        return 'status-completed';
      default:
        return 'status-unknown';
    }
  }
  
}
