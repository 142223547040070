<div>
    <table>
        <ng-container *ngFor="let r of list">
            <tr (dblclick)="dblClick(r)">
                <td rowspan="2">{{r.idOperation}}</td>
                <td>{{ r.refYear }}-{{ r.refMonth }}</td>
                <td>{{ r.startLocale?.date }}</td>
                <td>{{ r.endLocale?.date }}</td>
            </tr>
            <tr (dblclick)="dblClick(r)">
                <td>{{ r.status }}</td>
                <td colspan="2">
                    Rent: {{ r.rentAmount }} | Fees: {{ r.feesAmount }}
                </td>
            </tr>
        </ng-container>
    </table>
</div>