import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiService } from '../api/api.service';
import { IUserDto } from './user.dto';
import { User } from './user.class';
import { environment } from '../../environments/environment';
import { IUserRequestOptions } from './user.request';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private apiService: ApiService,
    private authService: AuthService,
  ) {}

  partialUpdate(
    username: string,
    partialDto: Partial<IUserDto>,
  ): Observable<User | null> {
    const url: string = `${environment.api.url}${environment.api.endpoint.userUpdate}/${username}`;
    console.log(url);
  
    return this.apiService.patch<IUserDto>(url, partialDto).pipe(
      map(dto => new User(dto)),
      catchError(this.apiService.handleError.bind(this)) 
    );
  }  

  /**
   * mise à jour partielle
   * le dto en entrée doit contenir le username du user à modifier
   */
  newPassword(
    username: string,
    clearPassword: string,
  ): Observable<User | null> {
    const url: string = `${environment.api.url}${environment.api.endpoint.userPassword}/${username}/pwd`;
    const dto: Partial<IUserDto> = {
      password: clearPassword,
    };
    console.log(url);
  
    return this.apiService.patch<IUserDto>(url, dto).pipe(
      map(dto => new User(dto)),
      catchError(this.apiService.handleError.bind(this)) 
    );
  }
  
  create(
    user: User,
  ): Observable<User | null> {
    const url: string = `${environment.api.url}${environment.api.endpoint.userCreate}`;
    return this.apiService.post<IUserDto>(url, user.getDto()).pipe(
      map(dto => new User(dto)),
      catchError(this.apiService.handleError.bind(this)) 
    );
  }  

  get(
    username: string,
    options: IUserRequestOptions = {},
  ): Observable<User | null> {
    const url: string = `${environment.api.url}${environment.api.endpoint.userGet}/${username}`;
    console.log(url);
  
    // Construire les paramètres de requête en fonction des options
    let params = new HttpParams();
    if (options.loadBuyer) {
      params = params.set('buy', '1');
    }
  
    return this.apiService.get<IUserDto>(url, {params}).pipe(
      map(dto => new User(dto)),
      catchError(this.apiService.handleError.bind(this)) 
    );
  }

  list(options: IUserRequestOptions): Observable<User[] | null> {
    const url: string = `${environment.api.url}${environment.api.endpoint.userList}`;
    let params = new HttpParams();
    
    // Ajout d'un log pour vérifier les options et les paramètres envoyés
    console.log('Options passed to list method:', options);
  
    if (options.loadBuyer) {
      params = params.set('buy', '1');
    }
  
    console.log('URL:', url);
    console.log('Request Params:', params.toString());
  
    return this.apiService.get<IUserDto[]>(url, { params }).pipe(
      map(dtos => {
        // Log les dtos reçus avant de les transformer en instances de `User`
        console.log('Received dtos:', dtos);
        const users = dtos.map(dto => new User(dto));
        console.log('Mapped Users:', users);
        return users;
      }),
      catchError(error => {
        // Log l'erreur avant qu'elle ne soit gérée par `handleError`
        console.error('Error caught:', error);
        return this.apiService.handleError(error);
      })
    );
  }
  
  save(user: User): Observable<User | null> {
    const url: string = `${environment.api.url}${environment.api.endpoint.userSave}`;
    console.log(url);

    return this.apiService.post<IUserDto>(url, user.getDto()).pipe(
        map(dto => new User(dto)),
        catchError((error: HttpErrorResponse) => {
            if (error.status === 401) {
              this.authService.logout();
                // this.store.dispatch(logout());
            }
            return throwError(error);
        })
    );
  }

}
