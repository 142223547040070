<div class="rent-edit-route">
    <h1>Gestion du Loyer</h1>
  
    <!-- Si nous ne sommes pas en mode édition, affiche la vue du loyer -->
    <div *ngIf="!editMode">
      <plugin-rent-view [rentOperationDto]="rent.getDto()"></plugin-rent-view>
      <button class="btn btn-edit" (click)="toggleEditMode()">✏️ Modifier</button>
    </div>
  
    <!-- Si nous sommes en mode édition, affiche le plugin d'édition -->
    <div *ngIf="editMode">
      <plugin-rent-edit
        [rentOperationDto]="rent.getDto()"
        (onUpdate)="update($event)"
        (onCancel)="toggleEditMode()">
      </plugin-rent-edit>
    </div>
  
    <!-- Afficher les boutons émettre/annuler en dehors de la vue d'édition -->
    <div *ngIf="!editMode" class="form-actions">
      <button class="btn btn-emit" (click)="emitRent()">💾 Émettre</button>
      <button class="btn btn-cancel" (click)="cancelEdit()">❌ Annuler</button>
    </div>
  </div>
  