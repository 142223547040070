<div class="accommodation-picker">
    <h2>Choisissez un hébergement</h2>
  
    <!-- Liste des hébergements -->
    <ul *ngIf="list.length > 0">
      <li *ngFor="let accommodation of list" 
          (click)="click(accommodation.getDto())" 
          (dblclick)="activate(accommodation.getDto())" 
          [class.selected]="accommodation === selected">
        
        <div class="accommodation-details">
          <h3>{{ accommodation.name }}</h3>
          <p><strong>Description :</strong> {{ accommodation.inlineDescription || 'N/A' }}</p>
          <p><strong>Loyer mensuel :</strong> {{ accommodation.rentByMonthListed ? (accommodation.rentByMonthListed + ' €') : 'N/A' }}</p>
          <p><strong>Frais mensuels :</strong> {{ accommodation.feesByMonthListed ? (accommodation.feesByMonthListed + ' €') : 'N/A' }}</p>
        </div>
  
        <!-- Bouton pour voir les détails -->
        <button class="btn-view" (click)="view(accommodation.getDto())">{{ options.selectButtonLabel || 'Voir'  }}</button>
      </li>
    </ul>
  
    <!-- Aucun hébergement disponible -->
    <p *ngIf="list.length === 0">Aucun hébergement disponible.</p>
  </div>
  