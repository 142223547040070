import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ILeaseDto } from '../../lease.dto';
import { Lease } from '../../lease.class';
import { ILeaseRequestOptions, LeaseService } from '../../lease.service';
import { Router } from '@angular/router';

@Component({
  selector: 'plugin-lease-picker',
  templateUrl: './lease-picker.plugin.html',
  styleUrls: ['./lease-picker.plugin.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class LeasePickerPlugin {
  private _options: ILeaseRequestOptions = {
    loadAccommodation: true,
  };
  
  @Input()
  set options(value: ILeaseRequestOptions) {
    this._options = value;
    this.loadList();
  }
  get options(): ILeaseRequestOptions {
    return this._options;
  }

  // output
  @Output() onSelect = new EventEmitter<ILeaseDto>();    // simple click
  @Output() onActivate = new EventEmitter<ILeaseDto>();  // double-click
  @Output() onView = new EventEmitter<ILeaseDto>();      // view button click

  list: Lease[] = [];
  _selectedItem: Lease = new Lease();

  set selectedItem(value: Lease) {
    this._selectedItem = value;
  }
  get selectedItem(): Lease {
    return this._selectedItem;
  }

  constructor(
    private leaseService: LeaseService,
    private router: Router,
  ) {}

  selectItem(item: Lease) {
    if (item) {
      this.selectedItem = item;
      this.onSelect.emit(this.selectedItem.getDto());
    }
  }

  activateItem(item: Lease) {
    if (item) {
      this.selectedItem = item;
      this.onActivate.emit(this.selectedItem.getDto());
    }
  }

  // Method to trigger on the "View" button click
  viewLease(item: Lease, event: Event) {
    event.stopPropagation();  // Prevent triggering select on item click
    this.onView.emit(item.getDto());
  }

  loadList() {
    this.leaseService.list(this.options).subscribe(data => {
      if (data) {
        // console.log(data);
        this.list = data;
      }
    });
  }

  // Méthode pour la navigation vers la page de modification du statut
  changeStatus(item: Lease, event: Event) {
    event.stopPropagation();  // Prévenir la sélection de l'élément lors du clic
    console.log('Navigating to status change page for lease ID:', item.id);
    this.router.navigate(['/lease/status/edit', item.id]);
  }
}