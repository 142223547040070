<div class="lease-data-edit">
    <h2>Modifier les Détails du Bail</h2>
  
    <!-- Formulaire d'édition du bail -->
    <form (ngSubmit)="saveLease()">
      <div class="form-group">
        <label for="status">Status</label>
        <input type="text" id="status" [(ngModel)]="lease.status" name="status" />
      </div>
  
      <div class="form-group">
        <label for="startDate">Date de début</label>
        <input type="date" id="startDate" [(ngModel)]="lease.startDate" name="startDate" />
      </div>
  
      <div class="form-group">
        <label for="endDate">Date de fin</label>
        <input type="date" id="endDate" [(ngModel)]="lease.endDate" name="endDate" />
      </div>
  
      <div class="form-group">
        <label for="rentByMonthNegociated">Loyer mensuel négocié (€)</label>
        <input type="number" id="rentByMonthNegociated" [(ngModel)]="lease.rentByMonthNegociated" name="rentByMonthNegociated" />
      </div>
  
      <div class="form-group">
        <label for="feesByMonthNegociated">Frais mensuels négociés (€)</label>
        <input type="number" id="feesByMonthNegociated" [(ngModel)]="lease.feesByMonthNegociated" name="feesByMonthNegociated" />
      </div>
  
      <div class="form-group">
        <label for="primaryResidence">Statut de résidence principale</label>
        <select id="primaryResidence" [(ngModel)]="lease.primaryResidence" name="primaryResidence">
          <option [value]="true">Oui</option>
          <option [value]="false">Non</option>
        </select>
      </div>
  
      <div class="form-group">
        <label for="studentLease">Bail étudiant</label>
        <select id="studentLease" [(ngModel)]="lease.studentLease" name="studentLease">
          <option [value]="true">Oui</option>
          <option [value]="false">Non</option>
        </select>
      </div>
  
      <div class="form-group">
        <label for="automaticRenewal">Renouvellement automatique</label>
        <select id="automaticRenewal" [(ngModel)]="lease.automaticRenewal" name="automaticRenewal">
          <option [value]="true">Oui</option>
          <option [value]="false">Non</option>
        </select>
      </div>
  
      <div class="form-group">
        <label for="timezone">Fuseau horaire</label>
        <input type="text" id="timezone" [(ngModel)]="lease.timezone" name="timezone" />
      </div>
  
      <div class="form-group">
        <label for="idGdrive">ID Google Drive</label>
        <input type="text" id="idGdrive" [(ngModel)]="lease.idGdrive" name="idGdrive" />
      </div>
      
      <!-- Boutons de sauvegarde et d'annulation -->
      <div class="form-actions">
        <button type="submit" class="btn-save">Sauvegarder</button>
        <button type="button" class="btn-cancel" (click)="cancelEdit()">Annuler</button>
      </div>
    </form>
  </div>
  