import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { AccommodationPickerPlugin } from '../../plugin/picker/accommodation-picker.plugin';
import { IAccommodationRequestOptions } from '../../accommodation.service';
import { Router } from '@angular/router';
import { IAccommodationDto } from '../../accommodation.dto';
import { Accommodation } from '../../accommodation.class';

@Component({
  selector: 'route-accommodation-list',
  standalone: true,
  imports: [
    CommonModule,
    AccommodationPickerPlugin,
  ],
  templateUrl: './accommodation-list.route.html',
  styleUrl: './accommodation-list.route.scss'
})
export class AccommodationListRoute {
  public options: IAccommodationRequestOptions = {};
  
  constructor(
    private router: Router,
  ) {}

  view(dto: IAccommodationDto) {
    const accommodation: Accommodation = new Accommodation(dto);
    this.router.navigate(['accommodation', accommodation.id]);
  }

  addAccommodation() {
    this.router.navigate(['accommodation/new']); // Navigue vers la route d'ajout de logement
  }
  
}
