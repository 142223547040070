export enum ETopic {
  // Domaine Location
  Rental = 'TOPIC_RENTAL',

  // Domaien Prestation
  Services = 'TOPIC_SERVICES',

  // Domaine gestion
  Management = 'TOPIC_MANAGEMENT',

  // Domaine patrimoine
  Wealth = "TOPIC_WEALTH",

  // Domaine spécial
  Administration = 'TOPIC_ADMIN',
  Dev = 'TOPIC_DEV',
}
