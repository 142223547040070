import { Component } from '@angular/core';
import { IAddressRequestOptions } from '../../address.service';
import { CommonModule } from '@angular/common';
import { AddressPickerPlugin } from '../../plugin/picker/address-picker.plugin';
import { IAddressDto } from '../../address.dto';
import { Router } from '@angular/router';
import { Address } from '../../address.class';

@Component({
  selector: 'route-address-list',
  standalone: true,
  imports: [
    CommonModule,
    AddressPickerPlugin,
  ],
  templateUrl: './address-list.route.html',
  styleUrl: './address-list.route.scss'
})
export class AddressListRoute {
  options: IAddressRequestOptions =  {};

  constructor(
    private router: Router,
  ) {}
  view(dto: IAddressDto) {
    const address: Address = new Address(dto);
    this.router.navigate(['address', address.idAddress]);
  }
}
