import { Component, OnInit } from '@angular/core';
import { LabelService } from '../label.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { LabelGroupPickerPlugin } from '../group/plugin/picker/label-group-picker.plugin';
import { LabelPickerPlugin } from '../plugin/picker/label-picker.plugin';
import { LabelEditPlugin } from '../plugin/edit/label-edit.plugin';
import { ILabelDto } from '../label.dto';

@Component({
  selector: 'route-label-manager',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    LabelGroupPickerPlugin,
    LabelPickerPlugin,
    LabelEditPlugin,
  ],
  templateUrl: './label-manager.route.html',
  styleUrl: './label-manager.route.scss'
})
export class LabelListRoute implements OnInit {
  selectedGroup: string | undefined = undefined;
  selectedLabel: ILabelDto | null = null;
  isNewLabel: boolean = false;
  refreshPicker: boolean = false; // Pour déclencher le refresh du picker
  
  constructor(private labelService: LabelService) {}

  ngOnInit(): void {}

  onGroupSelected(group: string | null): void {
    this.selectedGroup = group ?? undefined;
    console.log('Group selected:', this.selectedGroup);
    this.selectedLabel = null;
    this.isNewLabel = false;
  }

  onLabelSelected(label: ILabelDto | null): void {
    this.selectedLabel = label;
    this.isNewLabel = false;
  }

  addNewLabel(): void {
    this.selectedLabel = { id: 0, group: this.selectedGroup || '', code: '', labelLong: '', defaultLabel: false };
    this.isNewLabel = true;
  }

  deleteSelectedLabel(): void {
    if (this.selectedLabel && this.selectedLabel.id !== undefined) {
      console.log('Supprimer le label:', this.selectedLabel.id);
      this.labelService.deleteLabel(this.selectedLabel.id).subscribe({
        next: (deleted) => {
          if (deleted) {
            console.log('Label supprimé avec succès');
            this.selectedLabel = null;
            this.isNewLabel = false;
            this.triggerRefresh();
          } else {
            console.log('Échec de la suppression du label');
          }
        },
        error: (error) => {
          console.error('Erreur lors de la suppression du label:', error);
        }
      });
    } else {
      console.log('Aucun label sélectionné à supprimer ou le label est nouveau.');
    }
  }

  private triggerRefresh(): void {
    this.refreshPicker = true; // Déclenche le refresh
    setTimeout(() => {
      this.refreshPicker = false; // Réinitialise la valeur après un court délai
    }, 0);
  }
}
