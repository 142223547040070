<div class="login">
    <ng-container *ngIf="!isAuthenticated">
      <button (click)="navigateToLogin()" class="btn btn-link">Se connecter</button>
    </ng-container>
  
    <ng-container *ngIf="isAuthenticated">
      <span>Bienvenue, {{ username }} !</span>
      <button (click)="navigateToProfile()" class="btn btn-link">Voir profil</button>
      <button (click)="logout()" class="btn btn-link">Se déconnecter</button>
    </ng-container>
  </div>
  