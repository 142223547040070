<div class="lease-edit">
  <!-- Bloc Data -->
  <div class="lease-data">
    <!-- Mode Vue -->
    <div *ngIf="!editMode">
      <plugin-lease-data-view [leaseDto]="currentLeaseDto"></plugin-lease-data-view>
      <button class="btn-edit" (click)="toggleEditMode()">Modifier</button>
    </div>

    <!-- Mode Édition -->
    <div *ngIf="editMode">
      <plugin-lease-data-edit
        [leaseDto]="currentLeaseDto"
        (onSave)="saveData($event)"
        (onCancel)="cancelDataEdit()">
      </plugin-lease-data-edit>
    </div>
  </div>

  <!-- Autres sections uniquement si le bail n'est pas nouveau -->
  <ng-container *ngIf="!currentLease.isNew">
    <!-- Bloc Accommodation -->
    <div class="lease-accommodation">
      <div *ngIf="!hasAccommodation">
        <p>Aucune accommodation n'est associée. Veuillez en choisir une :</p>
        <div class="accommodation-picker-container">
          <plugin-accommodation-picker 
            [options]="accommodationPickerOptions"
            (onSelect)="updateAccommodation($event)"
            (onActivate)="updateAccommodation($event)">
          </plugin-accommodation-picker>
        </div>
      </div>

      <div *ngIf="hasAccommodation">
        <!-- Mode Vue -->
        <div *ngIf="!accommodationEditMode">
          <plugin-accommodation-view 
            [accommodationDto]="_accommodationDto">
          </plugin-accommodation-view>
          <button class="btn-edit" (click)="toggleAccommodationEditMode()">Modifier l'accommodation</button>
        </div>

        <!-- Mode Édition de l'accommodation -->
        <div *ngIf="accommodationEditMode">
          <p>Choisissez une nouvelle accommodation :</p>
          <div class="accommodation-picker-container">
            <plugin-accommodation-picker 
              [options]="accommodationPickerOptions"
              (onSelect)="updateAccommodation($event)"
              (onView)="updateAccommodation($event)"
              (onActivate)="updateAccommodation($event)">
            </plugin-accommodation-picker>
          </div>
        </div>
      </div>
    </div>

    <!-- Bloc Lessors -->
    <div class="lease-lessors">
      <h3>Gestion des bailleurs</h3>
      <plugin-contact-multi
        [options]="lessorsPickerOptions"
        (onSave)="updateLessors($event)">
      </plugin-contact-multi>
    
      <!-- Utilisation du plugin pour la sélection d'occupancy -->
      <plugin-occupancy-selector 
        [leaseDto]="_leaseDto" 
        [selectedOccupancyId]="currentLease.issuerOccupancyId || 0" 
        (onSelect)="updateIssuerOccupancy($event)">
      </plugin-occupancy-selector>
    </div>
    
    

    <!-- Bloc Tenants -->
    <div class="lease-tenants">
      <h3>Gestion des locataires</h3>
      <plugin-contact-multi
        [options]="tenantsPickerOptions"
        (onSave)="updateTenants($event)">
      </plugin-contact-multi>
    </div>
      
    <!-- Boutons Sauvegarder et Annuler -->
    <div class="form-actions">
      <button class="btn btn-save" (click)="save()">💾 Sauvegarder</button>
      <button class="btn btn-cancel" (click)="cancel()">❌ Annuler</button>
    </div>
  </ng-container>
</div>
