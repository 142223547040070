import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IRentStatusRequestOptions, RentStatusService } from '../../rent-status.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'plugin-rent-status-next',
  standalone: true,
  imports: [
    CommonModule,
  ],
  templateUrl: './rent-status-next.plugin.html',
  styleUrl: './rent-status-next.plugin.scss'
})
export class RentStatusNextPlugin {
  _statusCode: string = '';
  @Input()
  set statusCode(value: string) {
    this._statusCode = value;
    this.loadData();
  }
  get statusCode(): string {
    return this._statusCode;
  }
  @Output() onChoose: EventEmitter<string> = new EventEmitter();

  list: string[] = [];
  selectedStatus: string = '';
  showAllStatuses: boolean = false;

  constructor(
    private rentStatusService: RentStatusService,
  ) {}

  loadData() {
    const options: IRentStatusRequestOptions = {
      selectAll: this.showAllStatuses,
    }
    console.log('options', options);
    this.rentStatusService.listNext(this.statusCode, options).subscribe(l => {
      if (l) {
        this.list = l;
        this.selectedStatus = this.list[0] || ''; // Initialise selectedStatus à la première valeur si disponible
      }
    });
  }

  updateSelectedStatus(event: Event) {
    this.selectedStatus = (event.target as HTMLSelectElement).value;
  }

  chooseStatus() {
    this.onChoose.emit(this.selectedStatus || this.list[0]);
  }

  toggleShowAllStatuses() {
    this.showAllStatuses = !this.showAllStatuses;  // Inverse l'état de la case à cocher
    this.loadData();  // Recharge la liste des statuts en fonction de l'état de la case
  }

}
