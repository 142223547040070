import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Lease } from '../../lease.class';
import { LeaseService } from '../../lease.service';
import { ILeaseDto } from '../../lease.dto';

/**
 * ce plugin permet d'afficher le status d'un bail
 * et propose une combo avec la liste des états suivants possibles 
 * et un bouton pour changer l'état
 */
@Component({
  selector: 'plugin-lease-status-manager',
  templateUrl: './lease-status-manager.plugin.html',
  styleUrl: './lease-status-manager.plugin.scss',
  standalone: true,
  imports: [CommonModule, FormsModule],
})
export class LeaseStatusManagerPlugin {
  @Input()
  set leaseDto(value: ILeaseDto) {
    this.loadLeaseDto(value);
  }

  @Output() onChanged = new EventEmitter<ILeaseDto>;

  public lease: Lease = new Lease();
  loaded: boolean = false;
  statusLoaded: boolean = false;
  listNextStatus: string[] = [];
  nextStatus: string ='';
  showAllStatuses: boolean = false;

  constructor(
    private leaseService: LeaseService,
  ) {}

  loadLeaseDto(dto: ILeaseDto) {
    if (!this.loaded) {
      this.lease = new Lease(dto);
      this.loadStatusList();
    }
  }

  loadStatusList() {
    if (!this.statusLoaded) {
      if (this.lease && this.lease.id) {
        if (this.showAllStatuses) {
          this.leaseService.getLeaseStatusList(this.lease.id).subscribe(
            list => {
              if (list) {
                this.listNextStatus = list;
                this.statusLoaded = true;
                console.log('Liste complète des statuts chargée:', list);
              }
            },
            error => {
              console.error('Erreur lors du chargement de la liste complète des statuts:', error);
            }
          );
        } else {
          this.leaseService.getLeaseNextStatusList(this.lease.id).subscribe(
            list => {
              if (list) {
                this.listNextStatus = list;
                this.statusLoaded = true;
                console.log('Liste des statuts suivants chargée:', list);
              }
            },
            error => {
              console.error('Erreur lors du chargement des statuts suivants:', error);
            }
          );
        }
      }
    }
  }

  refreshStatusList() {
    this.statusLoaded = false;
    this.loadStatusList();
  }
  
  changeStatus() {
    if (this.nextStatus && (this.nextStatus !== this.lease.status) && this.lease.id) {
      // Appel du service de mise à jour du statut du bail
      this.leaseService.updateLeaseStatus(this.lease.id, this.nextStatus).subscribe({
        next: (updatedLease) => {
          // Mise à jour réussie
          this.lease = updatedLease; // Mettre à jour le statut dans l'objet actuel si nécessaire
          
          // Réinitialiser le statut suivant
          this.nextStatus = '';
          
          // Recharger les données et émettre l'événement de rafraîchissement
          this.statusLoaded = false;
          this.loaded = false;
          this.loadStatusList();
          this.onChanged.emit(this.lease.getDto());
        },
        error: (error) => {
          console.error('Échec de la mise à jour du statut:', error);
        }
      });
    } else {
      console.warn('Mise à jour du statut non réalisée. Vérifiez les conditions:', {
        statutActuel: this.lease.status,
        statutSuivant: this.nextStatus,
        idBail: this.lease.id
      });
    }
  }
  
}