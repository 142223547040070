import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Rent } from '../../rent.class';
import { IOperationDto } from '../../../../operation/operation.dto';
import { LocaleDate } from '../../../../time/locale';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'plugin-rent-edit',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
  ],
  templateUrl: './rent-edit.plugin.html',
  styleUrl: './rent-edit.plugin.scss'
})
export class RentEditPlugin {
  rent: Rent = new Rent();
  @Input()
  set rentOperationDto(value: IOperationDto) {
    this.loadDto(value);
    console.log('top');
  }

  @Output() onUpdate: EventEmitter<IOperationDto> = new EventEmitter();
  @Output() onCancel: EventEmitter<void> = new EventEmitter();

  dataLoaded: boolean = false;

  constructor() {}

  loadDto(dto: IOperationDto) {
    console.log('Appel de loadDto avec DTO:', dto); // Log pour afficher le DTO reçu
    if (dto.idOperation !== this.rent.idOperation) {
      this.dataLoaded = false;
    }
    
    if (!this.dataLoaded) {
      console.log('Données non encore chargées, création de l\'objet Rent'); // Log si les données ne sont pas encore chargées
  
      this.rent = new Rent(dto);
      console.log('Objet Rent créé:', this.rent); // Log pour afficher l'objet Rent créé à partir du DTO
  
      this.dataLoaded = true;
      console.log('dataLoaded est maintenant défini à true'); // Log pour indiquer que dataLoaded est mis à jour
    } else {
      console.log('Données déjà chargées, skip de la création de l\'objet Rent'); // Log si les données étaient déjà chargées
    }
  }
  

  // Getter/Setter pour startLocale.date
  get startDate(): string {
    return this.rent.startLocale?.date || ''; // Retourne la date sous forme de string
  }

  set startDate(value: string) {
    if (!this.rent.startLocale) {
      this.rent.startLocale = new LocaleDate(value);
    } else {
      this.rent.startLocale.date = value; // Met à jour la date avec la nouvelle valeur
    }
  }

  // Getter/Setter pour endLocale.date
  get endDate(): string {
    return this.rent.endLocale?.date || ''; // Retourne la date sous forme de string
  }

  set endDate(value: string) {
    if (!this.rent.endLocale) {
      this.rent.endLocale = new LocaleDate(value);
    } else {
      this.rent.endLocale.date = value; // Met à jour la date avec la nouvelle valeur
    }
  }

  initializeEndLocale() {
    // Initialise endLocale si elle est undefined
    if (!this.rent.endLocale) {
      this.rent.endLocale = new LocaleDate('');
    }
  }

  save() {
    if (this.rent) {
      this.onUpdate.emit(this.rent.getDto());
    }
  }

  cancel() {
    this.onCancel.emit();
  }

}
