import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Accommodation } from '../../accommodation.class';
import { AccommodationService, IAccommodationRequestOptions } from '../../accommodation.service';
import { CommonModule } from '@angular/common';
import { AccommodationViewPlugin } from '../../plugin/view/accommodation-view.plugin';
import { AccommodationPickerPlugin } from "../../plugin/picker/accommodation-picker.plugin";
import { IAccommodationDto } from '../../accommodation.dto';
import { AccommodationEditPlugin } from '../../plugin/edit/accommodation-edit.plugin';

@Component({
  selector: 'route-accommodation-edit',
  standalone: true,
  imports: [
    CommonModule,
    AccommodationViewPlugin,
    AccommodationEditPlugin,
],
  templateUrl: './accommodation-edit.route.html',
  styleUrl: './accommodation-edit.route.scss'
})
export class AccommodationEditRoute implements OnInit {
  public accommodation: Accommodation = new Accommodation();
  public editMode: boolean = false; // Variable pour gérer le mode édition
  private dataLoaded: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private accommodationService: AccommodationService,
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      const id = +(params.get('id') || 0);
      this.loadData(id);
    });
  }

  loadData(id: number) {
    if (!this.dataLoaded) {
      if (!id || id === 0) {
        this.accommodation = new Accommodation();
        this.dataLoaded = true;
        this.editMode = true;
      } else {
        const options: IAccommodationRequestOptions = {
          loadAddress: true,
          loadOwnershipList: true,
        }
        this.accommodationService.get(id, options).subscribe(a => {
          if (a) {
            this.accommodation = a;
            this.dataLoaded = true;
          }
        })
      }
    }
  }

  toggleEditMode() {
    this.editMode = !this.editMode; // Basculer entre le mode vue et édition
  }

  save(dto: IAccommodationDto) {
    this.accommodation = new Accommodation(dto);
    this.accommodationService.save(this.accommodation).subscribe(a => {
      if (a) {
        this.loadData(a.id)
      }
    });
    this.toggleEditMode(); 
  }

  cancel() {
    this.toggleEditMode(); 
  }
}
