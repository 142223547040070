<div class="label-manager-container">
    <h2>Gestion des Labels</h2>
  
    <div class="group-picker-container">
      <plugin-label-group-picker 
        (selectedGroupChange)="onGroupSelected($event)"
      ></plugin-label-group-picker>
    </div>
  
    <div class="label-picker-container">
      <div class="label-picker-wrapper">
        <plugin-label-picker 
        [group]="selectedGroup"
        [refresh]="refreshPicker"
        (selectedLabelChange)="onLabelSelected($event)"
        (refreshCompleted)="refreshPicker = false"
      ></plugin-label-picker>
      </div>
      <div class="label-actions">
        <button (click)="addNewLabel()" class="action-button add-label-button">Ajouter</button>
        <button 
          (click)="deleteSelectedLabel()" 
          [disabled]="!selectedLabel || isNewLabel" 
          class="action-button delete-label-button"
        >Supprimer</button>
      </div>
    </div>
  
    <div class="label-edit-container">
      <plugin-label-edit 
        [selectedLabel]="selectedLabel"
        [isNew]="isNewLabel"
      ></plugin-label-edit>
    </div>
</div>