import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { OccupancyService } from '../../occupancy.service';
import { Occupancy } from '../../occupancy.class';

@Component({
  selector: 'widget-occupancy-select',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule
  ],
  templateUrl: './occupancy-select.widget.html',
  styleUrl: './occupancy-select.widget.scss'
})
export class OccupancySelectWidget implements OnChanges {
  @Input() personId: number | undefined;
  @Input() selectedOccupancyId: number | null | undefined;
  @Output() selectedOccupancyIdChange = new EventEmitter<number | null | undefined>();

  occupancies: Occupancy[] = [];

  constructor(private occupancyService: OccupancyService) {}

  ngOnChanges(changes: SimpleChanges): void {
    console.log('ngOnChanges triggered:', changes); // Log des changements reçus
    if (changes['personId']) {
      console.log('personId changed to:', this.personId);
      if (this.personId) {
        console.log('Loading occupancies for personId:', this.personId);
        this.loadOccupancies();
      } else {
        console.log('No personId provided, resetting occupancies');
        this.occupancies = [];
        this.selectedOccupancyId = undefined;
        this.selectedOccupancyIdChange.emit(undefined);
        console.log('Occupancies reset, selectedOccupancyId:', this.selectedOccupancyId);
      }
    }
    if (changes['selectedOccupancyId']) {
      console.log('selectedOccupancyId changed to:', this.selectedOccupancyId);
    }
  }

  private loadOccupancies(): void {
    if (this.personId) {
      console.log('Calling occupancyService.list with personIds:', [this.personId]);
      this.occupancyService
        .list({ personIds: [this.personId], loadAddress: true })
        .subscribe(
          (occupancies: Occupancy[] | null) => {
            console.log('Occupancies received from service:', occupancies);
            this.occupancies = occupancies || [];
            console.log('Occupancies set to:', this.occupancies);
            // Vérifier que selectedOccupancyId est toujours valide
            if (this.selectedOccupancyId && !this.occupancies.some(occ => occ.id === this.selectedOccupancyId)) {
              console.log('Selected occupancy ID', this.selectedOccupancyId, 'not found in occupancies, resetting');
              this.selectedOccupancyId = undefined;
              this.selectedOccupancyIdChange.emit(undefined);
              console.log('selectedOccupancyId reset to:', this.selectedOccupancyId);
            }
          },
          error => console.error('Error loading occupancies:', error)
        );
    } else {
      console.warn('loadOccupancies called with no personId');
    }
  }

  onOccupancyChange(): void {
    console.log('Occupancy selection changed to:', this.selectedOccupancyId);
    this.selectedOccupancyIdChange.emit(this.selectedOccupancyId);
  }
}