import { BaseEntity } from "../../base-entity.class";
import { IAccommodationDto } from "./accommodation.dto";
import { Address } from "../address/address.class";
import { Ownership } from "../ownership/ownership.class";


export class Accommodation extends BaseEntity<IAccommodationDto> {
    id: number = 0;
    name: string = '';
    idAddress: number = 0;
    inlineDescription: string = '';
    excludedParts: string = '';
    fullDescription: string = '';
    recentWorkDescription: string = '';
    previousRentInformation: string = '';
    rentByMonthReference?: number;
    feesByMonthReference?: number;
    rentByMonthListed?: number;
    feesByMonthListed?: number;
    timezone: string = '';
    address?: Address;
    ownerships?: Ownership[] = [];

    constructor(dto?: IAccommodationDto) {
        super(dto);
        if (dto) this.loadDto(dto);
    }

    protected override getMappings(): Partial<Record<keyof IAccommodationDto, keyof this>> {
        return {
            idAccommodation: 'id',
            name: 'name',
            idAddress: 'idAddress',
            inlineDescription: 'inlineDescription',
            excludedParts: 'excludedParts',
            fullDescription: 'fullDescription',
            recentWorkDescription: 'recentWorkDescription',
            previousRentInformation: 'previousRentInformation',
            rentByMonthReference: 'rentByMonthReference',
            feesByMonthReference: 'feesByMonthReference',
            rentByMonthListed: 'rentByMonthListed',
            feesByMonthListed: 'feesByMonthListed',
            timezone: 'timezone'
        };
    }

    protected override loadComplexProperties(dto: IAccommodationDto): void {
        // console.log('Loading complex properties:', dto);
        if (dto.address) {
            this.address = new Address(dto.address);
            // console.log('Loaded address:', this.address);
        }
        if (dto.ownerships) {
            this.ownerships = dto.ownerships.map(o => new Ownership(o));
            // console.log('Loaded ownerships:', this.ownerships);
        }
    }

    protected override getComplexProperties(dto: IAccommodationDto): void {
        if (this.address) {
            dto.address = this.address.getDto();
        }
        if (this.ownerships) {
            dto.ownerships = this.ownerships.map(o => o.getDto());
        }
    }

}
