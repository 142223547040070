import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RentService } from '../../rent.service';
import { Rent } from '../../rent.class';
import { CommonModule } from '@angular/common';
import { RentEditPlugin } from '../../plugin/edit/rent-edit.plugin';
import { IOperationDto } from '../../../../operation/operation.dto';
import { StatusViewPlugin } from '../../../../operation/status/plugin/view/status-view.plugin';
import { RentStatusNextPlugin } from "../../status/plugin/nextRentStatus/rent-status-next.plugin";
import { RentReceiptDownloadPlugin } from '../../plugin/receiptDL/rent-receipt-download.plugin';
import { OccupancyViewPlugin } from '../../../../contact/occupancy/plugin/view/occupancy-view.plugin';
import { RentReceiptGDrivePlugin } from '../../plugin/receipt-gdrive/rent-receipt-gdrive.plugin';

@Component({
  selector: 'route-rent-edit',
  standalone: true,
  imports: [
    CommonModule,
    RentEditPlugin,
    StatusViewPlugin,
    RentStatusNextPlugin,
    RentReceiptDownloadPlugin,
    RentReceiptGDrivePlugin,
    OccupancyViewPlugin,
],
  templateUrl: './rent-edit.route.html',
  styleUrl: './rent-edit.route.scss'
})
export class RentEditRoute implements OnInit {
  rent: Rent = new Rent();
  occupancyId: number = 0;
  dataLoaded: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private rentService: RentService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    if (!this.dataLoaded) {
      this.route.paramMap.subscribe(params => {
        const id = +(params.get('id') || 0);
        console.log('ParamMap received:', id);  // Ajout de console.log pour déboguer
        if (id) {
          console.log('Calling loadData with id:', id);  // Ajout de console.log avant l'appel de loadData
          this.loadData(id);
        }
      });
    }
  }

  loadData(id: number) {
    console.log('loadData called with id:', id);  // Ajout de console.log pour indiquer l'appel de la fonction
    if (!this.dataLoaded) {
      console.log('on essaie');
      this.rentService.get(id).subscribe(r => {
        if (r) {
          console.log('Rent data received:', r);  // Ajout de console.log pour afficher les données reçues
          this.rent = r;
          this.dataLoaded = true;
        } else {
          console.log('No rent data found for id:', id);  // Si aucune donnée n'est trouvée
        }
      }, error => {
        console.error('Error fetching rent data:', error);  // Gestion des erreurs avec console.error
      });
  
    }
  }
    
  cancel() {
    console.log('Cancel action triggered');  // Ajout de console.log pour déboguer l'action d'annulation
    // retour au dashboard
    this.router.navigate(['management/dashboard']);
  }

  update(dto: IOperationDto) {
    this.rent = new Rent(dto);
    console.log('Update action triggered with DTO:', dto);  // Ajout de console.log pour suivre l'appel à la mise à jour    
    this.rentService.save(this.rent).subscribe(r => {
      if (r) {
        this.rent = r;
        this.dataLoaded = false;
      } else {
        console.error('erreur lors de la sauvegarde de rent');
      }
    })
  }

  updateStatus(code: string) {
    console.log("code", code);
    this.rentService.updateStatus(this.rent, code).subscribe(r => {
      if (r) {
        this.rent = r;
        console.log('retour update status', this.rent);
        this.dataLoaded = false;
      } else {
        console.error('erreur mise à jour statut');
      }
    });
  }

  printReceipt(idOperation: number) {
    this.rentService.printReceipt(this.rent).subscribe(success => {
        if (success) {
            console.log('Téléchargement de la quittance réussi');
        } else {
            console.log('Échec du téléchargement de la quittance');
        }
    });
  }

  sendToGDrive(idOperation: number) {
    console.log('Sending receipt to Google Drive for operation ID:', idOperation);
    this.rentService.sendToGDrive(this.rent).subscribe(success => {
      if (success) {
        console.log('Envoi vers Google Drive réussi');
      } else {
        console.log('Échec de l’envoi vers Google Drive');
      }
    });
  }
  
  viewLease() {
    if (this.rent.idParent) {
      this.router.navigate(['/lease', this.rent.idParent]);
    } else {
      console.warn('Lease ID is not available for this rent');
    }
  }

  deleteOperation() {
    if (this.rent.idOperation) {
      console.log('Deleting operation with ID:', this.rent.idOperation);
      this.router.navigate(['/rent/confirmdelete', this.rent.idOperation]);
    } else {
      console.warn('Operation ID is not available for deletion');
    }
  }
}
