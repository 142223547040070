import { Component, Input } from '@angular/core';
import { Address } from '../../address.class';
import { IAddressDto } from '../../address.dto';
import { CommonModule } from '@angular/common';
import { AddressService, IAddressRequestOptions } from '../../address.service';

@Component({
  selector: 'plugin-address-view',
  standalone: true,
  imports: [
    CommonModule,
  ],
  templateUrl: './address-view.plugin.html',
  styleUrls: ['./address-view.plugin.scss'],
})
export class AddressViewPlugin {
  public address: Address = new Address();

  @Input()
  set addressDto(value: IAddressDto) {
    if (value) {
      this.address = new Address(value);
    }
  }

  @Input() 
  set options(value: IAddressRequestOptions) {
    this.loadData(value.id || 0);
  }

  constructor(
    private addressService: AddressService,
  ) {}

  loadData(id: number) {
    this.addressService.get(id).subscribe(a => {
      if (a) {
        this.address = a;
      }
    })
  }

}
