import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { OccupancyDataEditPlugin } from '../data-edit/occupancy-data-edit.plugin';
import { AddressViewPlugin } from '../../../../wealth/address/plugin/view/address-view.plugin';
import { IOccupancyRequestOptions, OccupancyService } from '../../occupancy.service';
import { AddressService, IAddressRequestOptions } from '../../../../wealth/address/address.service';
import { Occupancy } from '../../occupancy.class';
import { AddressInstanciatePlugin } from '../../../../wealth/address/plugin/instanciate/address-instanciate.plugin';
import { IAddressDto } from '../../../../wealth/address/address.dto';
import { Address } from '../../../../wealth/address/address.class';
import { IOccupancyDto } from '../../occupancy.dto';

@Component({
  selector: 'plugin-occupancy-edit',
  standalone: true,
  imports: [
    CommonModule,
    OccupancyDataEditPlugin,
    AddressViewPlugin,
    AddressInstanciatePlugin,
  ],
  templateUrl: './occupancy-edit.plugin.html',
  styleUrl: './occupancy-edit.plugin.scss'
})

export class OccupancyEditPlugin {
  @Input()
  set options(value: IOccupancyRequestOptions) {
    if (!this.loaded) {
      console.log('activation OccupancyEditPlugin', value);
      this._options = value;
      this.loadData();
    }
  }
  
  @Output() onSaved: EventEmitter<IOccupancyDto> = new EventEmitter();
  @Output() onCanceled: EventEmitter<void> = new EventEmitter();

  occupancy: Occupancy = new Occupancy();
  loaded: boolean = false;
  _options: IOccupancyRequestOptions = { showContact: false };
  addressOptions: IAddressRequestOptions = {}
  addressDto: IAddressDto = {};

  constructor(
    private occupancyService: OccupancyService,
    private addressService: AddressService,
  ) {}

  // Charge le plugin à partir des options
  loadData() {
    if (!this.loaded) {
      if (this._options.id === 0) {
        // nouvelle occupation
        this.occupancy = new Occupancy();
        this.addressDto = (new Address()).getDto();
        this.occupancy.idContact = this._options.personId || 0;   // met à jour le contact id en input
        this.loaded = true;
      } else {
        // occupation existante
        this.occupancyService.get(this._options.id || 0).subscribe({
          next: (o) => {
            if (o) {
              this.occupancy = o;
              this.loaded = true;
            }
          },
          error: (err) => {
            console.error('Error fetching occupancy data:', err);
          },
          complete: () => {
            console.log('Finished fetching occupancy data');
          }
        });
      }
    }
  }

  // addresse reçue (instanciate)
  // le dto est instancié
  addressSaved(dto: IAddressDto) {
    const address: Address = new Address(dto);
    this.addressService.instanciate(address).subscribe(a => {
      if (a) {
        this.occupancy.idAddress = a.idAddress || 0;
        // on passe à address view
        this.occupancy.address = a;
        this.addressDto = this.occupancy.address.getDto();
      }
    })
    // if (this.occupancy) {
    //   this.occupancy.idAddress = address.idAddress || 0;
    // }
    
  }

  // contrôle du plugin instanciate (address edit / view)
  get addressEditMode(): boolean {
    return this.occupancy.isNew && this.occupancy.idAddress === 0;
  }

  // sauvegarde des data (hors contact et address)
  saveData(dto: IOccupancyDto) {
    // Affichage des logs pour le débogage
    console.log('DTO reçu dans saveData:', dto);
    
    // Nous fusionnons les propriétés de dto dans this.occupancy
    // mais nous nous assurons de ne pas écraser idAddress et idContact
    const updatedOccupancy = new Occupancy(dto);
  
    // Conserver les valeurs critiques : idAddress et idContact
    updatedOccupancy.idAddress = this.occupancy.idAddress;
    updatedOccupancy.idContact = this.occupancy.idContact;
  
    // Mettre à jour l'objet occupancy avec les nouvelles données
    this.occupancy = updatedOccupancy;
  
    // Log après mise à jour
    console.log('Occupancy mise à jour dans saveData:', this.occupancy);

    // si adresse et contact ok => on sort
    if (this.occupancy.idAddress !== 0) {
      // on sauvegarde l'occupancy
      this.occupancyService.add(this.occupancy).subscribe(o => {
        if (o) {
          this.onSaved.emit(o.getDto());
        }
      })
    }
  }

  cancelData() {
    this.onCanceled.emit();
  }

}
