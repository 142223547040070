import { Tag } from "./tag/tag.class";

type Constructor<T> = new (...args: any[]) => T;

export abstract class BaseEntity<TDto> {
    tags?: Tag[];
    constructor(dto?: TDto) {}

    public loadDto(dto: TDto): void {
        const mappings = this.getMappings();
        (Object.keys(mappings) as (keyof TDto)[]).forEach(dtoKey => {
            const entityKey = mappings[dtoKey];
            if (dto[dtoKey] !== undefined && entityKey !== undefined) {
                (this as any)[entityKey] = dto[dtoKey];
            }
        });
        this.setComplexProperties(dto);
    }

    getDto(): TDto {
        const dto: any = {};
        const mappings = this.getMappings();
        // console.log("Mappings:", mappings);
        (Object.keys(mappings) as (keyof TDto)[]).forEach(dtoKey => {
            const entityKey = mappings[dtoKey];
            if (this[entityKey as keyof this] !== undefined && entityKey !== undefined) {
                dto[dtoKey as keyof TDto] = this[entityKey as keyof this];
                // console.log("dto[dtoKey as keyof TDto] = this[entityKey as keyof this];", dtoKey, entityKey, this[entityKey as keyof this]);
            }
        });
        this.getComplexProperties(dto);
        return dto;
    }

    protected abstract getMappings(): Partial<Record<keyof TDto, keyof this>>;

    protected setComplexProperties(dto: TDto) {
        // To be overridden by subclasses if needed
    }

    protected getComplexProperties(dto: any) {
        if (this.tags) dto.tags = this.tags.map(t => t.getDto());
        // To be overridden by subclasses if needed
    }

    addTag(tag: Tag) {
        if (!this.tags) this.tags = [];
        this.tags.push(tag);
    }
}
