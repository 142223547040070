<div class="occupancy-edit">
    <!-- Debugging information -->
    <p>Occupancy isNew: {{ occupancy.isNew }}</p>
    <p>Address id: {{ occupancy.idAddress }}</p>
  
    <!-- Plugin d'édition des données d'occupancy -->
    <plugin-occupancy-data-edit [occupancyDto]="occupancy.getDto()" (onSave)="saveData($event)" (onCancel)="cancelData()"></plugin-occupancy-data-edit>
  
    <!-- Condition d'affichage : Afficher address instanciate si l'occupancy est nouveau, sinon address view -->
    <ng-container *ngIf="addressEditMode; else addressView">
      <p>Displaying address instanciate because occupancy is new</p>
      <plugin-address-instanciate [addressDto]="addressDto" (onSave)="addressSaved($event)"></plugin-address-instanciate>
    </ng-container>
  
    <!-- Afficher plugin-address-view si l'occupancy n'est pas nouveau -->
    <ng-template #addressView>
      <p>Displaying address view because occupancy is not new</p>
      <plugin-address-view [addressDto]="addressDto"></plugin-address-view>
    </ng-template>
  </div>
  