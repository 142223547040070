<div class="vat-list">
    <h4>Détails TVA</h4>
    <div *ngIf="vats && vats.length > 0; else noVats">
      <table>
        <thead>
          <tr>
            <th>Code TVA</th>
            <th>Taux (%)</th>
            <th>Montant HT</th>
            <th>Montant TVA</th>
            <th>Total</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let vat of vats">
            <td (click)="editVat(vat)">{{ vat.codeVAT || 'N/A' }}</td>
            <td (click)="editVat(vat)">{{ vat.rateVAT | number:'1.2-2' }}</td>
            <td class="right-align" (click)="editVat(vat)">{{ vat.amountExcludingVAT | number:'1.2-2' }} €</td>
            <td class="right-align" (click)="editVat(vat)">{{ vat.amountVAT | number:'1.2-2' }} €</td>
            <td class="right-align" (click)="editVat(vat)">{{ vat.amount | number:'1.2-2' }} €</td>
            <td>
              <button (click)="deleteVat(vat.id)" class="btn-delete">Supprimer</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <ng-template #noVats>
      <p>Aucune TVA associée à cet item.</p>
    </ng-template>
    <button type="button" (click)="editVat()" class="btn-add-vat">Ajouter TVA</button>
    <div *ngIf="isEditingVat">
      <plugin-invoice-item-vat-edit 
        [itemId]="itemId"
        [vatDto]="selectedVatDto" 
        (saved)="onVatSaved($event)"
        (cancelled)="cancelVatEdit()"
      ></plugin-invoice-item-vat-edit>
    </div>
  </div>