import { InvoiceItemVat } from "../vat/invoice-item-vat.class";
import { IInvoiceItemVatDto } from "../vat/invoice-item-vat.dto";
import { IInvoiceItemDto } from "./invoice-item.dto";

export class InvoiceItem {
    id: number = 0;
    idInvoice: number = 0;
    reference: string | null = null;
    description: string | null = null;
    quantity: number | null = null;
    totalExcludingVAT: number | null = null;
    totalVAT: number | null = null;
    total: number | null = null;
    vats?: InvoiceItemVat[];
  
    constructor(dto?: IInvoiceItemDto) {
      if (dto) this.loadDto(dto);
    }
  
    private loadDto(dto: IInvoiceItemDto): void {
      for (const key in dto) {
        if (dto.hasOwnProperty(key)) {
          (this as any)[key] = (dto as any)[key];
        }
      }
      this.setComplexProperties(dto);
    }
  
    private setComplexProperties(dto: IInvoiceItemDto): void {
      if (dto.vats) {
        this.vats = dto.vats.map(vatDto => new InvoiceItemVat(vatDto));
      }
    }
  
    public getDto(): IInvoiceItemDto {
      const dto: IInvoiceItemDto = {
        id: this.id,
        idInvoice: this.idInvoice,
        reference: this.reference,
        description: this.description,
        quantity: this.quantity,
        totalExcludingVAT: this.totalExcludingVAT,
        totalVAT: this.totalVAT,
        total: this.total
      };
      
      if (this.vats) dto.vats = this.vats.map(vat => vat.getDto() as IInvoiceItemVatDto);
  
      return dto;
    }
  }
