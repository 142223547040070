<div class="container-fluid">
  <!-- Bandeau en haut avec logo et topics -->
  <header class="header">
    <div class="logo">
      <img src="assets/img/logo.png" alt="Logo gestion immobilière">
    </div>
    <div class="topics">
      <widget-topics></widget-topics>
    </div>
    <!-- Lien Se connecter à droite -->
    <widget-profile-status></widget-profile-status>
  </header>

  <!-- Colonne sur la gauche (sous le logo) avec les sections -->
  <aside class="sidebar">
    <widget-sections></widget-sections>
  </aside>

  <!-- Contenu principal -->
  <div class="content">
    <router-outlet></router-outlet>
  </div>

  <!-- Colonne flottante à droite, cachée par défaut si isColumnVisible est false -->
  <aside class="floating-column" id="floatingColumn" [class.hidden]="!isColumnVisible">
    <h5>Colonne Contextuelle</h5>
    <p>Ceci est la colonne flottante.</p>
    <widget-side-info></widget-side-info>
  </aside>

  <!-- Bouton toggle pour afficher/masquer la colonne flottante -->
  <button class="btn btn-primary" id="toggleColumn" (click)="toggleColumn()">Cacher/Montrer Colonne</button>
</div>
