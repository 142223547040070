import { Injectable } from '@angular/core';
import { INavItem } from './nav.item';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { ERoleCode } from '../user/roles.enum';
import { ETopic } from './topics.enum';
import { ESection } from './sections.enum';

@Injectable({
  providedIn: 'root'
})
export class NavService {
  private topics: INavItem[] = [
    { id: ETopic.Management, 
      label: 'Gestion', 
      route: '/management', 
      rolesAllowed: [ERoleCode.SUPER, ERoleCode.ADMIN, ERoleCode.GEST, ERoleCode.LOCAT],
    },
    { id: ETopic.Rental, 
      label: 'Location', 
      route: '/rental', 
      rolesAllowed: [ERoleCode.SUPER, ERoleCode.ADMIN, ERoleCode.GEST, ERoleCode.LOCAT],
    },
    { id: ETopic.Wealth, 
      label: 'Patrimoine', 
      route: '/wealth', 
      rolesAllowed: [ERoleCode.SUPER, ERoleCode.ADMIN, ERoleCode.GEST],
    },
    { id: ETopic.Administration, 
      label: 'Administration', 
      route: '/user', 
      rolesAllowed: [ERoleCode.SUPER, ERoleCode.ADMIN],
    },
    { id: ETopic.Dev, 
      label: 'Dev', 
      route: '/dev', 
      rolesAllowed: [ERoleCode.SUPER, ERoleCode.ADMIN],
    },
  ];

  private sections: INavItem[] = [
    { id: ESection.Collection, 
      parentId: ETopic.Management, 
      label: 'Loyers', 
      route: '/management/dashboard', 
      rolesAllowed: [ERoleCode.SUPER, ERoleCode.ADMIN, ERoleCode.GEST] 
    },

    { id: ESection.ContactList, 
      parentId: ETopic.Rental, 
      label: 'Contacts', 
      route: '/contact/list', 
      rolesAllowed: [ERoleCode.SUPER, ERoleCode.ADMIN, ERoleCode.GEST] 
    },
    { id: ESection.LeaseList, 
      parentId: ETopic.Rental, 
      label: 'Baux', 
      route: '/lease/list', 
      rolesAllowed: [ERoleCode.SUPER, ERoleCode.ADMIN, ERoleCode.GEST] 
    },

    { id: ESection.AccommodationList, 
      parentId: ETopic.Wealth, 
      label: 'Logements', 
      route: '/accommodation/list', 
      rolesAllowed: [ERoleCode.SUPER, ERoleCode.ADMIN, ERoleCode.GEST] 
    },
    
    { id: ESection.AddressList, 
      parentId: ETopic.Wealth, 
      label: 'Adresses', 
      route: '/address/list', 
      rolesAllowed: [ERoleCode.SUPER, ERoleCode.ADMIN, ERoleCode.GEST] 
    },
    
    { id: ESection.UserCreate, 
      parentId: ETopic.Administration, 
      label: 'Nouveau', 
      route: '/user/create', 
      rolesAllowed: [ERoleCode.SUPER, ERoleCode.ADMIN, ERoleCode.GEST] 
    },
    { id: ESection.UserList, 
      parentId: ETopic.Administration, 
      label: 'Liste', 
      route: '/user/list', 
      rolesAllowed: [ERoleCode.SUPER, ERoleCode.ADMIN, ERoleCode.GEST] 
    },
    { id: ESection.DevIssuerOccupancySelectorCOP, 
      parentId: ETopic.Dev, 
      label: 'IsserOccupancySelectorCOP', 
      route: '/dev/issuer-occupancy-selector-cop', 
      rolesAllowed: [ERoleCode.SUPER, ERoleCode.ADMIN] 
    },
  ];

  private selectedTopicIdSubject = new BehaviorSubject<string | null>(null);
  public selectedTopicId$: Observable<string | null> = this.selectedTopicIdSubject.asObservable();

  constructor() {}

  // Méthode pour filtrer les topics selon les rôles
  getFilteredTopics(roles: string[]): INavItem[] {
    return this.topics.filter(topic => topic.rolesAllowed?.some(role => roles.includes(role)));
  }

  getFilteredSections(roles: string[], topicId: string): Observable<INavItem[]> {
    const filteredSections = this.sections.filter(section =>
      section.parentId === topicId && section.rolesAllowed?.some(role => roles.includes(role))
    );
    return of(filteredSections); // Retourner un Observable ici
  }

  // Méthode pour changer de topic
  selectTopic(topicId: string): void {
    this.selectedTopicIdSubject.next(topicId);
  }

}
