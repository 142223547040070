import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Occupancy } from '../../../../contact/occupancy/occupancy.class';
import { ILeaseDto } from '../../lease.dto';
import { Lease } from '../../lease.class';
import { IOccupancyRequestOptions, OccupancyService } from '../../../../contact/occupancy/occupancy.service';
import { map } from 'rxjs';

@Component({
  selector: 'plugin-occupancy-selector',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
  ],
  templateUrl: './occupancy-selector.plugin.html',
  styleUrl: './occupancy-selector.plugin.scss'
})
/**
 * le leaseDto en input doit comprendre les lessors
 */
export class OccupancySelectorPlugin {
  lease: Lease = new Lease();

  @Input() 
  set leaseDto(dto: ILeaseDto) {
    this.lease = new Lease(dto);
    this.loadData();
  };

  @Input() occupancyList: { id: number, displayText: string }[] = [];

  // Valeur sélectionnée
  @Input() selectedOccupancyId: number | null = null;

  // Événement émis lors de la sélection d'une valeur
  @Output() onSelect: EventEmitter<number> = new EventEmitter<number>();

  // Fonction appelée lors du changement de sélection
  selectOccupancy() {
    if (this.selectedOccupancyId !== null) {
      console.log('Occupancy sélectionnée:', this.selectedOccupancyId);
      this.onSelect.emit(this.selectedOccupancyId); // Émettre la valeur sélectionnée
    }
  }
  

  constructor(
    private occupancyService: OccupancyService,
  ) {}

  // on fabrique la liste des occupancy des lessors
  loadData() {
    console.log('lease', this.lease);
    const lessorsIds: number[] = this.lease.getLessorsIds();
    console.log('lessors ids', lessorsIds);

    const options: IOccupancyRequestOptions = {
      personIds: lessorsIds,
      loadAddress: true,
      loadPerson: true,
    };

    this.occupancyService.list(options).pipe(
      map((occupancyList: Occupancy[] | null) => {
        if (!occupancyList) {
          console.warn('No occupancy data available.');
          return []; // Retourne une liste vide si aucun occupancy trouvé
        }
        // Créer une liste formatée pour la liste déroulante
        return occupancyList.map(occupancy => {
          // Formatter le nom du contact
          const contactName = occupancy.contact
            ? (occupancy.contact.company || `${occupancy.contact.firstName} ${occupancy.contact.lastName}`).slice(0, 15)
            : 'Contact inconnu';
          // Formatter l'adresse
          const address = occupancy.address
            ? `${occupancy.address.streetNumber || ''} ${occupancy.address.streetName || ''}`.slice(0, 15)
            : 'Adresse inconnue';
          // Nom de la ville
          const city = occupancy.address?.city || 'Ville inconnue';

          // Retourne la chaîne formatée pour la liste
          return {
            id: occupancy.id,
            displayText: `${contactName} - ${address} - ${city}`
          };
        });
      })
    ).subscribe({
      next: (formattedList) => {
        this.occupancyList = formattedList; // Mettre à jour la liste des occupancy formatée
        console.log('Formatted occupancy list:', this.occupancyList);
      },
      error: (err) => {
        console.error('Error loading occupancy list:', err);
      }
    });
  }

}
