<div *ngIf="invoices && invoices.length > 0; else loading">
  <table>
    <thead>
      <tr>
        <th>N° Facture</th>
        <th>Émetteur</th>
        <th>Destinataire</th>
        <th>Date d'émission</th>
        <th>Montant Total</th>
        <th>Statut</th>
      </tr>
    </thead>
    <tbody>
      <tr 
        *ngFor="let invoice of invoices" 
        (click)="onSelect(invoice)" 
        (dblclick)="onActivate(invoice)" 
        [ngClass]="{'selected': invoice === selectedInvoice}"
      >
        <td>{{ invoice.invoiceId }}</td>
        <td>
          <div>{{ invoice.issuer?.company }}</div>
          <div *ngIf="invoice.issuer?.firstName || invoice.issuer?.lastName" style="font-size: smaller;">
            {{ invoice.issuer?.firstName }} {{ invoice.issuer?.lastName }}
          </div>
        </td>
        <td>
          <div>{{ invoice.recipient?.company }}</div>
          <div *ngIf="invoice.recipient?.firstName || invoice.recipient?.lastName" style="font-size: smaller;">
            {{ invoice.recipient?.firstName }} {{ invoice.recipient?.lastName }}
          </div>
        </td>
        <td>{{ invoice.dtIssueLocale }}</td>
        <td class="right-align">{{ invoice.totalEUR }}</td>
        <td>{{ invoice.codeStatus }}</td>
      </tr>
    </tbody>
  </table>
</div>
<ng-template #loading>
  <p>Chargement des factures...</p>
</ng-template>