<div class="address-edit-route">
    <!-- Affichage du plugin vue -->
    <div *ngIf="!editMode">
      <plugin-address-view [addressDto]="address.getDto()"></plugin-address-view>
      <button (click)="toggleEditMode()" class="btn-edit">Modifier</button>
    </div>
  
    <!-- Affichage du plugin édition -->
    <div *ngIf="editMode">
      <plugin-address-edit 
        [addressDto]="address.getDto()" 
        (onSave)="saveAddress($event)" 
        (onCancel)="cancelEdit()">
      </plugin-address-edit>
    </div>
  </div>
  