import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { LeaseStatusManagerPlugin } from '../../plugin/status-manager/lease-status-manager.plugin';
import { ActivatedRoute, Router } from '@angular/router';
import { Lease } from '../../lease.class';
import { ILeaseRequestOptions, LeaseService } from '../../lease.service';
import { LeaseDataViewPlugin } from '../../plugin/data-view/lease-data-view.plugin';
import { ILeaseDto } from '../../lease.dto';

@Component({
  selector: 'route-lease-status-edit',
  standalone: true,
  imports: [
    CommonModule,
    LeaseStatusManagerPlugin,
    LeaseDataViewPlugin,
  ],
  templateUrl: './lease-status-edit.route.html',
  styleUrl: './lease-status-edit.route.scss'
})
export class LeaseStatusEditRoute implements OnInit {
  lease: Lease = new Lease();
  loaded: boolean = false;
  
  constructor(
    private route: ActivatedRoute,
    private leaseService: LeaseService,
    private router: Router,
  ) {}

  ngOnInit() {
    if (!this.loaded) {
      this.route.paramMap.subscribe(params => {
        const id = +(params.get('idlease') || 0);
        this.loadLease(id);
      });
    }
  }

  loadLease(id: number = 0) {
    console.log('Chargement du bail avec ID:', id);
    
    if (id === 0) {
        console.log('ID est 0, création d\'un nouveau bail');
        this.lease = new Lease();
        this.loaded = true;
        console.log('Nouveau bail créé, mode chargé:', this.loaded);
    } else {
        console.log('Chargement du bail existant avec ID:', id);
        
        const options: ILeaseRequestOptions = {
            loadAccommodation: true,
            loadLessors: true,
            loadTenants: true,
            loadOperations: true,
        };
        console.log('Options de chargement:', options);

        this.leaseService.get(id, options).subscribe(
            l => {
                if (l) {
                    console.log('Données du bail reçues:', l);
                    this.lease = l;
                } else {
                    console.log('Aucun bail trouvé, création d\'un nouveau bail');
                    this.lease = new Lease();
                }
                this.loaded = true;
                console.log('Chargement terminé, mode chargé:', this.loaded);
            },
            error => {
                console.error('Erreur lors du chargement du bail:', error);
                this.loaded = true;
            }
        );
    }
  }

  statusChanged(dto: ILeaseDto) {
    this.router.navigate(['lease/list']);
  }
}
