<div *ngIf="emailDto; else noEmail" class="contact-email-edit">
  <h2>Edit Email</h2>
  <form [formGroup]="emailForm">
    <div>
      <label for="emailTypeField">Type d'email:</label>
      <input id="emailTypeField" formControlName="emailTypeField" type="text" />
    </div>
    <div>
      <label for="emailAddressField">Adresse email:</label>
      <input id="emailAddressField" formControlName="emailAddressField" type="email" required />
    </div>
    <div>
      <label for="isFavoriteField">Favori:</label>
      <input id="isFavoriteField" formControlName="isFavoriteField" type="checkbox" />
    </div>
    <div class="button-container">
      <button type="button" (click)="save()">Sauvegarder</button>
      <button type="button" (click)="cancel()">Annuler</button>
    </div>
  </form>
</div>

<ng-template #noEmail>
  <p>Aucun email sélectionné.</p>
</ng-template>
