import { ITagDto } from "./tag.dto";

export class Tag {
    // #region Attributes
    idTag: number = 0;
    label: string = '';
    classParent?: string;
    // idParent?: number;
    free?: boolean;
    // #endregion

    constructor(dto?: ITagDto) {
        if (dto) this.loadDto(dto);
    }

    loadDto(dto: ITagDto) {
        this.idTag = dto.idTag ?? 0;
        this.label = dto.label ?? '';
        this.classParent = dto.classParent;
        // this.idParent = dto.idParent;
        this.free = dto.free;
    }

    getDto(): ITagDto {
        const dto: ITagDto = {
            idTag: this.idTag,
            label: this.label,
            classParent: this.classParent,
            // idParent: this.idParent,
            free: this.free,
        };
        return dto;
    }
}