export enum ESection {
    Utilisateurs = 'SECTION_UTILISATEURS',
    Locataires = 'SECTION_LOCATAIRES',
    ContactList = 'SECTION_CONTACT_LIST',
    AccommodationList = 'SECTION_ACCOMMODATION_LIST',
    AddressList = 'SECTION_ADRESS_LIST',
    LeaseList = 'SECTION_LEASE_LIST',
    Collection = 'SECTION_COLLECTION',
    UserCreate = 'SECTION_USER_CREATE',
    UserList = 'SECTION_USER_LIST',
    DevIssuerOccupancySelectorCOP = 'SECTION_DEV_ISSUER_OCCUPANCY_SELECTOR_COP',
  }