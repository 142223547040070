export enum ELeaseStatus {
    NEW = 'NEW',
    OWNER_SIGN = 'OWS',
    TENANT_SIGN = 'TNS',
    MOVEIN_INSPECT = 'MOVIN',
    MOVEOUT_INSPECT = 'MOVOUT',
    READY = 'RDY',
    RUNNING = 'RUN',
    STOPPED = 'STOP',
    CLOSED = 'CLOSED',
}