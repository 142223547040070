<div class="occupancy-list">
  <div class="occupancy-item" *ngFor="let occupancy of list">
    <div class="occupancy-details">
      <h3>Occupancy #{{ occupancy.id }}</h3>

      <!-- Contact Information -->
      <div *ngIf="occupancy.contact">
        <strong>Contact:</strong> {{ occupancy.contact.firstName }} {{ occupancy.contact.lastName }}
      </div>

      <!-- Address Information -->
      <div *ngIf="occupancy.address">
        <strong>Address:</strong> 
        <div class="address-info">
          {{ occupancy.address.streetNumber }} {{ occupancy.address.streetType }} {{ occupancy.address.streetName }}, 
          {{ occupancy.address.postalCode }} {{ occupancy.address.city }}, {{ occupancy.address.country }}
        </div>
      </div>

      <!-- Additional Info -->
      <div *ngIf="occupancy.additionalInfo">
        <strong>Additional Info:</strong> {{ occupancy.additionalInfo }}
      </div>

      <!-- Occupancy Dates -->
      <div *ngIf="occupancy.dtStart || occupancy.dtEnd">
        <strong>Occupancy Period:</strong>
        <span *ngIf="occupancy.dtStart">From {{ occupancy.dtStart | date: 'longDate' }}</span>
        <span *ngIf="occupancy.dtEnd"> to {{ occupancy.dtEnd | date: 'longDate' }}</span>
      </div>

      <!-- Action Buttons -->
      <div class="action-buttons">
        <button class="btn-delete" (click)="deleteOccupancy(occupancy)">Supprimer</button>
        <!-- <button class="btn-complete" (click)="completeOccupancy(occupancy)">Terminer</button> -->
      </div>
    </div>
  </div>
</div>
