<div class="invoice-view" *ngIf="invoice">
    <h2>Facture {{ invoice.invoiceId === 0 ? 'Nouvelle' : invoice.invoiceId }}</h2>
  
    <div class="view-group">
      <label>Type de facture:</label>
      <span>{{ invoice.typeCode }}</span>
    </div>
  
    <div class="view-group">
      <label>ID de l'émetteur:</label>
      <widget-contact-name-view [contactId]="invoice.idIssuer"></widget-contact-name-view>
    </div>
  
    <div class="view-group" *ngIf="invoice.idIssuerOccupancy">
      <label>Occupation Émetteur:</label>
      <widget-occupancy-short-view [occupancyId]="invoice.idIssuerOccupancy"></widget-occupancy-short-view>
    </div>

    <div class="view-group">
      <label>ID du destinataire:</label>
      <widget-contact-name-view [contactId]="invoice.idRecipient"></widget-contact-name-view>
    </div>
  
    <div class="view-group" *ngIf="invoice.idRecipientOccupancy">
      <label>Occupation Destinataire:</label>
      <widget-occupancy-short-view [occupancyId]="invoice.idRecipientOccupancy"></widget-occupancy-short-view>
    </div>

    <div class="view-group">
      <label>Date de début:</label>
      <span>{{ invoice.dtStartLocale | date:'dd/MM/yyyy' }}</span>
    </div>
  
    <div class="view-group">
      <label>Date de fin:</label>
      <span>{{ invoice.dtEndLocale | date:'dd/MM/yyyy' }}</span>
    </div>

    <div class="view-group">
      <label>Date d'émission:</label>
      <span>{{ invoice.dtIssueLocale | date:'dd/MM/yyyy' }}</span>
    </div>
  
    <div class="view-group">
      <label>Date d'échéance:</label>
      <span>{{ invoice.dtDueLocale | date:'dd/MM/yyyy' }}</span>
    </div>
  
    <div class="view-group">
      <label>Conditions de paiement:</label>
      <span>{{ invoice.codePaymentTerms }}</span>
    </div>
  
    <div class="view-group">
      <label>Référence interne:</label>
      <span>{{ invoice.internalRef || 'N/A' }}</span>
    </div>
  
    <div class="view-group">
      <label>Référence externe:</label>
      <span>{{ invoice.externalRef || 'N/A' }}</span>
    </div>
    
    <div class="view-group">
      <label>Statut:</label>
      <span>{{ invoice.codeStatus }}</span>
    </div>
  
    <div class="view-group">
      <label>Total:</label>
      <span>{{ invoice.total | currency:'EUR':'symbol':'1.2-2' }}</span>
    </div>
  </div>