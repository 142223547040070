import { Injectable } from '@angular/core';
import { InvoiceItemVat } from './invoice-item-vat.class';
import { ApiService } from '../../api/api.service';
import { catchError, map, Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { IInvoiceItemVatDto } from './invoice-item-vat.dto';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class InvoiceItemVatService {

  constructor(private apiService: ApiService) {}

  list(
    itemId: number,
    options: IInvoiceItemVatRequestOptions = {}
  ): Observable<InvoiceItemVat[]> {
    const url: string = `${environment.api.url}${environment.api.endpoint.invoiceItemVatList}/${itemId}`;
    let params = new HttpParams();
    
    console.log('URL for invoice item VATs:', url);
    console.log('Parameters for request:', params);

    return this.apiService.get<IInvoiceItemVatDto[]>(url, { params }).pipe(
      map(dtos => {
        console.log('Received data from API:', dtos);
        return dtos.map(dto => {
          const vat = new InvoiceItemVat(dto);
          console.log('Converted DTO to InvoiceItemVat:', vat);
          return vat;
        });
      }),
      catchError((error) => {
        console.error('Error fetching invoice item VAT list:', error);
        return throwError(error);
      })
    );
  }


  create(
    vat: InvoiceItemVat
  ): Observable<InvoiceItemVat> {
    const url: string = `${environment.api.url}${environment.api.endpoint.invoiceItemVatCreate}/${vat.idItem}`;
    const dto = vat.getDto();

    console.log('Creating invoice item VAT with URL:', url);
    console.log('DTO to send:', dto);

    return this.apiService.post<IInvoiceItemVatDto>(url, dto).pipe(
      map(returnedDto => {
        console.log('DTO received from API:', returnedDto);
        const createdVat = new InvoiceItemVat(returnedDto);
        console.log('Created InvoiceItemVat:', createdVat);
        return createdVat;
      }),
      catchError((error) => {
        console.error('Error creating invoice item VAT:', error);
        return throwError(error);
      })
    );
  }

  update(
    vatId: number,
    vat: InvoiceItemVat
  ): Observable<InvoiceItemVat> {
    const url: string = `${environment.api.url}${environment.api.endpoint.invoiceItemVatUpdate}/${vatId}`;
    const dto = vat.getDto();

    console.log('Updating invoice item VAT with URL:', url);
    console.log('DTO to send:', dto);

    return this.apiService.patch<IInvoiceItemVatDto>(url, dto).pipe(
      map(returnedDto => {
        console.log('DTO received from API:', returnedDto);
        const updatedVat = new InvoiceItemVat(returnedDto);
        console.log('Updated InvoiceItemVat:', updatedVat);
        return updatedVat;
      }),
      catchError((error) => {
        console.error('Error updating invoice item VAT:', error);
        return throwError(error);
      })
    );
  }

  delete(
    vatId: number
  ): Observable<void> {
    const url: string = `${environment.api.url}${environment.api.endpoint.invoiceItemVatDelete}/${vatId}`;

    console.log('Deleting invoice item VAT with URL:', url);

    return this.apiService.delete<void>(url).pipe(
      map(() => {
        console.log('Invoice item VAT successfully deleted');
        return;
      }),
      catchError((error) => {
        console.error('Error deleting invoice item VAT:', error);
        return throwError(error);
      })
    );
  }  
}


export interface IInvoiceItemVatRequestOptions {
  // Ajoutez des options si nécessaire, par exemple :
  // loadDetails?: boolean;
}
