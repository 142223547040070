import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Accommodation } from '../../../accommodation/accommodation.class';
import { IAccommodationDto } from '../../../accommodation/accommodation.dto';
import { AddressService } from '../../address.service';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { Address } from '../../address.class';
import { IAddressDto } from '../../address.dto';

@Component({
  selector: 'plugin-address-instanciate',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
  ],
  templateUrl: './address-instanciate.plugin.html',
  styleUrl: './address-instanciate.plugin.scss'
})
export class AddressInstanciatePlugin {
  private address: Address = new Address();
  @Input() set addressDto(value: IAddressDto) {
    this.address = new Address(value);
    this.loadData();
  }
  get addressDto(): IAddressDto {
    return this.address.getDto();
  }

  @Output() onUpdated: EventEmitter<IAddressDto> = new EventEmitter();

  dataLoaded: boolean = false;

  // formulaire
  addressForm: FormGroup;
  streetTypeListDefault: string[] = ['RUE', 'RTE', 'BD', 'AV', 'ALL', 'PL', 'SQ', 'CAR', 'CHE', 'TRV', 'RLE', 'SENT', 'LD', 'ZA', 'ZAC', 'ZAD', 'ZI', 'CCAL', 'RES', 'CITE', 'COURS', 'RPT', 'CTRE', 'PAS', 'PARC', 'QUAI'];
  countryList: string[] = [];
  cityList: string[] = [];
  postalCodeList: string[] = [];
  deliveryServiceList: string[] = [];
  streetNameList: string[] = [];
  streetNumberList: string[] = [];
  streetTypeList: string[] = this.streetTypeListDefault;
  buildingLocationList: string[] = [];
  deliveryPointList: string[] = [];

  constructor(
    private fb: FormBuilder,
    private addressService: AddressService,
  ) {
    this.addressForm = this.fb.group({
      country: [''],
      city: [''],
      postalCode: [''],
      deliveryService: [''],
      streetNumber: [''],
      streetName: [''],
      streetType: [''],
      buildingLocation: [''],
      deliveryPoint: [''],
    });
  }

  ngOnInit(): void {
    // chargement liste des pays
    this.addressService.getStoredCountryList().subscribe(countries => {
      this.countryList = countries || [];
    });

    // trigger country
    // --> city
    // --> postalCode
    this.addressForm.get('country')?.valueChanges.subscribe(country => {
      if (!this.address) this.address = new Address();
      this.address.country = country;
      const city = this.addressForm.get('city')?.value || '';
      const postalCode = this.addressForm.get('postalCode')?.value || '';
      this.addressService.getStoredCityList(country, postalCode).subscribe(cities => {
        this.cityList = cities || [];
      });
      this.addressService.getStoredPostalCodeList(country, city).subscribe(postalCodeList => {
        this.postalCodeList = postalCodeList || [];
      });
    });

    // trigger city
    // --> deliveryService
    // --> postalCode
    // --> streetName
    this.addressForm.get('city')?.valueChanges.subscribe(city => {
      if (!this.address) this.address = new Address();
      this.address.city = city;
      const country = this.addressForm.get('country')?.value || '';
      const postalCode = this.addressForm.get('postalCode')?.value || '';
      const streetType = this.addressForm.get('streetType')?.value || '';
      this.addressService.getStoredDeliveryServiceList(country, city, postalCode).subscribe(deliveryServiceList => {
        this.deliveryServiceList = deliveryServiceList || [];
        //this.addressForm.get('postalCode')?.reset();
      });
      this.addressService.getStoredPostalCodeList(country, city).subscribe(postalCodeList => {
        this.postalCodeList = postalCodeList || [];
        //this.addressForm.get('postalCode')?.reset();
      });
      this.addressService.getStoredStreetNameList(country, city, postalCode, streetType).subscribe(streetNameList => {
        this.streetNameList = streetNameList || [];
        //this.addressForm.get('postalCode')?.reset();
      });
    });

    // trigger postalCode
    // --> deliveryService
    // --> streetName
    this.addressForm.get('postalCode')?.valueChanges.subscribe(postalCode => {
      if (!this.address) this.address = new Address();
      this.address.postalCode = postalCode;
      const country = this.addressForm.get('country')?.value || '';
      const city = this.addressForm.get('city')?.value || '';
      const streetType = this.addressForm.get('streetType')?.value || '';
      this.addressService.getStoredDeliveryServiceList(country, city, postalCode).subscribe(deliveryServiceList => {
        this.deliveryServiceList = deliveryServiceList || [];
        //this.addressForm.get('deliveryService')?.reset();
      });
      this.addressService.getStoredStreetNameList(country, city, postalCode, streetType).subscribe(streetNameList => {
        this.streetNameList = streetNameList || [];
        //this.addressForm.get('postalCode')?.reset();
      });
    })

    // trigger streetName
    // --> streetNumber
    // --> buildingLocation
    // --> deliveryPoint
    this.addressForm.get('streetName')?.valueChanges.subscribe(streetName => {
      const country = this.addressForm.get('country')?.value || '';
      const city = this.addressForm.get('city')?.value || '';
      const postalCode = this.addressForm.get('postalCode')?.value || '';
      const deliveryService = this.addressForm.get('deliveryService')?.value || '';
      const streetType = this.addressForm.get('streetType')?.value || '';
      const streetNumber = this.addressForm.get('streetNumber')?.value || '';
      const buildingLocation = this.addressForm.get('buildingLocation')?.value || '';
      this.addressService.getStoredStreetNumberList(country, city, postalCode, streetName, streetType).subscribe(streetNumberList => {
        this.streetNumberList = streetNumberList || [];
        //this.addressForm.get('streetType')?.reset();
      });
      this.addressService.getStoredBuildingLocationList(country, city, postalCode, streetName, streetType, streetNumber).subscribe(buildingLocationList => {
        this.buildingLocationList = buildingLocationList || [];
        //this.addressForm.get('streetType')?.reset();
      });
      this.addressService.getStoredDeliveryPointList(country, city, postalCode, deliveryService, streetName, streetType, streetNumber, buildingLocation).subscribe(deliveryPointList => {
        this.deliveryPointList = deliveryPointList || [];
        //this.addressForm.get('streetType')?.reset();
      });
    })

    // trigger streetType
    // --> streetNumber
    // --> buildingLocation
    // --> deliveryPoint
    this.addressForm.get('streetType')?.valueChanges.subscribe(streetType => {
      const country = this.addressForm.get('country')?.value || '';
      const city = this.addressForm.get('city')?.value || '';
      const postalCode = this.addressForm.get('postalCode')?.value || '';
      const deliveryService = this.addressForm.get('deliveryService')?.value || '';
      const streetNumber = this.addressForm.get('streetNumber')?.value || '';
      const streetName = this.addressForm.get('streetName')?.value || '';
      const buildingLocation = this.addressForm.get('buildingLocation')?.value || '';
      this.addressService.getStoredStreetNumberList(country, city, postalCode, streetName, streetType).subscribe(streetNumberList => {
        this.streetNumberList = streetNumberList || [];
        //this.addressForm.get('streetType')?.reset();
      });
      this.addressService.getStoredStreetNameList(country, city, postalCode, deliveryService).subscribe(streetNameList => {
        this.streetNameList = streetNameList || [];
        //this.addressForm.get('streetName')?.reset();
      });
      this.addressService.getStoredBuildingLocationList(country, city, postalCode, streetName, streetType, streetNumber).subscribe(buildingLocationList => {
        this.buildingLocationList = buildingLocationList || [];
        //this.addressForm.get('streetType')?.reset();
      });
      this.addressService.getStoredDeliveryPointList(country, city, postalCode, deliveryService, streetName, streetType, streetNumber, buildingLocation).subscribe(deliveryPointList => {
        this.deliveryPointList = deliveryPointList || [];
        //this.addressForm.get('streetType')?.reset();
      });
    })
  }

  onReinitialize() {
    if (this.address) {
      this.addressForm.get('country')?.setValue(this.address.country ?? '');
      this.addressForm.get('city')?.setValue(this.address.city ?? '');
      this.addressForm.get('postalCode')?.setValue(this.address.postalCode ?? '');
      this.addressForm.get('deliveryService')?.setValue(this.address.deliveryService ?? '');
      this.addressForm.get('streetNumber')?.setValue(this.address.streetNumber ?? '');
      this.addressForm.get('streetName')?.setValue(this.address.streetName ?? '');
      this.addressForm.get('streetType')?.setValue(this.address.streetType ?? '');
      this.addressForm.get('buildingLocation')?.setValue(this.address.buildingLocation ?? '');
      this.addressForm.get('deliveryPoint')?.setValue(this.address.deliveryPoint ?? '');
    }
  }
  

  loadData() {
    console.log(this.address);
    if (!this.dataLoaded) {
      this.onReinitialize();
      this.dataLoaded = true;
    }
  }

  onUpdate() {
    if (this.address instanceof Address) {
      Object.assign(this.address, this.addressForm.value);
      this.onUpdated.emit(this.address.getDto());
    } else {
      console.error('L\'objet address n\'est pas une instance de Address');
    }
  }

}
