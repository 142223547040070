import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserRolePickerPlugin } from '../../role/plugin/picker/user-role-picker.plugin';
import { AuthService } from '../../../auth/auth.service';
import { IUserRoleRequestOptions } from '../../role/userrole.request';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    UserRolePickerPlugin,
  ],
  templateUrl: './profile.route.html',
  styleUrls: ['./profile.route.scss']
})
export class ProfileRoute implements OnInit {
  username$: Observable<string> | undefined;
  urOptions: IUserRoleRequestOptions = {}

  constructor(
    private authService: AuthService,
  ) {}

  ngOnInit(): void {
    // Filtrer les valeurs nulles avant d'assigner à username$
    this.username$ = this.authService.username$.pipe(
      filter((username): username is string => username !== null)
    );

    this.username$.subscribe(username => {
      if (username) {
        this.urOptions = {
          username: username,
          assigned: true,
        };
      }
    });
  }
}
