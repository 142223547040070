<div class="email-picker">
    <!-- Message s'il n'y a pas d'emails -->
    <p *ngIf="list.length === 0">Aucun email disponible.</p>
  
    <!-- Liste des emails si disponible -->
    <ul *ngIf="list.length > 0">
      <li *ngFor="let email of list" class="email-item">
        <div class="email-details">
          <strong>{{ email.emailType }}</strong>: {{ email.emailAddress }}
        </div>
        <div class="email-favorite" *ngIf="email.isFavorite">
          <span class="favorite-label">Favori</span>
        </div>
        <div class="email-actions">
          <button class="btn-delete" (click)="deleteEmail(email)">Supprimer</button>
        </div>
      </li>
    </ul>
  </div>
  