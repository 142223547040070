export const environment = {
    production: false,
    api: {
        url: 'https://alfredapi1.int.do-good.fr',
        endpoint: {
            login: '/auth/login',

            accommodationGet: '/accommodation',
            accommodationList: '/accommodation/list',
            accommodationCreate: '/accommodation/create',
            accommodationSave: '/accommodation/save',
            accommodationUpdate: '/accommodation/update',

            addressGet: '/address',
            addressList: '/address/list',
            addressInstanciate: '/address/instanciate',
            addressStoredCountryList: '/address/stored/country/list',
            addressStoredCityList: '/address/stored/city/list',
            addressStoredPostalCodeList: '/address/stored/postalcode/list',
            addressStoredDeliveryServiceList: '/address/stored/deliveryservice/list',
            addressStoredStreetNameList: '/address/stored/streetname/list',
            addressStoredStreetNumberList: '/address/stored/streetnumber/list',
            addressStoredStreetTypeList: '/address/stored/streettype/list',
            addressStoredBuildingLocationList: '/address/stored/buildinglocation/list',
            addressStoredDeliveryPointList: '/address/stored/deliverypoint/list',

            contactEmailCreate: '/email/create',
            contactEmailDelete: '/email',
            contactEmailGet: '/email',
            contactEmailList: '/email/list',
            contactPhoneCreate: '/phone/create',
            contactPhoneDelete: '/phone',
            contactPhoneGet: '/phone',
            contactPhoneList: '/phone/list',

            leaseGet:               '/lease/l',
            leaseList:              '/lease/list',
            leaseSave:              '/lease/save',

            leaseNextStatusList: '/lease/status/next/list',
            leaseStatusList: '/lease/status/list',
            leaseUpdateStatus: '/lease/status/update',

            occupancyAdd: '/occupancy/add/address/p',
            occupancyList: "/occupancy/list",
            occupancyGet: '/occupancy',
            occupiedAddressUpdate: '/person/occupancy/update', 

            operationGet: "/operation",
            operationList: "/operation/list",
            operationSave: "/operation/save",

            personTitleList: "/person-title/list",
            personList: "/person/list",
            personGet: "/person",
            personCreate: "/person/create",
            personUpdate: "/person/update",

            rentDev: '/rent/dev',
            rentDelete: '/rent',
            rentToIssue: '/rent/to/issue',
            rentSave: '/rent/save',
            rentStatus: '/rent/status',

            rentStatusNext: '/rent/status/next',
            rentStatusList: '/rent/status/list',

            roleList: '/role/list',

            userAssignRole: '/user',
            userCreate: '/user/create',
            userGet: '/user/u',
            userList: '/user/list',
            userPassword: '/user',
            userRemoveRole: '/user',
            userRoleCodes: '/user',
            userSave: '/user/save',
            userUpdate: '/user',

        }
    }
};
  