import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Contact } from '../../contact.class';
import { ContactService, IContactRequestOptions } from '../../contact.service';

@Component({
  selector: 'plugin-contact-select',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
  ],
  templateUrl: './contact-select.plugin.html',
  styleUrl: './contact-select.plugin.scss'
})
export class ContactSelectPlugin implements OnInit {
  private _selectedContactId: number | undefined;

  @Input() buTagLabel: string | undefined; // Nouvel input pour le tag BU label

  // si le tag BU label n'est pas renseigné, le plugin doit afficher un message indiquant qu'il manque

  @Input() 
  set selectedContactId(value: number | undefined) {
    this._selectedContactId = value;
    this.selectedContact = value; // Mettre à jour la sélection immédiatement
    console.log('Setter updated selectedContact to:', value);
  }
  get selectedContactId(): number | undefined {
    return this._selectedContactId;
  }
  
  @Output() selectedContactIdChange = new EventEmitter<number | undefined>();

  contacts: Contact[] = [];
  selectedContact: number | undefined;
  isBuTagMissing: boolean = false;
  
  constructor(private contactService: ContactService) {}

  ngOnInit(): void {
    this.loadContacts();
    console.log('Initial selectedContactId:', this.selectedContactId);
  }

  private loadContacts(): void {
    const options: IContactRequestOptions = { 
      loadTitle: true,
      selectTags: this.buTagLabel ? [this.buTagLabel] : undefined // Ajoute le tag BU dans les options
    };
    this.contactService.list(options).subscribe(
      (contacts) => {
        this.contacts = contacts;
        console.log('Contacts loaded with BU tag:', this.buTagLabel, 'Contacts:', this.contacts);
        if (this.selectedContactId && !this.contacts.some(c => c.id === this.selectedContactId)) {
          console.warn('Selected contact ID not found in list:', this.selectedContactId);
        }
      },
      (error) => console.error('Error loading contacts:', error)
    );
  }

  onContactChange(): void {
    this.selectedContactIdChange.emit(this.selectedContact);
    console.log('Selected contact changed to:', this.selectedContact);
  }
}
