/**
 * Rent est une classe qui modélise un loyer, somme à percevoir d'un locataire dans le cadre d'un bail
 */

import { Contact } from "../../contact/contact.class";
import { Operation } from "../../operation/operation.class";
import { IOperationDto } from "../../operation/operation.dto";
import { Lease } from "../../rental/lease/lease.class";
import { LocaleDate } from "../../time/locale";
import { IRentDetailsDto } from "./rent.dto";

export class Rent extends Operation {
    // mois de référence
    refYear?: number;
    refMonth?: number;
    refRentAmount?: number;
    refFeesAmount?: number;

    // dates
    startLocale?: LocaleDate;
    endLocale?: LocaleDate;

    // montants
    rentAmount?: number;
    feesAmount?: number;

    // données lease
    lease?: Lease;

    constructor(dto?: IOperationDto) {
        super();   
        this.startLocale = new LocaleDate('');
        if (dto) {
            this.loadDto(dto);
        }
    }

    get receiptFilename(): string {
        const typeDoc: string = 'Quittance';
        
        let tenantName: string = '';
        if (this.lease?.tenants && this.lease?.tenants.length > 0) {
            const contact: Contact = this.lease?.tenants[0];
            tenantName = contact.lastName;
        }
        
        // Création du nom de fichier de base
        const fn: string = `${typeDoc} ${this.name} ${tenantName} ${this.lease?.accommodation?.name}.pdf`;
    
        // Nettoyage des caractères indésirables, y compris les apostrophes
        const cleanFilename: string = fn
            .replace(/[^a-zA-Z0-9\s-_]/g, '')   // Supprime tous les caractères non désirés
            .replace(/'/g, '')                  // Supprime explicitement les apostrophes
            .replace(/\s+/g, ' ')               // Remplace les espaces multiples par un seul espace
            .trim();                            // Enlève les espaces en début et fin de chaîne
        
        return cleanFilename;
    }

    // to do : loadDto
    override loadDto(dto: IOperationDto) {
        super.loadDto(dto);
        if (dto && 'details' in dto && dto.details) {
            const details = dto.details as IRentDetailsDto;
            this.loadDetailsDto(details);
        }
    }

    private loadDetailsDto(details: IRentDetailsDto) {
        console.log('details dto', details);

        this.refYear = details.year;
        this.refMonth = details.month;

        this.startLocale = new LocaleDate(details.localeStart);
        this.endLocale = details.localeEnd ? new LocaleDate(details.localeEnd) : undefined;

        this.rentAmount = details.rentAmount;
        this.feesAmount = details.feesAmount;

        this.lease = new Lease(details.lease);
    }

    public override getDto(): IOperationDto {
        const details: IRentDetailsDto = {
            localeStart: this.startLocale!.getDto(),
            localeEnd: this.endLocale ? this.endLocale.getDto() : undefined,
            month: this.refMonth,
            year: this.refYear,
            rentAmount: this.rentAmount,
            feesAmount: this.feesAmount,
            negociatedFees: this.refFeesAmount,
            negociatedRent: this.refRentAmount,
        }
        return super.getDto(details);
    }
}