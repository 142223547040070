import { Component, OnInit } from '@angular/core';
import { INavItem } from '../nav.item';
import { NavService } from '../nav.service';
import { AuthService } from '../../auth/auth.service';
import { map, Observable } from 'rxjs';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'widget-topics',
  standalone: true,
  imports: [
    CommonModule,
  ],
  templateUrl: './topics.widget.html',
  styleUrl: './topics.widget.scss'
})
export class TopicsWidget implements OnInit {
  topics$: Observable<INavItem[]> | undefined;
  isLoggedIn$: Observable<boolean>;

  constructor(
    private navService: NavService, 
    private authService: AuthService,
    private router: Router,
  ) {
    this.isLoggedIn$ = this.authService.isLoggedIn$;
  }

  ngOnInit(): void {
    // Filtrer les topics selon les rôles et vérifier si l'utilisateur est connecté
    this.isLoggedIn$.subscribe(isLoggedIn => {
      if (isLoggedIn) {
        this.authService.roles$.subscribe(roles => {
          const filteredTopics = this.navService.getFilteredTopics(roles);
          this.topics$ = new Observable((observer) => {
            observer.next(filteredTopics);
          });
        });
      }
    });
  }

  onTopicSelect(topic: INavItem) {
    this.navService.selectTopic(topic.id);
    this.router.navigate([topic.route]); // Naviguer vers la route sélectionnée
  }
}
