import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Lease } from '../../lease.class';
import { ILeaseDto } from '../../lease.dto';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'plugin-lease-data-edit',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
  ],
  templateUrl: './lease-data-edit.plugin.html',
  styleUrl: './lease-data-edit.plugin.scss'
})
export class LeaseDataEditPlugin {
  public lease: Lease = new Lease();
  @Input()
  set leaseDto(value: ILeaseDto) {
    this.lease = new Lease(value);
    this.loadData();
  }

  @Output() onSave: EventEmitter<ILeaseDto> = new EventEmitter();
  @Output() onCancel: EventEmitter<void> = new EventEmitter();

  constructor() {}

  loadData() {}
  
  saveLease() {
    const leaseDto: ILeaseDto = this.lease.getDto();
    this.onSave.emit(leaseDto);
  }

  cancelEdit() {
    console.log('Editing canceled');
    this.onCancel.emit();
  }

  
}
