import { Component, OnInit } from '@angular/core';
import { Lease } from '../../../../rental/lease/lease.class';
import { LeaseService } from '../../../../rental/lease/lease.service';
import { CommonModule } from '@angular/common';
import { OccupancySelectorPlugin } from '../../../../rental/lease/plugin/occupancy-selector/occupancy-selector.plugin';

@Component({
  selector: 'dev-issuer-occupancy-selector-cop-route',
  standalone: true,
  imports: [
    CommonModule,
    OccupancySelectorPlugin,
  ],
  templateUrl: './dev-issuer-occupancy-selector-cop.route.html',
  styleUrl: './dev-issuer-occupancy-selector-cop.route.scss'
})

/**
 * Test du selecteur de IssuerOccupancy
 * 
 */
export class DevIssuerOccupancySelectorCOPRoute implements OnInit {
  lease: Lease = new Lease();

  selectedId: number = 0;

  constructor(
    private leaseService: LeaseService,
  ) {}

  ngOnInit(): void {
    this.leaseService.get(9, {
      loadLessors: true,
    }).subscribe(l => {
      if (l) {
        this.lease = l;
      }
    })
  }

  loadData() {

  }

  ShowSelection(occupancyId: number) {
    this.selectedId = occupancyId;
  }
}
