<div class="phone-picker">
    <h2>Liste des numéros de téléphone</h2>
  
    <div *ngIf="list.length > 0; else noPhones" class="phone-list">
      <div class="phone-item" *ngFor="let phone of list">
        <div class="phone-type">{{ phone.phoneType }}</div>
        <div class="phone-number">{{ phone.phoneNumber }}</div>
        <div class="phone-favorite" *ngIf="phone.isFavorite">⭐</div>
        <div class="phone-actions">
          <button class="btn-delete" (click)="deletePhone(phone)">Supprimer</button>
        </div>
      </div>
    </div>
  
    <ng-template #noPhones>
      <div class="no-phones">
        <p>Aucun numéro de téléphone disponible.</p>
      </div>
    </ng-template>
  </div>
  