import { Component, Input } from '@angular/core';
import { Occupancy } from '../../occupancy.class';
import { IOccupancyRequestOptions, OccupancyService } from '../../occupancy.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'plugin-occupancy-picker',
  standalone: true,
  imports: [
    CommonModule,
  ],
  templateUrl: './occupancy-picker.plugin.html',
  styleUrl: './occupancy-picker.plugin.scss'
})
export class OccupancyPickerPlugin {
  list: Occupancy[] = [];
  _options: IOccupancyRequestOptions = {};
  private dataLoaded: boolean = false;

  @Input()
  set options(value: IOccupancyRequestOptions) {
    this._options = value;
    this.loadData();
  }
  get options(): IOccupancyRequestOptions {
    return this._options;
  }
  constructor(
    private occupancyService: OccupancyService,
  ) {}

  loadData() {
    if (!this.dataLoaded && this.options && this.options.personIds && this.options.personIds.length>0) {
      this.occupancyService.list(this.options).subscribe(l => {
        if (l) {
          this.list = l;
          this.dataLoaded = true;
        }
      })
    }
  }

}
