<div *ngIf="isBuTagMissing" class="missing-bu-message">
  <span>Le tag BU est requis pour charger les contacts.</span>
</div>
<div *ngIf="!isBuTagMissing">
  <select 
    [(ngModel)]="selectedContact" 
    (ngModelChange)="onContactChange()" 
    name="contactSelect">
    <option value="" disabled selected>Choisir un contact</option>
    <option *ngFor="let contact of contacts" [value]="contact.id">
      {{ contact.company || contact.formattedName() || 'Sans nom' }}
    </option>
  </select>
</div>