import { Component } from '@angular/core';

@Component({
  selector: 'route-wealth-home',
  standalone: true,
  imports: [],
  templateUrl: './wealth-home.route.html',
  styleUrl: './wealth-home.route.scss'
})
export class WealthHomeRoute {

}
