import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AddressService, IAddressRequestOptions } from '../../address.service';
import { IAddressDto } from '../../address.dto';
import { Address } from '../../address.class';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'plugin-address-picker',
  standalone: true,
  imports: [ 
    CommonModule,
  ],
  templateUrl: './address-picker.plugin.html',
  styleUrl: './address-picker.plugin.scss'
})
export class AddressPickerPlugin {
  private _options: IAddressRequestOptions = {};
  list: Address[] = [];
  selectedAddress: Address = new Address();

  @Input()
  set options(value: IAddressRequestOptions) {
    this._options = value;
    this.loadData();
  }
  get options(): IAddressRequestOptions {
    return this._options;
  }

  @Output() onSelect = new EventEmitter<IAddressDto>();
  @Output() onActivate = new EventEmitter<IAddressDto>();
  @Output() onView = new EventEmitter<IAddressDto>();
  
  constructor(
    private addressService: AddressService,
  ) {}

  loadData() {
    this.addressService.list(this.options).subscribe(l => {
      if (l) {
        this.list = l;
      }
    })
  }

  select(address: Address) {
    this.selectedAddress = address;
    this.onSelect.emit(this.selectedAddress.getDto());
  }

  activate(address: Address) {
    this.selectedAddress = address;
    this.onActivate.emit(this.selectedAddress.getDto());
  }

  view(address: Address) {
    this.selectedAddress = address;
    this.onView.emit(this.selectedAddress.getDto());
  }
}
