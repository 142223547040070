<div class="vat-edit">
  <h5>{{ vatDto ? 'Modifier TVA' : 'Ajouter TVA' }}</h5>
  <form (ngSubmit)="saveVat()">
    <div class="form-group">
      <label for="codeVAT">Code TVA:</label>
      <input type="number" id="codeVAT" [(ngModel)]="vat.codeVAT" name="codeVAT" />
    </div>
    <div class="form-group">
      <label for="rateVAT">Taux TVA (%):</label>
      <input type="number" id="rateVAT" [(ngModel)]="vat.rateVAT" name="rateVAT" step="0.01" required (ngModelChange)="updateAmountFromRate()" />
    </div>
    <div class="form-group">
      <label for="amountExcludingVAT">Montant HT:</label>
      <input type="number" id="amountExcludingVAT" [(ngModel)]="vat.amountExcludingVAT" name="amountExcludingVAT" step="0.01" required (ngModelChange)="updateAmount()" />
    </div>
    <div class="form-group">
      <label for="amountVAT">Montant TVA:</label>
      <input type="number" id="amountVAT" [(ngModel)]="vat.amountVAT" name="amountVAT" step="0.01" required />
    </div>
    <div class="form-group">
      <label for="amount">Total:</label>
      <input type="number" id="amount" [(ngModel)]="vat.amount" name="amount" step="0.01" required (ngModelChange)="updateAmountExcludingVAT()" />
    </div>
    <div class="action-buttons">
      <button type="submit" class="btn-save">Sauvegarder</button>
      <button type="button" class="btn-cancel" (click)="cancel()">Annuler</button>
    </div>
  </form>
</div>