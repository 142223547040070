import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Address } from '../../address.class';
import { IAddressDto } from '../../address.dto';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'plugin-address-edit',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule
  ],
  templateUrl: './address-edit.plugin.html',
  styleUrl: './address-edit.plugin.scss'
})
export class AddressEditPlugin {
  private _address: Address = new Address();
  private dataLoaded: boolean = false;

  @Input()
  set addressDto(value: IAddressDto) {
    this._address = new Address(value);
    this.loadData();
  }
  get addressDto(): IAddressDto {
    return this._address.getDto();
  }

  @Output() onSave = new EventEmitter<IAddressDto>();
  @Output() onCancel = new EventEmitter<void>();

  addressForm: FormGroup;

  constructor(
    private fb: FormBuilder
  ) {
    this.addressForm = this.fb.group({
      deliveryPoint: [''],
      buildingLocation: [''],
      streetNumber: ['', Validators.required],
      streetType: [''],
      streetName: ['', Validators.required],
      deliveryService: [''],
      postalCode: ['', Validators.required],
      city: ['', Validators.required],
      country: ['', Validators.required],
      timezone: [''],
    });
  }

  ngOnInit(): void {
    this.loadData();
  }

  loadData(): void {
    if (this._address && !this.dataLoaded) {
      this.addressForm.patchValue({
        deliveryPoint: this._address.deliveryPoint,
        buildingLocation: this._address.buildingLocation,
        streetNumber: this._address.streetNumber,
        streetType: this._address.streetType,
        streetName: this._address.streetName,
        deliveryService: this._address.deliveryService,
        postalCode: this._address.postalCode,
        city: this._address.city,
        country: this._address.country,
        timezone: this._address.timezone,
      });
      this.dataLoaded = true;
    }
  }

  save(): void {
    if (this.addressForm.valid) {
      this._address.deliveryPoint = this.addressForm.get('deliveryPoint')?.value;
      this._address.buildingLocation = this.addressForm.get('buildingLocation')?.value;
      this._address.streetNumber = this.addressForm.get('streetNumber')?.value;
      this._address.streetType = this.addressForm.get('streetType')?.value;
      this._address.streetName = this.addressForm.get('streetName')?.value;
      this._address.deliveryService = this.addressForm.get('deliveryService')?.value;
      this._address.postalCode = this.addressForm.get('postalCode')?.value;
      this._address.city = this.addressForm.get('city')?.value;
      this._address.country = this.addressForm.get('country')?.value;
      this._address.timezone = this.addressForm.get('timezone')?.value;

      this.onSave.emit(this._address.getDto());
    }
  }

  cancel(): void {
    this.onCancel.emit();
  }
}
