<!-- <p>contact-picker works!</p> -->
<!-- contact-picker.plugin.html -->
<div class="contact-picker">
    <h2>Liste des Contacts</h2>
    <table *ngIf="list.length > 0" class="contact-table">
      <thead>
        <tr>
          <th>ID</th>
          <th>Nom</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let contact of list" 
            (click)="selectContact(contact)" 
            (dblclick)="activateContact(contact)"
            [class.selected]="isSelected(contact)">
          <td>{{ contact.id }}</td>
          <td>{{ contact.formattedName() }}</td>
        </tr>
      </tbody>
    </table>
    <div *ngIf="list.length === 0">
      Aucun contact disponible.
    </div>
  </div>
  