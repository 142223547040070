import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { RentViewPlugin } from '../../plugin/view/rent-view.plugin';
import { ActivatedRoute, Router } from '@angular/router';
import { RentService } from '../../rent.service';
import { Rent } from '../../rent.class';

@Component({
  selector: 'route-rent-confirm-delete',
  standalone: true,
  imports: [
    CommonModule,
    RentViewPlugin,
  ],
  templateUrl: './rent-confirm-delete.route.html',
  styleUrl: './rent-confirm-delete.route.scss'
})
export class RentConfirmDeleteRoute implements OnInit {
  loaded: boolean = false;
  rent: Rent = new Rent();

  constructor(
    private route: ActivatedRoute,
    private rentService: RentService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    if (!this.loaded) {
      this.route.paramMap.subscribe(params => {
        const id = +(params.get('idoperation') || 0);
        this.loadRentOperation(id);
      });
    }
  }

  loadRentOperation(idOperation: number) {
    if (!this.loaded) {
      this.rentService.get(idOperation).subscribe(r => {
        if (r) {
          this.rent = r;
          this.loaded = true;
        } else {
          console.warn('No rent data found for id:', idOperation);  // Si aucune donnée n'est trouvée
        }
      }, error => {
        console.error('Error fetching rent data:', error);  // Gestion des erreurs avec console.error
      });
  
    }
  }

  confirmDelete() {
    if (this.rent.idOperation) {
      console.log('Confirming deletion of rent operation with ID:', this.rent.idOperation);
      this.rentService.delete(this.rent.idOperation).subscribe({
        next: () => {
          console.log('Rent operation successfully deleted');
          this.router.navigate(['/management/dashboard']);
        },
        error: () => {
          console.error('Failed to delete rent operation');
        }
      });
    } else {
      console.warn('Operation ID is not available for deletion');
    }
  }
  
}
