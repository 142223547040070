import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { InvoiceItem } from '../../invoice-item.class';
import { IInvoiceItemDto } from '../../invoice-item.dto';
import { FormsModule } from '@angular/forms';
import { InvoiceItemService } from '../../invoice-item.service';
import { IInvoiceItemVatDto } from '../../../vat/invoice-item-vat.dto';
import { InvoiceItemVat } from '../../../vat/invoice-item-vat.class';
import { InvoiceItemVatPickerPlugin } from '../../../vat/plugin/picker/invoice-item-vat-picker.plugin';

@Component({
  selector: 'plugin-invoice-item-edit',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    InvoiceItemVatPickerPlugin,
  ],
  templateUrl: './invoice-item-edit.plugin.html',
  styleUrl: './invoice-item-edit.plugin.scss'
})
export class InvoiceItemEditPlugin implements OnChanges  {
  @Input() itemDto: IInvoiceItemDto | undefined; 
  @Output() saved = new EventEmitter<IInvoiceItemDto>();   
  @Output() deleted = new EventEmitter<number>(); 
  @Output() totalsUpdated = new EventEmitter<IInvoiceItemDto>();

  item: InvoiceItem = new InvoiceItem();
  isNewItem: boolean = true;
  showVatEdit: boolean = false;

  constructor(
    private invoiceItemService: InvoiceItemService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['itemDto']) {
      const newItemDto = changes['itemDto'].currentValue;
      this.item = new InvoiceItem(newItemDto || {});
      this.isNewItem = !this.item.id;
      if (!this.item.vats) {
        this.item.vats = [];
      }
      console.log('Item updated with new itemDto:', this.item);
    }
  }

  saveItem(): void {
    this.updateTotals();
    console.log('Saving item with recalculated totals:', this.item);
    if (this.isNewItem) {
      this.invoiceItemService.create(this.item).subscribe(
        (createdItem: InvoiceItem) => {
          console.log('Item created:', createdItem);
          this.saved.emit(createdItem.getDto());
          this.item = createdItem;
          this.isNewItem = false;
          this.totalsUpdated.emit(createdItem.getDto()); // Émettre totalsUpdated après création
        },
        error => console.error('Error creating item:', error)
      );
    } else if (this.item.id) {
      this.invoiceItemService.update(this.item.id, this.item).subscribe(
        (updatedItem: InvoiceItem) => {
          console.log('Item updated:', updatedItem);
          this.saved.emit(updatedItem.getDto());
          this.totalsUpdated.emit(updatedItem.getDto()); // Émettre totalsUpdated après mise à jour
          this.item = updatedItem;
        },
        error => console.error('Error updating item:', error)
      );
    } else {
      console.error('Cannot save item: ID is undefined for an existing item');
    }
  }

  // Méthode pour supprimer l'élément de facture
  deleteItem(): void {
    this.deleted.emit(this.item.id);
  }

  saveVat(vatDto: IInvoiceItemVatDto): void {
    console.log("**** saveVat");
    if (!this.item.vats) this.item.vats = [];
    const newVatObj = new InvoiceItemVat(vatDto);
    this.item.vats.push(newVatObj);
    this.updateTotals();
    console.log('VAT added:', newVatObj);
    this.totalsUpdated.emit(this.item.getDto()); // Émettre totalsUpdated après ajout de VAT
    if (this.item.id) {
      // Si l'item existe déjà, sauvegarder immédiatement les modifications
      this.saveItem();
    }
  }

  removeVat(vatId: number): void {
    if (this.item.vats) {
      const index = this.item.vats.findIndex(v => v.id === vatId);
      if (index !== -1) {
        this.item.vats.splice(index, 1);
        this.updateTotals();
        console.log('VAT removed, ID:', vatId);
        this.totalsUpdated.emit(this.item.getDto()); // Émettre totalsUpdated après suppression de VAT
        if (this.item.id) {
          // Si l'item existe déjà, sauvegarder immédiatement les modifications
          this.saveItem();
        }
      }
    }
  }

  updateTotals(): void {
    if (!this.item.vats) {
      this.item.vats = [];
    }
    // Recalculer les montants en fonction des VATs
    this.item.totalExcludingVAT = this.item.vats.reduce((acc, vat) => acc + (vat.amountExcludingVAT || 0), 0);
    this.item.totalVAT = this.item.vats.reduce((acc, vat) => acc + (vat.amountVAT || 0), 0);
    this.item.total = this.item.totalExcludingVAT + this.item.totalVAT;
  
    console.log('Totals recalculated:', {
      quantity: this.item.quantity,
      totalExcludingVAT: this.item.totalExcludingVAT,
      totalVAT: this.item.totalVAT,
      total: this.item.total,
      vats: this.item.vats
    });
  }

  // Afficher le formulaire pour ajouter un nouveau VAT
  showVatForm(): void {
    this.showVatEdit = true;
  }

  // Cacher le formulaire d'ajout de VAT
  hideVatForm(): void {
    this.showVatEdit = false;
  }
}
