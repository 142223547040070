import { IOperationDto } from "./operation.dto";


export class Operation {
    idOperation: number;       // nouvelle opération si absent
    typeOperation: string;
    status?: string;
    name?: string;
    details: any;               // json => private ?
    classParent?: string;
    idParent?: number;

    constructor(dto?: IOperationDto) {
        this.idOperation = 0;
        this.typeOperation = '';
        this.status = 'NEW';
        this.name = '';
        this.classParent = '';
        this.idParent = 0;
        this.details = {};
        if (dto) {
            this.loadDto(dto);
        }
    }

    protected loadDto(dto: IOperationDto) {
        this.idOperation = dto.idOperation ?? 0;
        this.typeOperation = dto.typeOperation ?? '';
        this.classParent = dto.classParent;
        this.idParent = dto.idParent;
        this.status = dto.status;
        this.name = dto.name;
    }

    protected getDto(detailsDto: any): IOperationDto {
        const dto: IOperationDto = {
            idOperation: this.idOperation,
            typeOperation: this.typeOperation,
            classParent: this.classParent,
            idParent: this.idParent,
            name: this.name,
            status: this.status,
            details: detailsDto,
        }
        return dto;
    }
}
