import { Component, OnInit } from '@angular/core';
import { Lease } from '../../lease.class';
import { ILeaseRequestOptions } from '../../lease.service';
import { CommonModule } from '@angular/common';
import { LeasePickerPlugin } from '../../plugin/picker/lease-picker.plugin';
import { ILeaseDto } from '../../lease.dto';
import { Router } from '@angular/router';

@Component({
  selector: 'route-lease-list',
  standalone: true,
  imports: [
    CommonModule,
    LeasePickerPlugin,
  ],
  templateUrl: './lease-list.route.html',
  styleUrl: './lease-list.route.scss'
})
export class LeaseListRoute {
  options: ILeaseRequestOptions = {}

  constructor(
    private router: Router,
  ) {}

  viewLease(dto: ILeaseDto) {
    console.log(dto);
    const lease: Lease = new Lease(dto);
    this.router.navigate(['lease', lease.id]);
  }

  newLease() {
    this.router.navigate(['lease/new']);
  }
}