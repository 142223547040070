<div class="address-picker">
    <h2>Choisissez une adresse</h2>
  
    <!-- Liste des adresses -->
    <ul *ngIf="list.length > 0">
      <li *ngFor="let address of list" 
          (click)="select(address)" 
          (dblclick)="activate(address)" 
          [class.selected]="address === selectedAddress">
        
        <div class="address-details">
          <h3>{{ address.streetNumber }} {{ address.streetType }} {{ address.streetName }}, {{ address.city }}</h3>
          <p><strong>Code postal :</strong> {{ address.postalCode }}</p>
          <p><strong>Pays :</strong> {{ address.country }}</p>
        </div>
  
        <!-- Bouton Voir -->
        <button class="btn-view" (click)="view(address)">Voir</button>
      </li>
    </ul>
  
    <!-- Aucun résultat -->
    <p *ngIf="list.length === 0">Aucune adresse disponible.</p>
  </div>
  