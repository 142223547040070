import { Component, OnInit } from '@angular/core';
import { Lease } from '../../../rental/lease/lease.class';
import { ActivatedRoute } from '@angular/router';
import { LeaseService } from '../../../rental/lease/lease.service';
import { CommonModule } from '@angular/common';
import { LeaseDataViewPlugin } from '../../../rental/lease/plugin/data-view/lease-data-view.plugin';
import { RentPickerPlugin } from '../../rent/plugin/picker/rent-picker.plugin';
import { IRentRequestOptions } from '../../rent/rent.service';

@Component({
  selector: 'route-contract-edit',
  standalone: true,
  imports: [
    CommonModule,
    LeaseDataViewPlugin,
    RentPickerPlugin,
  ],
  templateUrl: './contract-edit.route.html',
  styleUrl: './contract-edit.route.scss'
})
export class ContractEditRoute implements OnInit {
  lease: Lease = new Lease();
  dataLoaded: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private leaseService: LeaseService,
  ) {}

  ngOnInit(): void {
    if (!this.dataLoaded) {
      this.route.paramMap.subscribe(params => {
        const id = +(params.get('id') || 0);
        console.log('ParamMap received:', id);  // Ajout de console.log pour déboguer
        if (id) {
          console.log('Calling loadData with id:', id);  // Ajout de console.log avant l'appel de loadData
          this.loadData(id);
        }
      });
    }
  }

  loadData(id: number) {
    if (!this.dataLoaded) {
      this.leaseService.get(id).subscribe(l => {
        if (l) {
          this.lease = l;
        } else {
          console.error('erreur chargement lease');
        }
      })
    }
  }

  get getRentPickerOptions(): IRentRequestOptions {
    const options: IRentRequestOptions = {
      inStatus: ['PAID'],
      
    };
    return options;
  }
}
