import { BaseEntity } from "../base-entity.class";
import { IContactTitleDto } from "./contact.dto";

export class ContactTitle extends BaseEntity<IContactTitleDto> {
    id?: number;
    shortTitle: string = '';
    longTitle: string = '';

    constructor(dto?: IContactTitleDto) {
        super(dto);
        if (dto) this.loadDto(dto);
    }

    protected override getMappings(): Partial<Record<keyof IContactTitleDto, keyof this>> {
        return {
            idTitle: 'id',
            titleShort: 'shortTitle',
            titleLong: 'longTitle',
        }
    }
}
