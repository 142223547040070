<div class="contact-multi">
    <h3>Sélectionner des contacts</h3>
    
    <!-- Liste des contacts -->
    <ul class="contact-list">
      <li *ngFor="let contact of list" 
          [class.selected]="isSelected(contact)" 
          (click)="toggleContactSelection(contact)">
        {{ contact.firstName }} {{ contact.lastName }}
      </li>
    </ul>
  
      <!-- Bouton pour étendre la liste -->
  <button class="btn-toggle-list" (click)="toggleFullList()">
    {{ showFullList ? 'Réduire la liste' : 'Étendre la liste' }}
  </button>
  
    <!-- Bouton de sauvegarde -->
    <button class="btn-save" (click)="saveSelection()">Sauvegarder</button>
  </div>
  