import { Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { IContactEmailDto } from '../../contact-email.dto';
import { ContactEmail } from '../../contact-email.class';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'plugin-contact-email-edit',
  standalone: true,
  imports: [
    CommonModule, 
    ReactiveFormsModule
  ],
  templateUrl: './contact-email-edit.plugin.html',
  styleUrls: ['./contact-email-edit.plugin.scss'],
})
export class ContactEmailEditPlugin implements OnInit {
  private _email: ContactEmail = new ContactEmail();

  @Input() emailDto!: IContactEmailDto;
  
  @Output() onSubmit: EventEmitter<IContactEmailDto> = new EventEmitter();
  @Output() onCancel: EventEmitter<void> = new EventEmitter();

  emailForm: FormGroup;

  constructor(private fb: FormBuilder) {
    // Initialize the form group and controls with renamed fields
    this.emailForm = this.fb.group({
      emailTypeField: [''],
      emailAddressField: [''],
      isFavoriteField: [false],
    });
  }

  ngOnInit(): void {
    // Initialize or load form data when the component is initialized
    this.loadData();
  }

  loadData(): void {
    if (this.emailDto) {
      this._email = new ContactEmail(this.emailDto); // Ensure the local model is updated
      // Patch form values using renamed fields
      this.emailForm.patchValue({
        emailTypeField: this._email.emailType,
        emailAddressField: this._email.emailAddress,
        isFavoriteField: this._email.isFavorite,
      });
    }
  }

  save(): void {
    // Update the model with form values before saving
    this._email.emailType = this.emailForm.get('emailTypeField')?.value;
    this._email.emailAddress = this.emailForm.get('emailAddressField')?.value;
    this._email.isFavorite = this.emailForm.get('isFavoriteField')?.value;
    
    console.log('save', this._email);
    this.onSubmit.emit(this._email.getDto());
  }

  cancel(): void {
    this.loadData(); // Reset form to original values
    this.onCancel.emit();
  }
}
