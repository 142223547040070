/**
 * Contact est une classe qui gère un contact
 */

import { BaseEntity } from "../base-entity.class";
import { ContactTitle } from "./contact-title.class";
import { IContactDto } from "./contact.dto";
import { Occupancy } from "./occupancy/occupancy.class";

export class Contact extends BaseEntity<IContactDto> {
    id: number = 0;
    // type: string = '';
    idTitle?: number;
    firstName: string = '';
    surname: string = '';
    lastName: string = '';
    company: string = '';
    siret: string = '';
    title?: ContactTitle;
    occupancy?: Occupancy[];                // les adresses possibles pour le contact

    constructor(dto?: IContactDto) {
        super(dto);
        if (dto) this.loadDto(dto);
    }

    protected override getMappings(): Partial<Record<keyof IContactDto, keyof this>> {
        return {
            idPerson: 'id',
            // typePerson: 'type',
            firstName: 'firstName',
            surname: 'surname',
            lastName: 'lastName',
            idTitle: 'idTitle',
            company: 'company',
            siret: 'siret',
        }
    }

    protected override loadComplexProperties(dto: IContactDto): void {
        if (dto.title) {
            this.title = new ContactTitle(dto.title);
        }
    }

    protected override getComplexProperties(dto: IContactDto): void {
        if (this.title) {
            dto.title = this.title.getDto();
        }
    }

    public formattedName(): string {
        let nameParts: string[] = [];

        if (this.title && this.title.shortTitle) {
            nameParts.push(this.title.shortTitle);
        }

        if (this.firstName) {
            nameParts.push(this.firstName);
        }

        if (this.surname) {
            nameParts.push(`"${this.surname}"`);
        }

        if (this.lastName) {
            nameParts.push(this.lastName);
        }

        return nameParts.join(' ').trim();
    }

    get isNew(): boolean {
        return this.id === 0;
    }
}