import { IAddressDto } from "./address.dto";

export class Address {
    idAddress?: number;
    deliveryPoint?: string;
    buildingLocation?: string;
    streetNumber?: string;
    streetType?: string;
    streetName?: string;
    deliveryService?: string;
    postalCode?: string;
    city?: string;
    country?: string;
    timezone?: string;

    constructor(dto?: IAddressDto) {
        if (dto) this.loadDto(dto);
    }

    public getDto(): IAddressDto {
        return {
            idAddress: this.idAddress,
            deliveryPoint: this.deliveryPoint,
            buildingLocation: this.buildingLocation,
            streetNumber: this.streetNumber,
            streetType: this.streetType,
            streetName: this.streetName,
            deliveryService: this.deliveryService,
            postalCode: this.postalCode,
            city: this.city,
            country: this.country,
            timezone: this.timezone,
        };
    }

    public loadDto(dto: IAddressDto) {
        this.idAddress = dto.idAddress;
        this.deliveryPoint = dto.deliveryPoint;
        this.buildingLocation = dto.buildingLocation;
        this.streetNumber = dto.streetNumber;
        this.streetType = dto.streetType;
        this.streetName = dto.streetName;
        this.deliveryService = dto.deliveryService;
        this.postalCode = dto.postalCode;
        this.city = dto.city;
        this.country = dto.country;
        this.timezone = dto.timezone;
    }

    // Retourne l'adresse formatée complète
    public formattedAddress(): string {
        const parts: string[] = [];

        if (this.streetNumber) parts.push(this.streetNumber);
        if (this.streetType) parts.push(this.streetType);
        if (this.streetName) parts.push(this.streetName);
        if (this.postalCode) parts.push(this.postalCode);
        if (this.city) parts.push(this.city);
        if (this.country) parts.push(this.country);

        return parts.filter(Boolean).join(' ');
    }

    // Retourne une version abrégée de l'adresse (par exemple rue + ville)
    public shortAddress(): string {
        const parts: string[] = [];
        if (this.streetName) parts.push(this.streetName);
        if (this.city) parts.push(this.city);

        return parts.filter(Boolean).join(', ');
    }

    // Vérifie si l'adresse est complète (tous les champs essentiels sont remplis)
    public isComplete(): boolean {
        return !!(this.streetName && this.postalCode && this.city && this.country);
    }

    // Vérifie si cette adresse est égale à une autre
    public isEqualTo(address: Address): boolean {
        return (
            this.streetNumber === address.streetNumber &&
            this.streetName === address.streetName &&
            this.postalCode === address.postalCode &&
            this.city === address.city &&
            this.country === address.country
        );
    }

    // Valide le code postal (si une validation spécifique est nécessaire)
    public validatePostalCode(): boolean {
        if (!this.postalCode || !this.country) {
            return false;
        }
        // Par exemple, pour la France
        if (this.country === 'France') {
            const frenchPostalCodeRegex = /^\d{5}$/;
            return frenchPostalCodeRegex.test(this.postalCode);
        }
        // Autres validations pour d'autres pays peuvent être ajoutées ici
        return true;
    }

    // Vérifie si l'adresse contient au moins les informations minimales
    public get isValid(): boolean {
        return !!this.city && !!this.country;
    }
}
