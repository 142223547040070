import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserService } from '../../user/user.service';
import { ApiService } from '../../api/api.service';
import { IAddressDto } from './address.dto';
import { catchError, map, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Address } from './address.class';

@Injectable({
  providedIn: 'root'
})
export class AddressService {

  constructor(
    private apiService: ApiService,
  ) {}

  instanciate(
    address: Address,
  ) {
    const url = `${environment.api.url}${environment.api.endpoint.addressInstanciate}`;
    return this.apiService.post<IAddressDto>(url, address.getDto()).pipe(
      map(dto => new Address(dto))
    );
  }

  instanciate_deprecated(dto: IAddressDto): Observable<IAddressDto | null> {
    const url = `${environment.api.url}${environment.api.endpoint.addressInstanciate}`;
    return this.apiService.post<IAddressDto>(url, dto);
  }

  list(
    options: IAddressRequestOptions = {}
  ): Observable<Address[] | null> {
    const url = `${environment.api.url}${environment.api.endpoint.addressList}`;
    const params = new HttpParams()
      .set('ctry', options.country ?? '')
      .set('city', options.city ?? '');
    return this.apiService.get<IAddressDto[]>(url, {params}).pipe(
      map(dtos => dtos.map(dto => new Address(dto)))
    );
  }

  get(id: number): Observable<Address | null> {
    const url = `${environment.api.url}${environment.api.endpoint.addressGet}/a/${id}`;
    const params = new HttpParams();
    return this.apiService.get<IAddressDto>(url, {params}).pipe(
      map(dto => new Address(dto))
    );
  }

  getStoredCountryList(): Observable<string[] | null> {
    const url = `${environment.api.url}${environment.api.endpoint.addressStoredCountryList}`;
    const params = new HttpParams()
    return this.apiService.get<string[]>(url, {params});
  }

  getStoredCityList(country: string, postalcode: string): Observable<string[] | null> {
    const url = `${environment.api.url}${environment.api.endpoint.addressStoredCityList}`;
    const params = new HttpParams()
      .set('country', country)
      .set('postalcode', postalcode);
    return this.apiService.get<string[]>(url, {params});
  }

  getStoredPostalCodeList(country: string, city: string): Observable<string[] | null> {
    const url = `${environment.api.url}${environment.api.endpoint.addressStoredPostalCodeList}`;
    const params = new HttpParams()
      .set('country', country)
      .set('city', city);
    return this.apiService.get<string[]>(url, {params});
  }

  getStoredDeliveryServiceList(
    country: string, 
    city: string, 
    postalcode: string
  ): Observable<string[] | null> {
    const url = `${environment.api.url}${environment.api.endpoint.addressStoredDeliveryServiceList}`;
    const params = new HttpParams()
      .set('country', country)
      .set('city', city)
      .set('postalcode', postalcode);
    return this.apiService.get<string[]>(url, {params});
  }

  getStoredStreetNameList(
    country: string, 
    city: string, 
    postalcode: string, 
    streettype: string
  ): Observable<string[] | null> {
    const url = `${environment.api.url}${environment.api.endpoint.addressStoredStreetNameList}`;
    const params = new HttpParams()
      .set('country', country)
      .set('city', city)
      .set('postalcode', postalcode)
      .set('streettype', streettype);
    return this.apiService.get<string[]>(url, {params});
  }

  getStoredStreetNumberList(
    country: string, 
    city: string, 
    postalcode: string, 
    streetname: string,
    streettype: string,
  ): Observable<string[] | null> {
    const url = `${environment.api.url}${environment.api.endpoint.addressStoredStreetNumberList}`;
    const params = new HttpParams()
      .set('country', country)
      .set('city', city)
      .set('postalcode', postalcode)
      .set('streetname', streetname)
      .set('streettype', streettype);
    return this.apiService.get<string[]>(url, {params});
  }

  getStoredStreetTypeList(
    country: string, 
    city: string, 
    postalcode: string, 
    streetname: string
  ): Observable<string[] | null> {
    const url = `${environment.api.url}${environment.api.endpoint.addressStoredStreetTypeList}`;
    const params = new HttpParams()
      .set('country', country)
      .set('city', city)
      .set('postalcode', postalcode)
      .set('streetname', streetname);
    return this.apiService.get<string[]>(url, {params});
  }

  getStoredBuildingLocationList(
    country: string, 
    city: string, 
    postalcode: string, 
    streetname: string, 
    streettype: string,
    streetnumber: string
  ): Observable<string[] | null> {
    const url = `${environment.api.url}${environment.api.endpoint.addressStoredBuildingLocationList}`;
    const params = new HttpParams()
      .set('country', country)
      .set('city', city)
      .set('postalcode', postalcode)
      .set('streetname', streetname)
      .set('streettype', streettype)
      .set('streetnumber', streetnumber);
    return this.apiService.get<string[]>(url, {params});
  }

  getStoredDeliveryPointList(
    country: string, 
    city: string, 
    postalcode: string, 
    deliveryservice: string, 
    streetname: string, 
    streettype: string, 
    streetnumber: string, 
    buildinglocation: string
  ): Observable<string[] | null> {
    const url = `${environment.api.url}${environment.api.endpoint.addressStoredDeliveryPointList}`;
    const params = new HttpParams()
      .set('country', country)
      .set('city', city)
      .set('postalcode', postalcode)
      .set('deliveryservice', deliveryservice)
      .set('streetname', streetname)
      .set('streettype', streettype)
      .set('streetnumber', streetnumber)
      .set('buildinglocation', buildinglocation);
    return this.apiService.get<string[]>(url, {params});
  }
}

export interface IAddressRequestOptions {
  country?: string,
  city?: string,
  id?: number,
}
