import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, map, of } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Rent } from './rent.class';
import { IOperationRequestOptions, OperationService } from '../../operation/operation.service';
import { ApiService } from '../../api/api.service';
import { IOperationDto } from '../../operation/operation.dto';
import { EOperationType } from '../../operation/operation-type.enum';
import { saveAs } from 'file-saver';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class RentService {
  // Map pour stocker les objets Rent associés à des clés uniques
  private rentStorage = new Map<string, Rent>();

  constructor(
    private operationService: OperationService,
    private apiService: ApiService,
  ) {}

  delete(idOperation: number): Observable<void> {
    const url: string = `${environment.api.url}${environment.api.endpoint.rentDelete}/${idOperation}`;
    return this.apiService.delete<void>(url).pipe(
      catchError(this.apiService.handleError.bind(this))
    );
  }

  /**
   * Stocke un objet Rent associé à une clé unique
   * @param rent L'objet Rent à stocker
   * @returns La clé unique générée associée à l'objet stocké
   */
  storeRent(rent: Rent): string {
    const uniqueKey = this.generateUniqueKey();
    this.rentStorage.set(uniqueKey, rent);
    return uniqueKey; // Retourne la clé au composant qui stocke
  }

  /**
   * Récupère un objet Rent à l'aide de sa clé unique
   * @param key La clé unique associée à l'objet Rent
   * @returns L'objet Rent correspondant ou null si non trouvé
   */
  getRentByKey(key: string): Rent | null {
    return this.rentStorage.get(key) || null;
  }

  /**
   * Supprime un objet Rent de la mémoire après utilisation
   * @param key La clé unique associée à l'objet Rent à supprimer
   */
  removeRentByKey(key: string): void {
    this.rentStorage.delete(key);
  }

  /**
   * Génère une clé unique pour stocker l'objet Rent
   * @returns Une chaîne de caractères unique
   */
  private generateUniqueKey(): string {
    return `${Date.now()}-${Math.random()}`; // Génère une clé unique basée sur l'heure et un nombre aléatoire
  }

  // Reste du service inchangé
  list(rentOptions: IRentRequestOptions = {}): Observable<Rent[] | null> {
    const options: IOperationRequestOptions = {
      ...getRentBaseOperationRequest(),
      ...rentOptions, 
    };

    return this.operationService.list(options).pipe(
      map(data => {
        if (!data) return null;

        const rentList: Rent[] = data.map(d => new Rent(d));
        return rentList;
      }),
      catchError(error => {
        console.error('Error fetching operation list', error);
        return of(null);
      })
    )
  };

  get(
    idOperation: number,
    options: IRentRequestOptions = {},
  ): Observable<Rent | null> {
    console.log('Appel de la méthode get avec idOperation:', idOperation, 'et options:', options); // Log pour vérifier les paramètres d'entrée
  
    return this.operationService.get(idOperation).pipe(
      map(dto => {
        if (dto) {
          console.log('DTO reçu depuis l\'operationService:', dto); // Log pour afficher le DTO reçu
  
          const rent: Rent = new Rent(dto);
          console.log('Objet Rent construit:', rent); // Log pour afficher l'objet Rent créé à partir du DTO
  
          return rent;
        } else {
          console.log('Aucun DTO trouvé pour idOperation:', idOperation); // Log si aucun DTO n'est trouvé
          return null;
        }
      }),
      catchError(error => {
        console.error('Erreur lors de la récupération de l\'opération pour le loyer:', error); // Log en cas d'erreur
  
        // Retourne un Observable avec la valeur null en cas d'erreur
        return of(null);
      })
    );
  }

  listRentToIssue(
    idlease: number,
  ): Observable<Rent[] | null> {
    const url = `${environment.api.url}${environment.api.endpoint.rentToIssue}/${idlease}`;
    return this.apiService.get<IOperationDto[]>(url).pipe(
      map(ops => ops.map(o => new Rent(o))),
      catchError(this.apiService.handleError.bind(this)),
    );
  }

  save(
    rent: Rent,
  ): Observable<Rent | null> {
    const url: string = `${environment.api.url}${environment.api.endpoint.rentSave}`;
    
    // Log de l'URL et des données à envoyer
    console.log('URL d\'envoi:', url);
    console.log('Données du loyer à sauvegarder:', rent.getDto());
  
    return this.apiService.post<IOperationDto>(url, rent.getDto()).pipe(
      map(o => {
        // Log du résultat reçu du backend
        console.log('Réponse reçue du serveur:', o);
        return new Rent(o);
      }),
      catchError(error => {
        // Log en cas d'erreur
        console.error('Erreur lors de la sauvegarde du loyer:', error);
        return this.apiService.handleError.bind(this)(error);
      }),
    );
  }
  
  updateStatus(
    rent: Rent,
    code: string,
  ): Observable<Rent | null> {
    const url: string = `${environment.api.url}${environment.api.endpoint.rentStatus}/${rent.idOperation}/${code}`;
  
    // Log de l'URL et des paramètres de mise à jour
    console.log('updateStatus appelé avec rent ID:', rent.idOperation, 'et code:', code);
    console.log('URL pour la mise à jour:', url);
  
    return this.apiService.patch<IOperationDto>(url, {}).pipe(
      map(dto => {
        console.log('Données de réponse DTO reçues:', dto); // Log pour afficher le DTO reçu
        const result: Rent = new Rent(dto);
        console.log('convert rent', result);
        return result;
      }),
      catchError(error => {
        console.error('Erreur lors de la mise à jour du statut:', error); // Log pour afficher l'erreur si elle survient
        return this.apiService.handleError.bind(this)(error);
      })
    );
  }
  
  printReceipt(rent: Rent): Observable<boolean> {
    const url: string = `${environment.api.url}${environment.api.endpoint.rentDev}/${rent.idOperation}`;
    
    console.log('logement', rent.lease?.accommodation?.name);
    console.log('tenants', rent.lease?.tenants);
    console.log('rent', rent);
    console.log('Appel de printReceipt pour le loyer ID:', rent.idOperation);
    console.log('URL pour le téléchargement:', url);

    return this.apiService.getBlob(url).pipe(
      map((blob: Blob) => {
          const fileName = `Quittance_Loyer_${rent.idOperation}.pdf`;
          saveAs(blob, rent.receiptFilename);
          console.log('Quittance téléchargée sous le nom de fichier :', rent.receiptFilename);
          return true; // Succès
      }),
      catchError((error) => {
          console.error('Erreur lors du téléchargement de la quittance :', error);
          return of(false); // Échec
      })
    );
  }


}

export interface IRentRequestOptions {
  inStatus?: string[],
  exStatus?: string[],
}

export function getRentBaseOperationRequest(): IOperationRequestOptions {
  return {
    typeOperation: EOperationType.Rent,
  }
}