import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Rent } from '../../rent.class';
import { RentService } from '../../rent.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { LocaleDate } from '../../../../time/locale';
import { RentEditPlugin } from '../../plugin/edit/rent-edit.plugin';
import { RentViewPlugin } from '../../plugin/view/rent-view.plugin';
import { IOperationDto } from '../../../../operation/operation.dto';

@Component({
  selector: 'route-rent-edit',
  standalone: true,
  imports: [
    CommonModule,
    RentEditPlugin,
    RentViewPlugin,
  ],
  templateUrl: './rent-emit.route.html',
  styleUrl: './rent-emit.route.scss'
})
export class RentEmitRoute implements OnInit {
  public rent: Rent = new Rent();

  public editMode: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private rentService: RentService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      const storedRentKey: string = params.get('key') || '';
      console.log('ParamMap received:', storedRentKey);
      if (storedRentKey) this.loadStoredData(storedRentKey);
    });
  }

  loadStoredData(itemKey: string) {
    this.rent = this.rentService.getRentByKey(itemKey) || new Rent();
  }

  toggleEditMode() {
    this.editMode = !this.editMode;
  }

  update(dto: IOperationDto) {
    this.rent = new Rent(dto);
    this.toggleEditMode();
  }

  cancelEdit() {
    this.router.navigate(['management/dashboard']); // Redirige après annulation
  }

  // save de l'operation
  emitRent() {
    this.rentService.save(this.rent).subscribe(r => {
      if (r) {
        this.rent = r;
        this.router.navigate(['management/dashboard']);
      }
    })
  }
}
