import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IOccupancyDto } from '../../occupancy.dto';
import { Occupancy } from '../../occupancy.class';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Address } from '../../../../wealth/address/address.class';

@Component({
  selector: 'plugin-occupancy-data-edit',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
  ],
  templateUrl: './occupancy-data-edit.plugin.html',
  styleUrl: './occupancy-data-edit.plugin.scss'
})
/**
 * ce plugin édite les données d'un objet occupancy
 * les données sont reçues et renvoyées par un DTO
 * 
 */
export class OccupancyDataEditPlugin {
  @Input()
  set occupancyDto(value: IOccupancyDto) {
    if (!this.loaded) {
      this.loadDto(value);
    }
  }

  @Output() onSave: EventEmitter<IOccupancyDto> = new EventEmitter();
  @Output() onCancel: EventEmitter<void> = new EventEmitter();

  loaded: boolean = false;
  occupancy: Occupancy = new Occupancy();

  constructor() {}

  loadDto(dto: IOccupancyDto) {
    if (!this.loaded) {
      this.occupancy = new Occupancy(dto);

      // S'assurer que l'adresse est toujours initialisée
      if (!this.occupancy.address) {
        this.occupancy.address = new Address(); // Créer une adresse vide si non existante
      }

      this.loaded = true;
    }
  }

  cancel() {
    console.log('cancen data edit');
    this.onCancel.emit();
  }

  save() {
    // idAddress et idContact ne peuvent pas être modifiés
    const dto: IOccupancyDto = this.occupancy.getDto();
    this.onSave.emit(dto);
  }
}
