import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { IInvoiceHeaderDto } from '../../../invoice-header.dto';
import { InvoiceHeader } from '../../../invoice-header.class';
import { ContactNameViewPlugin } from '../../../../../contact/widget/name-view/contact-name-view.widget';
import { OccupancyShortViewWidget } from '../../../../../contact/occupancy/widget/occupancy-short-view/occupancy-short-view.widget';

@Component({
  selector: 'plugin-invoice-view',
  standalone: true,
  imports: [
    CommonModule,
    ContactNameViewPlugin,
    OccupancyShortViewWidget
  ],
  templateUrl: './invoice-view.plugin.html',
  styleUrl: './invoice-view.plugin.scss'
})
export class InvoiceViewPlugin implements OnChanges {
  @Input() invoiceDto: IInvoiceHeaderDto | undefined;
  invoice: InvoiceHeader | undefined;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['invoiceDto']) {
      if (this.invoiceDto) {
        this.invoice = new InvoiceHeader(this.invoiceDto);
        console.log('Issuer Occupancy:', this.invoice.idIssuerOccupancy);
        console.log('Recipient Occupancy:', this.invoice.idRecipientOccupancy);
      } else {
        this.invoice = undefined;
        console.log('Invoice reset to undefined');
      }
    }
  }
}