<div class="rent-view">
    <h1>Détails du Loyer</h1>
  
    <!-- Affichage des informations du loyer -->
    <div class="rent-info">
      <!-- Nom de l'opération -->
      <div class="info-item">
        <label>Nom de l'opération:</label>
        <span>{{ rent.name || 'Non défini' }}</span>
      </div>
  
      <!-- Mois et année de référence -->
      <div class="info-item">
        <label>Année de référence:</label>
        <span>{{ rent.refYear || 'Non défini' }}</span>
      </div>
  
      <div class="info-item">
        <label>Mois de référence:</label>
        <span>{{ rent.refMonth || 'Non défini' }}</span>
      </div>
  
      <!-- Montants -->
      <div class="info-item">
        <label>Montant du loyer (€):</label>
        <span>{{ rent.rentAmount || 'Non défini' }}</span>
      </div>
  
      <div class="info-item">
        <label>Montant des frais (€):</label>
        <span>{{ rent.feesAmount || 'Non défini' }}</span>
      </div>
  
      <!-- Dates -->
      <div class="info-item">
        <label>Date de début:</label>
        <span>{{ rent.startLocale?.date || 'Non défini' }}</span>
      </div>
  
      <div class="info-item">
        <label>Date de fin:</label>
        <span>{{ rent.endLocale?.date || 'Non définie' }}</span>
      </div>
    </div>
  </div>
  