<div *ngIf="items && items.length > 0; else noItems">
  <table>
    <thead>
      <tr>
        <th>Référence</th>
        <th>Description</th>
        <th>Quantité</th>
        <th>Total HT</th>
        <th>TVA</th>
        <th>Total TTC</th>
        <th>Action</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of items" (click)="selectItem(item)">
        <td>{{ item.reference || 'N/A' }}</td>
        <td>{{ item.description || 'N/A' }}</td>
        <td>{{ item.quantity | number }}</td>
        <td class="right-align">{{ item.totalExcludingVAT | number:'1.2-2' }} €</td>
        <td class="right-align">{{ item.totalVAT | number:'1.2-2' }} €</td>
        <td class="right-align">{{ item.total | number:'1.2-2' }} €</td>
        <td>
          <button (click)="selectItem(item)">Éditer</button>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<ng-template #noItems>
  <p>Aucun item trouvé pour cette facture.</p>
</ng-template>