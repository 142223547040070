import { Directive, OnInit } from "@angular/core";
import { Tag } from "../tag/tag.class";
import { NavService } from "../nav/nav.service";

// Classe abstraite parent gérant tagBU
@Directive()
export abstract class TagBUComponent implements OnInit {
    protected tagBU: Tag = new Tag();
  
    constructor(protected navService: NavService) {}
  
    ngOnInit() {
      this.initializeTagBU();
    }
  
    // Méthode protégée pour initialiser tagBU
    protected initializeTagBU(): void {
      console.log('Initialisation de tagBU dans la classe parente');
      this.tagBU = this.navService.getTagBU();
      console.log('tagBU chargé:', this.tagBU);
    }
  
// Méthode utilitaire pour ajouter tagBU à un objet ayant une méthode addTag
protected applyTagBU<T extends { addTag(tag: Tag): void }>(entity: T): void {
    console.log('applyTagBU called with entity:', entity);
    console.log('Current tagBU:', this.tagBU);
    
    if (this.tagBU.label !== '') {
      console.log('Applying tagBU to entity. TagBU label:', this.tagBU.label);
      entity.addTag(this.tagBU);
      console.log('tagBU successfully applied to entity:', entity);
    } else {
      console.log('tagBU not applied: label is empty');
    }
  }
  
    // Méthode pour obtenir tagBU (si besoin d'accès extérieur)
    protected getTagBU(): Tag {
      return this.tagBU;
    }
  }