import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { InvoiceItem } from '../../invoice-item.class';
import { InvoiceItemService } from '../../invoice-item.service';
import { CommonModule } from '@angular/common';
import { IInvoiceItemDto } from '../../invoice-item.dto';

@Component({
  selector: 'plugin-invoice-item-picker',
  standalone: true,
  imports: [
    CommonModule,
  ],
  templateUrl: './invoice-item-picker.plugin.html',
  styleUrl: './invoice-item-picker.plugin.scss'
})
export class InvoiceItemPickerPlugin implements OnInit {
  @Input() invoiceId: number | undefined;
  @Output() itemSelected = new EventEmitter<IInvoiceItemDto>();
  items: InvoiceItem[] = [];

  constructor(
    private invoiceItemService: InvoiceItemService,
    ) {}

  ngOnInit(): void {
    if (this.invoiceId) {
      this.loadItems();
    }
  }    

  refreshItems(): void {
    this.loadItems();
  }
    
  private loadItems(): void {
    if (this.invoiceId) {
      this.invoiceItemService.list(this.invoiceId).subscribe(
        (items) => {
          this.items = items;
        },
        (error) => {
          console.error('Error loading invoice items:', error);
        }
      );
    }
  }  

  selectItem(item: InvoiceItem): void {
    this.itemSelected.emit(item.getDto()); // Émettre le DTO au lieu de l'objet
    console.log('Item selected:', item.getDto());
  }
}
