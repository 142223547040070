import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Lease } from '../../lease.class';
import { ILeaseDto } from '../../lease.dto';
import { CommonModule } from '@angular/common';
import { LeaseDataEditPlugin } from '../data-edit/lease-data-edit.plugin';
import { LeaseDataViewPlugin } from '../data-view/lease-data-view.plugin';
import { AccommodationViewPlugin } from '../../../../wealth/accommodation/plugin/view/accommodation-view.plugin';
import { AccommodationPickerPlugin } from '../../../../wealth/accommodation/plugin/picker/accommodation-picker.plugin';
import { IAccommodationDto } from '../../../../wealth/accommodation/accommodation.dto';
import { Accommodation } from '../../../../wealth/accommodation/accommodation.class';
import { IAccommodationRequestOptions } from '../../../../wealth/accommodation/accommodation.service';
import { ContactMultiPlugin } from '../../../../contact/plugin/multi/contact-multi.plugin';
import { IContactDto } from '../../../../contact/contact.dto';
import { Contact } from '../../../../contact/contact.class';
import { LeaseStatusManagerPlugin } from '../status-manager/lease-status-manager.plugin';
import { Occupancy } from '../../../../contact/occupancy/occupancy.class';
import { IOccupancyRequestOptions, OccupancyService } from '../../../../contact/occupancy/occupancy.service';
import { FormsModule } from '@angular/forms';
import { OccupancySelectorPlugin } from '../occupancy-selector/occupancy-selector.plugin';

@Component({
  selector: 'plugin-lease-edit',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    LeaseDataEditPlugin,
    LeaseDataViewPlugin,
    AccommodationViewPlugin,
    AccommodationPickerPlugin,
    ContactMultiPlugin,
    OccupancySelectorPlugin,
  ],
  templateUrl: './lease-edit.plugin.html',
  styleUrl: './lease-edit.plugin.scss'
})
export class LeaseEditPlugin {
  @ViewChild(LeaseDataViewPlugin) leaseDataViewPlugin!: LeaseDataViewPlugin;
  @ViewChild(LeaseDataEditPlugin) leaseDataEditPlugin!: LeaseDataEditPlugin;
  @ViewChild(AccommodationViewPlugin) accommodationViewPlugin!: AccommodationViewPlugin;
  @ViewChild(AccommodationPickerPlugin) accommodationPickerPlugin!: AccommodationPickerPlugin;
  @ViewChild(ContactMultiPlugin) contactMultiPlugin!: ContactMultiPlugin;

  @Input()
  set leaseDto(value: ILeaseDto) {
      // Vérifier si le DTO reçu est différent de l'actuel avant d'injecter
      if (!this.loaded || this.loadedDto.idLease !== value.idLease) {
          this.loadedDto = value;
          this.loadDto(value);
      } else {
          console.log('Aucune mise à jour requise, DTO inchangé.');
      }
  }
  
  @Output() onSave: EventEmitter<ILeaseDto> = new EventEmitter(); // Émettre les données sauvegardées
  @Output() onCancel: EventEmitter<void> = new EventEmitter(); // Émettre l'événement d'annulation

  public currentLease: Lease = new Lease();                      // objet courant
  public _leaseDto: ILeaseDto = (new Lease()).getDto();   // export objet courant pour plugins sous-jacents

  // issuerOccupancyList: Occupancy[] = [];                  // liste des occupancy des lessors (à supprimer)
  // issuerStringList: string[] = [];                        // nom  et adresse 

  // public issuerOccupancyPickerOptions: IOccupancyRequestOptions = {};

  set lease(value: Lease) {
    this.currentLease = new Lease();
    this.currentLease = value;
    this._leaseDto = this.currentLease.getDto();
  }

  get currentLeaseDto(): ILeaseDto {
    return this._leaseDto;
  }

  public _accommodationDto: IAccommodationDto = (new Accommodation()).getDto();    // instance de lease.accommodation
  loaded: boolean = false;

  public editMode: boolean = false;
  public accommodationEditMode = false;
  public hasAccommodation: boolean = false;

  private loadedDto: ILeaseDto = (new Lease()).getDto();    // sauvegarde du DTO reçu (pour détecter une mise à jour)


  constructor(
    private occupancyService: OccupancyService,
  ) {}

  updateIssuerOccupancy(occupancyId: number) {
    console.log('Occupancy ID sélectionné :', occupancyId);
    this.currentLease.issuerOccupancyId = occupancyId;
  }

  
  loadDto(dto: ILeaseDto) {
    // Initialise l'objet Lease avec le DTO
    if (!dto) {
      console.error('DTO is undefined or null');
      return;
    }
    this.lease = new Lease(dto);

    // Vérifie si this.lease est bien initialisé avant de continuer
    if (!this.currentLease) {
      console.error('Lease object is not initialized properly');
      return;
    }

    this.editMode = this.currentLease.isNew;
    this.hasAccommodation = this.currentLease.hasAccommodation;
    this._accommodationDto = this.currentLease.accommodation ? this.currentLease.accommodation.getDto() : (new Accommodation()).getDto();

    this.loaded = true;
  }

  toggleEditMode() {
    this.editMode = !this.editMode; // Bascule entre les modes vue et édition
  }
  toggleAccommodationEditMode() {
    this.accommodationEditMode = !this.accommodationEditMode;
  }

  get accommodationPickerOptions(): IAccommodationRequestOptions {
    return {
      selectButtonLabel: 'Choisir ce logement',
    }
  }

  mergeCurrentLease(updatedLease: Lease) {
    const l = this.currentLease;
    l.mergeChangesFromLease(updatedLease);    // ne propage pas l'id
    l.id = updatedLease.id;   // propagation du id
    this.lease = l;
  }

  saveData(dto: ILeaseDto) {
    console.log('Début de saveData avec DTO :', dto);

      // Sauvegarde de l'occupancy du bailleur sélectionné
    dto.issuerOccupancyId = this.currentLease.issuerOccupancyId;

    if (!dto.idLease || dto.idLease === 0) {
      console.log('ID de dto est 0 ou non défini, mise à jour à -1 pour activer le mode vue');
      dto.idLease = -1;
    } else {
        console.log('ID de lease déjà défini :', dto.idLease);
    }

    // Création de l'objet Lease mis à jour à partir du DTO
    const updatedLease: Lease = new Lease(dto);
    console.log('Objet Lease mis à jour créé à partir du DTO :', updatedLease);

    // Fusion des changements dans le lease actuel
    console.log('Avant fusion des changements, état actuel de this.currentLease :', this.currentLease);
    this.mergeCurrentLease(updatedLease);
    console.log('Après fusion des changements, état mis à jour de this.currentLease :', this.currentLease);

    // Désactiver le mode édition
    this.editMode = false;
    console.log('Mode édition désactivé :', this.editMode);
  }

  cancelDataEdit() {
    this.editMode = false;
  }

  editAccommodation() {
    this.accommodationEditMode = true;
  }

  updateAccommodation(dto: IAccommodationDto) {
    const accommodation: Accommodation = new Accommodation(dto);
    this.currentLease.allocate(accommodation);
    this.loadDto(this.currentLease.getDto());
    this.accommodationEditMode = false;
  }

  // Gestion des bailleurs (lessors)
  get lessorsPickerOptions() {
    return {
      selectIds: this.currentLease.getLessorsIds(), // Pré-sélectionner les bailleurs actuels
    };
  }

  updateLessors(contactDtos: IContactDto[]) {
    const updatedLessors = contactDtos.map(dto => new Contact(dto));
    this.currentLease.lessors = updatedLessors; // Mettre à jour les bailleurs sélectionnés
    this._leaseDto = this.currentLease.getDto();
  }

  // Gestion des locataires (tenants)
  get tenantsPickerOptions() {
    return {
      selectIds: this.currentLease.getTenantsIds(),
    };
  }

  updateTenants(contactDtos: IContactDto[]) {
    const updatedTenants = contactDtos.map(dto => new Contact(dto));
    this.currentLease.tenants = updatedTenants;
  }

  // Sauvegarder les modifications du bail
  save() {
    console.log('Début de la méthode save()');
    
    // Convertir l'objet Lease actuel en DTO
    const dto: ILeaseDto = this.currentLease.getDto();
    console.log('DTO généré à partir de currentLease:', dto);
  
    // Vérifier l'ID du bail et ajuster si nécessaire
    if (!dto.idLease || dto.idLease < 0) {
      console.log(`ID de bail invalide ou absent (${dto.idLease}), mise à jour à 0`);
      dto.idLease = 0;
    } else {
      console.log('ID de bail valide:', dto.idLease);
    }
  
    // Émettre le DTO sauvegardé
    console.log('Émission du DTO via l\'événement onSave:', dto);
    this.onSave.emit(dto);
  
    console.log('Fin de la méthode save()');
  }
  
  

  // Annuler l'édition du bail
  cancel() {
    this.editMode = false;
    this.onCancel.emit();
  }

}
