import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, catchError, map } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ApiService } from '../../api/api.service';
import { UserService } from '../../user/user.service';
import { ILeaseDto } from './lease.dto';
import { Lease } from './lease.class';


@Injectable({
  providedIn: 'root'
})
export class LeaseService {
  constructor(
    private http: HttpClient,
    private userService: UserService,
    private apiService: ApiService,
    // private store: Store<{ [USER_STORE_KEY]: IUserState }>,
  ) {}

  /**
   * Obtention d'un objet Lease existant, selon son id
   * @param idlease 
   * @param options options pour le chargement des accessoires, pas de filtre
   * @returns 
   */
  get(
    idlease: number, 
    options: ILeaseRequestOptions = {},
  ): Observable<Lease | null> {
    const url = `${environment.api.url}${environment.api.endpoint.leaseGet}/${idlease}`;
    let params = new HttpParams()
      .set('acc', options.loadAccommodation ? +options.loadAccommodation : 0)
      .set('tnt', options.loadTenants ? +options.loadTenants : 0)
      .set('les', options.loadLessors ? +options.loadLessors : 0)
      .set('ops', options.loadOperations ? +options.loadOperations : 0);

    // if (options.statusList && options.statusList.length) {
    //   params = params.set('s', options.statusList.join(','));
    // }

    return this.apiService.get<ILeaseDto>(url, { params }).pipe(
      map(dto => dto ? new Lease(dto) : null),
      catchError(this.apiService.handleError.bind(this)),
    );
  }

  list(
    options: ILeaseRequestOptions = {},
  ): Observable<Lease[] | null> {
    const url = `${environment.api.url}${environment.api.endpoint.leaseList}`;
    let params = new HttpParams()
      .set('acc', options.loadAccommodation ? +options.loadAccommodation : 0)
      .set('tnt', options.loadTenants ? +options.loadTenants : 0)
      .set('les', options.loadLessors ? +options.loadLessors : 0);
    if (options.statusList && options.statusList.length) {
      params = params.set('sts', options.statusList.join(','));
    }
    return this.apiService.get<ILeaseDto[]>(url, { params }).pipe(
      map(dtos => dtos.map(dto => new Lease(dto))),
      catchError(this.apiService.handleError.bind(this)),
    );
  }
  
  save(
    data: Lease,
  ): Observable<Lease | null> {
    // Log du nom de la méthode et des données entrantes
    console.log(`[${this.save.name}] - Starting save process for Lease:`, data);
  
    // Construire l'URL pour l'API
    const url = `${environment.api.url}${environment.api.endpoint.leaseSave}`;
    console.log(`[${this.save.name}] - API URL:`, url);
  
    // Log du DTO avant de l'envoyer
    const dto = data.getDto();
    console.log(`[${this.save.name}] - Lease DTO to be sent:`, dto);
  
    return this.apiService.post<ILeaseDto>(url, dto).pipe(
      // Log après réception de la réponse de l'API
      map(dto => {
        console.log(`[${this.save.name}] - API Response received:`, dto);
        return new Lease(dto);
      }),
      // Log en cas d'erreur
      catchError((error) => {
        console.error(`[${this.save.name}] - Error during API call:`, error);
        return this.apiService.handleError.bind(this)(error);
      }),
    );
  }
  
  getLeaseStatusList(
    idlease: number,
  ): Observable<string[] | null> {
    const url: string = `${environment.api.url}${environment.api.endpoint.leaseStatusList}`;
    return this.apiService.get<string[]>(url).pipe(
      catchError(this.apiService.handleError.bind(this)),
    );
  }

  getLeaseNextStatusList(
    idlease: number,
  ): Observable<string[] | null> {
    const url: string = `${environment.api.url}${environment.api.endpoint.leaseNextStatusList}/${idlease}`;
    return this.apiService.get<string[]>(url).pipe(
      catchError(this.apiService.handleError.bind(this)),
    );
  }

  updateLeaseStatus(
    idLease: number,
    newStatus: string,
  ): Observable<Lease> {
    const url: string = `${environment.api.url}${environment.api.endpoint.leaseUpdateStatus}/${idLease}`;
    const body = { status: newStatus, idLease: idLease };
    return this.apiService.put<ILeaseDto>(url, body).pipe(
      map(dto => { return new Lease(dto); }), 
      catchError(this.apiService.handleError.bind(this)),
    );
  }

}

export interface ILeaseRequestOptions {
  loadAccommodation?: boolean;
  loadLessors?: boolean;
  loadTenants?: boolean;
  loadOperations?: boolean,
  statusList?: string[];
}