import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { InvoiceEditPlugin } from '../../plugin/edit/invoice-edit.plugin';
import { InvoiceViewPlugin } from '../../plugin/view/invoice-view/invoice-view.plugin';
import { InvoiceItemPickerPlugin } from '../../../item/plugin/picker/invoice-item-picker.plugin';
import { InvoiceItemEditPlugin } from '../../../item/plugin/edit/invoice-item-edit.plugin';
import { TagBUComponent } from '../../../../model/tag-bu-component.class';
import { InvoiceHeader } from '../../invoice-header.class';
import { InvoiceService } from '../../invoice-header.service';
import { NavService } from '../../../../nav/nav.service';
import { IInvoiceItemDto } from '../../../item/invoice-item.dto';
import { InvoiceItem } from '../../../item/invoice-item.class';
import { IInvoiceHeaderDto } from '../../invoice-header.dto';

@Component({
  selector: 'route-invoice-edit',
  standalone: true,
  imports: [
    CommonModule,
    InvoiceEditPlugin,
    InvoiceViewPlugin,
    InvoiceItemPickerPlugin,
    InvoiceItemEditPlugin,
  ],
  templateUrl: './invoice-edit.route.html',
  styleUrl: './invoice-edit.route.scss'
})
export class InvoiceEditRoute extends TagBUComponent implements OnInit {
  invoiceId: number | undefined;
  invoice: InvoiceHeader | undefined;
  isEditMode: boolean = false;
  isCreatingNewItem: boolean = false;
  isEditingItem: boolean = false;
  selectedItem: IInvoiceItemDto | undefined;
  isDownloading: boolean = false;
  
  @ViewChild(InvoiceItemPickerPlugin) itemPicker!: InvoiceItemPickerPlugin;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private invoiceService: InvoiceService,
    navService: NavService,
  ) {
    super(navService);
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.route.paramMap.subscribe(params => {
      const id = params.get('idInvoice');
      if (id !== null) {
        this.invoiceId = +id; // Conversion de string à number
        if (this.invoiceId === 0) {
          // Création d'une nouvelle facture
          this.invoice = new InvoiceHeader();
          this.isEditMode = true; // Passer directement en mode édition pour une nouvelle facture
          console.log('Creating new invoice');
        } else {
          this.loadInvoice();
        }
      }
    });
  }

  private loadInvoice(): void {
    if (this.invoiceId) {
      this.invoiceService.get(this.invoiceId, { loadIssuer: true, loadRecipient: true }).subscribe(
        (invoice: InvoiceHeader | null) => {
          if (invoice) {
            this.invoice = invoice;
            console.log('Invoice loaded:', this.invoice);
            this.refreshItemPicker();
          } else {
            console.error('Invoice not found with id:', this.invoiceId);
          }
        },
        error => console.error('Error loading invoice:', error)
      );
    }
  }

  toggleEditMode(): void {
    this.isEditMode = !this.isEditMode;
  }

  onInvoiceSaved(invoiceDto: IInvoiceHeaderDto): void {
    this.isEditMode = false;
    if (this.invoiceId === 0) {
      this.invoiceId = invoiceDto.invoiceId; // Mise à jour de l’ID pour une nouvelle facture
    }
    // Mettre à jour l’objet invoice avec le DTO reçu
    this.invoice = new InvoiceHeader(invoiceDto);
    console.log('Invoice updated after save:', this.invoice);
    this.refreshItemPicker();
  }

  createNewItem(): void {
    console.log('Creating new invoice item for invoice', this.invoiceId);
    this.isCreatingNewItem = true;
    this.isEditingItem = false; // Désactiver l'édition d'un item existant
    this.selectedItem = undefined;
  }

  editItem(itemDto: IInvoiceItemDto): void { // Changement de InvoiceItem à IInvoiceItemDto
    console.log('Editing invoice item:', itemDto);
    this.isEditingItem = true;
    this.isCreatingNewItem = false;
    this.selectedItem = itemDto; 
  }

  cancelNewItem(): void {
    this.isCreatingNewItem = false;
    this.isEditingItem = false;
    this.selectedItem = undefined;
  }

  onNewItemSaved(itemDto: IInvoiceItemDto): void {
    console.log('Item saved:', itemDto);
    this.isCreatingNewItem = false;
    this.isEditingItem = false;
    this.selectedItem = undefined;
    this.refreshItemPicker();
    this.loadInvoice(); // Recharger l'InvoiceHeader pour mettre à jour les totaux
    console.log('onNewItemSaved, states:', { isCreatingNewItem: this.isCreatingNewItem, isEditingItem: this.isEditingItem, selectedItem: this.selectedItem });
  }

  onTotalsUpdated(itemDto: IInvoiceItemDto): void {
    console.log('Totals updated for item:', itemDto);
    this.loadInvoice(); // Recharger l'InvoiceHeader pour mettre à jour les totaux
  }
  
  getNewItemDto(): IInvoiceItemDto {
    return {
      idInvoice: this.invoiceId // Valoriser idInvoice avec l'ID de la facture courante
    };
  }  

  private refreshItemPicker(): void {
    if (this.itemPicker) {
      this.itemPicker.refreshItems();
      console.log('Item picker refreshed');
    } else {
      console.warn('Item picker not yet initialized');
    }
  }

  downloadInvoice(): void {
    if (this.invoiceId) {
      this.invoiceService.downloadInvoice(this.invoiceId).subscribe(
        (success: boolean) => {
          if (success) {
            console.log('Invoice download initiated successfully');
          } else {
            console.warn('Download failed, but no error thrown');
          }
        },
        error => console.error('Error initiating invoice download:', error)
      );
    } else {
      console.warn('No invoice ID available for download');
    }
  }  
}