<div class="lease-list-container">
    <!-- Bouton Nouveau Bail -->
    <button (click)="newLease()" class="btn btn-primary">Nouveau bail</button>
  
    <!-- Liste des Baux -->
    <plugin-lease-picker 
        [options]="options" 
        (onActivate)="viewLease($event)" 
        (onView)="viewLease($event)">
    </plugin-lease-picker>
  </div>
  