import { Component, Input } from '@angular/core';
import { IOccupancyDto } from '../../occupancy.dto';
import { OccupancyService } from '../../occupancy.service';
import { Occupancy } from '../../occupancy.class';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'plugin-occupancy-view',
  standalone: true,
  imports: [
    CommonModule,
  ],
  templateUrl: './occupancy-view.plugin.html',
  styleUrl: './occupancy-view.plugin.scss'
})
export class OccupancyViewPlugin {
  @Input()
  set id(id: number) {
    this.loadData(id);
  }

  @Input()
  set dto(dto: IOccupancyDto) {
    this.loadDto(dto);
  }

  occupancy?: Occupancy;
  loaded: boolean = false;

  constructor(
    private occupancyService: OccupancyService,
  ) {}

  loadData(id: number) {
    if (!this.loaded || id !== this.occupancy?.id) {
      this.occupancyService.get(id, {
        loadAddress: true,
        loadPerson: true,
      }).subscribe(o => {
        if (o) {
          this.occupancy = o;
          this.loaded = true;
        } else {
          // afficher sur l'occupancy n'a pas été trouvé
          console.log('occupancy non trouvée', id);
        }
      });
    }
  }

  // à compléter
  loadDto(dto: IOccupancyDto) {
    this.occupancy = new Occupancy(dto);
  }

  getContactName(): string {
    return this.occupancy?.contact?.formattedName() || 'Occupant inconnu';
  }

  getAdditionalInfo(): string {
    return this.occupancy?.additionalInfo ?? 'Pas d\'informations supplémentaires';
  }
}
