<div class="occupancy-view" *ngIf="occupancy">
    <!-- En-tête avec le nom du contact ou de l'occupant -->
    <div class="header">
      <h2>{{ getContactName() }}</h2>
      <p class="additional-info">{{ getAdditionalInfo() }}</p>
    </div>
  
    <!-- Détails de l'adresse de l'occupant -->
    <div class="address-section">
      <h3>Adresse</h3>
      <p *ngIf="occupancy.address; else noAddress">
        {{ occupancy.address.formattedAddress() }}
      </p>
      <ng-template #noAddress>
        <p>Aucune adresse disponible</p>
      </ng-template>
    </div>
  
    <!-- Informations supplémentaires de l'occupation -->
    <div class="details">
      <div class="info">
        <span class="label">Type d'usage :</span>
        <span>{{ occupancy.addressUsage || 'Non spécifié' }}</span>
      </div>
      <div class="info">
        <span class="label">Date de début :</span>
        <span>{{ occupancy.dtStart ? (occupancy.dtStart | date : 'dd/MM/yyyy') : 'Non spécifiée' }}</span>
      </div>
      <div class="info">
        <span class="label">Date de fin :</span>
        <span>{{ occupancy.dtEnd ? (occupancy.dtEnd | date : 'dd/MM/yyyy') : 'Non spécifiée' }}</span>
      </div>
    </div>
  </div>
  