<div *ngIf="phoneDto; else noPhone">
    <h2>Éditer le Téléphone</h2>
    <form [formGroup]="phoneForm">
      <div>
        <label for="phoneType">Type de téléphone:</label>
        <input id="phoneType" formControlName="phoneType" />
      </div>
      <div>
        <label for="phoneNumber">Numéro de téléphone:</label>
        <input id="phoneNumber" formControlName="phoneNumber" required />
      </div>
      <div>
        <label for="isFavorite">Favori:</label>
        <input id="isFavorite" formControlName="isFavorite" type="checkbox" />
      </div>
      <div>
        <button type="button" (click)="save()">Sauvegarder</button>
        <button type="button" (click)="cancel()">Annuler</button>
      </div>
    </form>
  </div>
  <ng-template #noPhone>
    <p>Aucun téléphone sélectionné.</p>
  </ng-template>
  