import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Contact } from '../../contact.class';
import { ContactService, IContactRequestOptions } from '../../contact.service';
import { CommonModule } from '@angular/common';
import { IContactDto } from '../../contact.dto';

@Component({
  selector: 'plugin-contact-view',
  standalone: true,
  imports: [
    CommonModule,
  ],
  templateUrl: './contact-view.plugin.html',
  styleUrl: './contact-view.plugin.scss'
})
export class ContactViewPlugin {
  public contact: Contact = new Contact();

  @Input()
  set options(value: IContactRequestOptions) {
    // console.log('Options received in ContactViewPlugin:', value);
    this.loadData(value);
  }
  @Input()
  set contactDto(value: IContactDto) {
    // console.log('Options received in ContactViewPlugin dto:', value);
    this.loadDto(value);
  }

  @Output() onDelete: EventEmitter<void> = new EventEmitter();
  @Output() onEdit: EventEmitter<void> = new EventEmitter();

  private loaded: boolean = false;
  showDelete: boolean = false;
  showEdit: boolean = false;

  constructor(
    private contactService: ContactService,
  ) {}

  loadDto(dto: IContactDto) {
    if (dto) {
      this.contact = new Contact(dto);
      this.loaded = true;
    }
  }

  loadData(options: IContactRequestOptions) {
    // console.log('loadData called with options:', options);
    // console.log('loaded data:', this.loaded);
    if (!this.loaded && options.selectIds && options.selectIds.length > 0) {
      if (options.selectIds[0] !== 0) {
        // console.log('Fetching contact with ID:', options.selectIds[0]);
        this.contactService.get(options.selectIds[0] || 0, { loadTitle: true }).subscribe(c => {
          if (c) {
            this.contact = c;
            // console.log('Contact data loaded:', this.contact);
          } else {
            if (options.selectIds) {
              console.warn('No contact data found for ID:', options.selectIds[0]);
            }
          }
        });
      }
      // paramétrage 
      this.loaded = true;
      this.showDelete = options.showDelete || false;
      this.showEdit = options.showEdit || false;
    }
  }

  editContact() {
    // console.log('Edit button clicked for contact:', this.contact);
    this.onEdit.emit();
  }

  deleteContact() {
    // console.log('Delete button clicked for contact:', this.contact);
    this.onDelete.emit();
  }
}
