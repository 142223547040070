import { Component } from '@angular/core';

@Component({
  selector: 'app-user-home',
  standalone: true,
  imports: [],
  templateUrl: './user-home.route.html',
  styleUrl: './user-home.route.scss'
})
export class UserHomeRoute {

}
