import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common'; // Import pour *ngIf, *ngFor
import { IRentRequestOptions, RentService } from '../../rent.service';
import { Rent } from '../../rent.class';
import { IOperationDto } from '../../../../operation/operation.dto';

@Component({
  selector: 'plugin-rent-picker',
  standalone: true,
  imports: [CommonModule],  // Ajout du CommonModule pour les directives natives Angular
  templateUrl: './rent-picker.plugin.html',
  styleUrls: ['./rent-picker.plugin.scss'],
})
export class RentPickerPlugin {
  _options: IRentRequestOptions = {};
  @Input()
  set options(value: IRentRequestOptions) {
    this._options = value;
    this.loadList();
  }
  get options(): IRentRequestOptions {
    return this._options;
  }

  @Output() activated = new EventEmitter<IOperationDto>();

  list: Rent[] = [];
  dataLoaded: boolean = false;

  constructor(
    private rentService: RentService,
  ) {}

  loadList() {
    if (!this.dataLoaded) {
      this.rentService.list(this.options).subscribe(data => {
        if (data) {
          this.list = data;
          this.dataLoaded = true;
        }
      });
    }
  }

  dblClick(rent: Rent) {
    this.activated.emit(rent.getDto());
  }
}
