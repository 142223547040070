import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ContactPhone } from '../../contact-phone.class';
import { ContactPhoneService, IContactPhoneRequestOptions } from '../../contact-phone.service';
import { CommonModule } from '@angular/common';
import { IContactPhoneDto } from '../../contact-phone.dto';

@Component({
  selector: 'plugin-contact-phone-picker',
  standalone: true,
  imports: [
    CommonModule,
  ],
  templateUrl: './contact-phone.picker.html',
  styleUrl: './contact-phone.picker.scss'
})
export class ContactPhonePicker {
  public list: ContactPhone[] = [];
  private _options: IContactPhoneRequestOptions = {};
  private dataLoaded: boolean = false;

  @Input()
  set options(value: IContactPhoneRequestOptions) {
    this._options = value;
    this.loadData();
  }
  get options(): IContactPhoneRequestOptions {
    return this._options;
  }

  @Output() onDelete: EventEmitter<IContactPhoneDto> = new EventEmitter(); 

  constructor(
    private contactPhoneService: ContactPhoneService,
  ) {}

  loadData() {
    if (!this.dataLoaded && this.options && this.options.selectIdPerson && this.options.selectIdPerson !== 0) {
      this.contactPhoneService.list(this.options).subscribe(l => {
        if (l) {
          this.list = l;
          this.dataLoaded = true;
        }
      }, error => {
        console.error('Error loading contact phone list:', error); // Log any error from the API
      });
    }
  }

  reloadData() {
    this.dataLoaded = false;
    this.loadData();
  }

  deletePhone(phone: ContactPhone) {
    console.log('Delete phone:', phone);
    this.onDelete.emit(phone.getDto()); // Emit the phone to be deleted
  }
}
