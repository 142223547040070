import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IInvoiceItemVatDto } from '../../invoice-item-vat.dto';
import { InvoiceItemVat } from '../../invoice-item-vat.class';
import { InvoiceItemVatService } from '../../invoice-item-vat.service';

@Component({
  selector: 'plugin-invoice-item-vat-edit',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
  ],
  templateUrl: './invoice-item-vat-edit.plugin.html',
  styleUrl: './invoice-item-vat-edit.plugin.scss'
})
export class InvoiceItemVatEditPlugin implements OnChanges {
  @Input() itemId: number | undefined;
  @Input() vatDto: IInvoiceItemVatDto | undefined;
  @Output() saved = new EventEmitter<IInvoiceItemVatDto>();
  @Output() cancelled = new EventEmitter<void>();

  vat: InvoiceItemVat = new InvoiceItemVat();

  constructor(private invoiceItemVatService: InvoiceItemVatService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['vatDto']) {
      if (this.vatDto) {
        this.vat = new InvoiceItemVat(this.vatDto);
        console.log('Editing VAT updated:', this.vat);
      } else if (this.itemId !== undefined) {
        this.vat = new InvoiceItemVat({
          idItem: this.itemId,
          rateVAT: 20,
          amountExcludingVAT: 0,
          amountVAT: 0,
          amount: 0
        });
        console.log('New VAT initialized with itemId:', this.itemId, 'and rateVAT: 20');
      }
    }
  }

  saveVat(): void {
    if (this.itemId) {
      this.vat.idItem = this.itemId;
      if (this.vat.id) {
        // Mise à jour si un ID existe
        this.invoiceItemVatService.update(this.vat.id, this.vat).subscribe(
          (updatedVat) => {
            this.saved.emit(updatedVat.getDto());
            console.log('VAT updated:', updatedVat);
          },
          (error) => console.error('Error updating VAT:', error)
        );
      } else {
        // Création si pas d'ID
        this.invoiceItemVatService.create(this.vat).subscribe(
          (savedVat) => {
            this.saved.emit(savedVat.getDto());
            console.log('VAT created:', savedVat);
          },
          (error) => console.error('Error creating VAT:', error)
        );
      }
    } else {
      console.error('Cannot save VAT: itemId is undefined');
    }
  }

  cancel(): void {
    this.cancelled.emit();
  }

  // Recalculer HT à partir du Total TTC
  updateAmountExcludingVAT(): void {
    if (this.vat.rateVAT && this.vat.amount) {
      const rate = this.vat.rateVAT / 100;
      this.vat.amountExcludingVAT = this.vat.amount / (1 + rate);
      this.vat.amountVAT = this.vat.amount - this.vat.amountExcludingVAT;
      console.log('Recalculated HT:', this.vat.amountExcludingVAT, 'VAT:', this.vat.amountVAT);
    }
  }

  // Recalculer Total TTC à partir du HT
  updateAmount(): void {
    if (this.vat.rateVAT && this.vat.amountExcludingVAT) {
      const rate = this.vat.rateVAT / 100;
      this.vat.amountVAT = this.vat.amountExcludingVAT * rate;
      this.vat.amount = this.vat.amountExcludingVAT + this.vat.amountVAT;
      console.log('Recalculated Total:', this.vat.amount, 'VAT:', this.vat.amountVAT);
    }
  }

  // Recalculer Total TTC à partir du taux de TVA
  updateAmountFromRate(): void {
    if (this.vat.rateVAT && this.vat.amountExcludingVAT) {
      const rate = this.vat.rateVAT / 100;
      this.vat.amountVAT = this.vat.amountExcludingVAT * rate;
      this.vat.amount = this.vat.amountExcludingVAT + this.vat.amountVAT;
      console.log('Recalculated Total from Rate:', this.vat.amount, 'VAT:', this.vat.amountVAT);
    }
  }
}