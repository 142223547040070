import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiService } from '../api/api.service';
import { ILoginRequestDto, ILoginResponseDto } from './login.dto';
import { ELocalStorageData } from './local-storage.enum';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private isLoggedInSubject: BehaviorSubject<boolean>;
  public isLoggedIn$: Observable<boolean>;

  private rolesSubject = new BehaviorSubject<string[]>(this.getRoleCodes());
  roles$: Observable<string[]> = this.rolesSubject.asObservable();

  private usernameSubject = new BehaviorSubject<string | null>(this.getUsername());
  public username$: Observable<string | null> = this.usernameSubject.asObservable();

  constructor(
    private jwtHelper: JwtHelperService,
    private apiService: ApiService,
  ) {
    this.isLoggedInSubject = new BehaviorSubject<boolean>(this.hasToken());
    this.isLoggedIn$ = this.isLoggedInSubject.asObservable();
    console.log('AuthService constructor');
  }

  handleLoginResponse(response: ILoginResponseDto) {
    console.log('handleLoginResponse:', response);
    localStorage.setItem(ELocalStorageData.token, response.token);
    this.isLoggedInSubject.next(true);
    localStorage.setItem(ELocalStorageData.roles, JSON.stringify(response.roleCodes));
    this.rolesSubject.next(response.roleCodes);
    localStorage.setItem(ELocalStorageData.username, response.username);
    this.usernameSubject.next(response.username);
  }

  public hasToken(): boolean {
    const token = localStorage.getItem(ELocalStorageData.token);
    const isTokenValid = token ? !this.jwtHelper.isTokenExpired(token) : false;
    console.log('hasToken:', isTokenValid);
    return isTokenValid;
  }

  public logout(): void {
    console.log('logoutUser');
    localStorage.removeItem(ELocalStorageData.token);
    localStorage.removeItem(ELocalStorageData.roles);
    localStorage.removeItem(ELocalStorageData.username);
    this.isLoggedInSubject.next(false);
    this.rolesSubject.next([]);
    this.usernameSubject.next(null);
  }

  public getRoleCodes(): string[] {
    const roleCodes = localStorage.getItem(ELocalStorageData.roles);
    const parsedRoleCodes = roleCodes ? JSON.parse(roleCodes) : [];
    console.log('getRoleCodes:', parsedRoleCodes);
    return parsedRoleCodes;
  }

  public getUsername(): string | null {
    const username = localStorage.getItem(ELocalStorageData.username);
    console.log('getUsername:', username);
    return username;
  }

  login(dto: ILoginRequestDto): Observable<ILoginResponseDto> {
    console.log('login:', dto);
    return this.apiService.post<ILoginResponseDto>(
      environment.api.url + environment.api.endpoint.login,
      dto
    );
  }
}
