<h1>Liste des Factures</h1>
<div>
  <plugin-invoice-picker
    (activated)="navigateToEdit($event)"
    (selected)="onInvoiceSelected($event)"
  ></plugin-invoice-picker>
</div>

<div class="global-actions">
  <button class="btn-add-invoice" (click)="addNewInvoice()">
    <img src="assets/btn/btn-new-invoice-256.png" alt="Créer une nouvelle facture">
    <span>Créer une nouvelle facture</span>
  </button>
</div>