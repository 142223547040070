import { Component, Input } from '@angular/core';
import { Lease } from '../../lease.class';
import { ILeaseDto } from '../../lease.dto';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'plugin-lease-data-view',
  standalone: true,
  imports: [
    CommonModule,
  ],
  templateUrl: './lease-data-view.plugin.html',
  styleUrl: './lease-data-view.plugin.scss'
})
export class LeaseDataViewPlugin {
  @Input()
  set leaseDto(value: ILeaseDto) {
    this.loadDto(value);
  }

  public lease: Lease = new Lease();
  loaded: boolean = false;

  constructor() {}

  loadDto(dto: ILeaseDto) {
    this.lease = new Lease(dto);
    this.loaded = true;
  }

}
