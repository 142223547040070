import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'widget-side-info',
  standalone: true,
  imports: [],
  templateUrl: './side-info.widget.html',
  styleUrl: './side-info.widget.scss'
})
export class SideInfoWidget {

}
