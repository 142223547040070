import { Injectable } from '@angular/core';
import { ApiService } from '../../../api/api.service';
import { environment } from '../../../../environments/environment';
import { catchError, Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class RentStatusService {

  constructor(
    private apiService: ApiService
  ) {}

  listNext(code: string, options: IRentStatusRequestOptions = {}): Observable<string[]> {
    // Ajout de log pour afficher le code et les options en entrée
    console.log('listNext appelé avec code:', code, 'et options:', options);
    
    // Construction des paramètres pour la requête HTTP
    const params = new HttpParams()
      .set('all', options.selectAll ? '1' : '0');
      
    const url: string = `${environment.api.url}${environment.api.endpoint.rentStatusNext}/${code}`;
    
    // Log pour confirmer l'URL générée
    console.log('URL générée:', url, 'avec paramètres:', params.toString());
    
    return this.apiService.get<string[]>(url, { params }).pipe(
      catchError(error => {
        console.error('Erreur lors de l\'appel à listNext:', error);
        return this.apiService.handleError(error);
      }),
    );
  }

  list() {
    const url:string = `${environment.api.url}${environment.api.endpoint.rentStatusList}`;
    return this.apiService.get<string[]>(url).pipe(
      catchError(this.apiService.handleError.bind(this)),
    );
  }

}

export interface IRentStatusRequestOptions {
  selectAll?: boolean,
}