import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AddressViewPlugin } from '../../plugin/view/address-view.plugin';
import { AddressEditPlugin } from '../../plugin/edit/address-edit.plugin';
import { AddressService, IAddressRequestOptions } from '../../address.service';
import { ActivatedRoute } from '@angular/router';
import { Address } from '../../address.class';

@Component({
  selector: 'route-address-edit',
  standalone: true,
  imports: [
    CommonModule,
    AddressViewPlugin,
    AddressEditPlugin,
  ],
  templateUrl: './address-edit.route.html',
  styleUrl: './address-edit.route.scss'
})
export class AddressEditRoute implements OnInit {
  public address: Address = new Address();
  public editMode: boolean = false; 

  constructor(
    private route: ActivatedRoute,
    private addressService: AddressService,
  ) {}

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      const id = +(params.get('id') || 0);
      console.log('ParamMap received:', id);
      this.loadData(id);
    });
  }

  loadData(id: number = 0) {
    if (id !== 0) {
      this.addressService.get(id).subscribe(a => {
        if (a) {
          this.address = a;
        }
      })
    }
  }

  toggleEditMode() {
    this.editMode = !this.editMode; 
  }

  saveAddress(addressDto: any) {
    console.log('Address saved:', addressDto);
    this.addressService.instanciate_deprecated(addressDto).subscribe(dto => {
      if (dto) this.address = new Address(dto);
    })
    this.toggleEditMode(); 
  }

  cancelEdit() {
    this.toggleEditMode(); 
  }
}
