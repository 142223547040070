import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ContactViewPlugin } from '../../plugin/view/contact-view.plugin';
import { Contact } from '../../contact.class';
import { ActivatedRoute } from '@angular/router';
import { ContactService, IContactRequestOptions } from '../../contact.service';
import { ContactEditPlugin } from '../../plugin/edit/contact-edit.plugin';
import { IContactDto } from '../../contact.dto';
import { OccupancyPickerPlugin } from '../../occupancy/plugin/picker/occupancy-picker.plugin';
import { IOccupancyRequestOptions, OccupancyService } from '../../occupancy/occupancy.service';
import { ContactPhoneService, IContactPhoneRequestOptions } from '../../phone/contact-phone.service';
import { ContactPhonePicker } from '../../phone/plugin/picker/contact-phone.picker';
import { ContactEmailPicker } from '../../email/plugin/picker/contact-email.picker';
import { ContactEmailService, IContactEmailRequestOptions } from '../../email/contact-email.service';
import { ContactEmailEditPlugin } from '../../email/plugin/edit/contact-email-edit.plugin';
import { ContactPhoneEditPlugin } from '../../phone/plugin/edit/contact-phone-edit.plugin';
import { IContactPhoneDto } from '../../phone/contact-phone.dto';
import { IContactEmailDto } from '../../email/contact-email.dto';
import { ContactPhone } from '../../phone/contact-phone.class';
import { ContactEmail } from '../../email/contact-email.class';
import { IOccupancyDto } from '../../occupancy/occupancy.dto';
import { Occupancy } from '../../occupancy/occupancy.class';
import { OccupancyEditPlugin } from '../../occupancy/plugin/edit/occupancy-edit.plugin';
import { Tag } from '../../../tag/tag.class';
import { NavService } from '../../../nav/nav.service';

@Component({
  selector: 'app-contact-edit',
  standalone: true,
  imports: [
    CommonModule,
    ContactViewPlugin,
    ContactEditPlugin,
    OccupancyPickerPlugin,
    ContactPhonePicker,
    ContactEmailPicker,
    ContactEmailEditPlugin,
    ContactPhoneEditPlugin,
    OccupancyPickerPlugin,
    OccupancyEditPlugin,
  ],
  templateUrl: './contact-edit.route.html',
  styleUrl: './contact-edit.route.scss'
})
export class ContactEditRoute implements OnInit {
  @ViewChild(ContactPhonePicker) phonePicker!: ContactPhonePicker;
  @ViewChild(ContactEmailPicker) emailPicker!: ContactEmailPicker;
  @ViewChild(OccupancyPickerPlugin) occupancyPicker!: OccupancyPickerPlugin;

  contact: Contact = new Contact();
  contactEditMode: boolean = false;
  contactPhoneEditMode: boolean = false;
  contactEmailEditMode: boolean = false;
  loaded: boolean = false;
  tagBU: Tag = new Tag();

  // pour l'édition d'un occupancy
  occupancyEditMode: boolean = false;
  occupancy: Occupancy = new Occupancy();

  public occupancyPickerOptions: IOccupancyRequestOptions = {};
  public contactPhoneOptions: IContactPhoneRequestOptions = {};
  public contactEmailOptions: IContactEmailRequestOptions = {};
  public occupancyEditOptions: IOccupancyRequestOptions = {};

  constructor(
    private route: ActivatedRoute,
    private contactService: ContactService,
    private contactPhoneService: ContactPhoneService,
    private contactEmailService: ContactEmailService,
    private occupancyService: OccupancyService,
    private navService: NavService,
  ) {}

  /**
   * l'activation de ce composant prépare le topic sélectionné pour contact (si sauvegarde)
   */
  ngOnInit() {
    console.log('Initialisation du composant ContactEditRoute');
    this.tagBU = this.navService.getTagBU();
    console.log(this.tagBU);

    if (!this.loaded) {
      this.route.paramMap.subscribe(params => {
        const contactId = +(params.get('idcontact') || 0);
        console.log('ParamMap reçu, contactId:', contactId);
        this.loadContact(contactId);
      });
    }
  }

  loadContact(id: number = 0) {
    console.log('Appel de loadData avec paramId:', id);
    if (id === 0) {
      this.contact = new Contact();
      this.contactEditMode = true;
      this.loaded = true;
    } else {
      const options: IContactRequestOptions = {
        loadTitle: true,
      };
      this.contactService.get(id, options).subscribe(c => {
        if (c) {
          console.log('Données du contact chargées:', c);
          this.contact = c;
        } else {
          console.log('Aucun contact trouvé, création d\'un nouveau contact');
          this.contact = new Contact();
        }
        this.contactEditMode = this.contact.isNew;
        this.contactEmailOptions = { selectIdPerson: this.contact.id };
        this.contactPhoneOptions = { selectIdPerson: this.contact.id };
        this.occupancyPickerOptions = { personIds: [this.contact.id], loadAddress: true,}
        this.loaded = true;
      });
    }
  }

  get contactOptions(): IContactRequestOptions {
    console.log('Obtention des options de contact');
    return {
      selectIds: [this.contact.id],
      showDelete: false,
      showEdit: true,
    }
  }

  get NewPhoneDto(): IContactPhoneDto {
    return (new ContactPhone()).getDto();
  };

  get NewEmailDto(): IContactEmailDto {
    return (new ContactEmail()).getDto();
  };

  toggleContactMode() {
    this.contactEditMode = !this.contactEditMode;
    console.log('Mode édition du contact:', this.contactEditMode);
  }

  togglePhoneEditMode() {
    this.contactPhoneEditMode = !this.contactPhoneEditMode;
    console.log('Mode édition du téléphone:', this.contactPhoneEditMode);
  }

  toggleEmailEditMode() {
    this.contactEmailEditMode = !this.contactEmailEditMode;
    console.log('Mode édition de l\'email:', this.contactEmailEditMode);
  }
  
  saveContact(dto: IContactDto): void {
    console.log('Sauvegarde du contact:', dto);
    this.contact = new Contact(dto);
    if (this.contact.isNew) {
      console.log('Création du contact');
      // ajout du tag BU
      if (this.tagBU.label !== '') {
        this.contact.addTag(this.tagBU);
      }
      this.contactService.create(this.contact).subscribe(c => {
        if (c) {
          console.log('Contact créé avec succès, ID:', c.id);
          this.loadContact(c.id);
        } else {
          console.log('Erreur lors de la création du contact');
          this.loadContact(0);
        }
      });
    } else {
      console.log('Mise à jour du contact');
      this.contactService.update(this.contact).subscribe(c => {
        if (c) {
          console.log('Contact mis à jour, ID:', c.id);
          this.loadContact(c.id);
        } else {
          console.log('Erreur lors de la mise à jour du contact');
          this.loadContact(0);
        }
      });
    }
    this.contactEditMode = false;
  }

  savePhone(dto: IContactPhoneDto): void {
    console.log('Sauvegarde du téléphone:', dto);
    const phone: ContactPhone = new ContactPhone(dto);
    phone.idContact = this.contact.id;
    this.contactPhoneService.create(phone).subscribe(p => {
      if (!p) {
        console.error('Erreur lors de la sauvegarde du téléphone');
      } else {
        console.log('Téléphone sauvegardé avec succès');
        this.togglePhoneEditMode();
        this.phonePicker.reloadData();
      }
    });
    this.contactPhoneEditMode = false;
  }

  saveEmail(dto: IContactEmailDto): void {
    console.log('Sauvegarde de l\'email:', dto);
    const email: ContactEmail = new ContactEmail(dto);
    email.idContact = this.contact.id;
    this.contactEmailService.create(email).subscribe( p => {
      if (!p) {
        console.error('Erreur lors de la sauvegarde de l\'email');
      } else {
        console.log('Email sauvegardé avec succès');
        this.toggleEmailEditMode();
        this.emailPicker.reloadData();
      }
    });
    this.contactEmailEditMode = false;
  }

  deleteEmail(dto: IContactEmailDto): void {
    console.log('Suppression de l\'email:', dto);
    const email: ContactEmail = new ContactEmail(dto);
    this.contactEmailService.delete(email.id).subscribe(() => {
      console.log('Email supprimé avec succès');
      this.emailPicker.reloadData();
    }, error => {
      console.error('Erreur lors de la suppression de l\'email', error);
    });
  }
  
  deletePhone(dto: IContactPhoneDto): void {
    console.log('Suppression du téléphone:', dto);
    const phone: ContactPhone = new ContactPhone(dto);
    this.contactPhoneService.delete(phone.id).subscribe(() => {
      console.log('Téléphone supprimé avec succès');
      this.phonePicker.reloadData();
    }, error => {
      console.error('Erreur lors de la suppression du téléphone', error);
    });
  }

  saveOccupancy(dto: IOccupancyDto): void {
    console.log('Sauvegarde de l\'adresse:', dto);
    const occupancy: Occupancy = new Occupancy(dto);
    // Logique pour sauvegarder l'occupancy via le service
    // Une fois sauvegardée, rechargez les données
    this.occupancyEditMode = false;
    this.loadContact(this.contact.id);  // Recharger les adresses après la sauvegarde
  }

  deleteOccupancy(dto: IOccupancyDto) {
    const occupancy: Occupancy = new Occupancy(dto);
    console.log(`Suppression de l'occupation avec l'ID ${occupancy.id}`);
    this.occupancyService.delete(occupancy.id, occupancy.idContact).subscribe(() => {
      this.occupancyPicker.reloadOptions();
    });
  }

  cancelOccupancyEdit(): void {
    console.log('Annulation de l\'édition de l\'adresse');
    this.occupancyEditMode = false;
  }

  addNewAddress() {
    this.occupancy = new Occupancy();
    this.occupancy.idContact = this.contact.id;
    this.occupancyEditOptions = {
      id: 0,
      personId: this.contact.id,
    }
    this.occupancyEditMode = true;
  }

  onOccupancyEditSaved(dto: IOccupancyDto) {
    this.occupancyEditMode = false;
  }

  onOccupancyEditCanceled() {
    console.log('occupancyEditCanceled');
    this.occupancyEditMode = false;
  }
}
