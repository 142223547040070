<div class="accommodation-edit-route">
    <!-- Mode vue -->
    <div *ngIf="!editMode">
      <plugin-accommodation-view 
        [accommodationDto]="accommodation.getDto()">
      </plugin-accommodation-view>
      <button class="btn-edit" (click)="toggleEditMode()">Modifier</button>
    </div>
  
    <!-- Mode édition -->
    <div *ngIf="editMode">
      <plugin-accommodation-edit 
        [accommodationDto]="accommodation.getDto()" 
        (onSave)="save($event)" 
        (onCancel)="cancel()">
      </plugin-accommodation-edit>
    </div>
  </div>
  