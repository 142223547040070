import { Component } from '@angular/core';

@Component({
  selector: 'route-rental-home',
  standalone: true,
  imports: [],
  templateUrl: './rental-home.route.html',
  styleUrl: './rental-home.route.scss'
})
export class RentalHomeRoute {

}
