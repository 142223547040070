import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { LabelService } from '../../../label.service';

@Component({
  selector: 'plugin-label-group-picker',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './label-group-picker.plugin.html',
  styleUrl: './label-group-picker.plugin.scss'
})
export class LabelGroupPickerPlugin implements OnInit {
  groups: string[] = [];
  selectedGroup: string | null = null;

  @Output() selectedGroupChange = new EventEmitter<string | null>();

  constructor(private labelService: LabelService) {}

  ngOnInit(): void {
    this.fetchGroups();
  }

  fetchGroups(): void {
    this.labelService.getGroups().subscribe({
      next: (groups) => {
        this.groups = groups;
      },
      error: (error) => {
        console.error('Error fetching groups:', error);
      }
    });
  }

  onGroupSelect(event: Event): void {
    const selectElement = event.target as HTMLSelectElement;
    const selectedGroup = selectElement.value || null;
    this.selectedGroupChange.emit(selectedGroup);
    console.log('Selected group:', selectedGroup);
  }
}