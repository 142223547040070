
import { BaseEntity } from "../../base-entity.class";
import { Address } from "../../wealth/address/address.class";
import { Contact } from "../contact.class";
import { IOccupancyDto } from "./occupancy.dto";

export class Occupancy extends BaseEntity<IOccupancyDto> {
    id: number = 0;
    idContact: number = 0;
    idAddress: number = 0;
    additionalInfo?: string;
    addressUsage?: string;
    dtStart?: Date | null;
    dtEnd?: Date | null;
    contact?: Contact;
    address?: Address;

    constructor(dto?: IOccupancyDto) {
        super(dto);
        if (dto) this.loadDto(dto);
    }

    protected override getMappings(): Partial<Record<keyof IOccupancyDto, keyof this>> {
        return {
            idOccupancy: 'id',
            idPerson: 'idContact',
            idAddress: 'idAddress',
            additionalInfo: 'additionalInfo',
            addressUsage: 'addressUsage',
            dtStart: 'dtStart',
            dtEnd: 'dtEnd',
        }
    }

    protected override setComplexProperties(dto: IOccupancyDto): void {
        if (dto.person) {
            this.contact = new Contact(dto.person);
        }
        if (dto.address) {
            this.address = new Address(dto.address);
        }
    }

    protected override getComplexProperties(dto: IOccupancyDto): void {
        if (this.address) {
            dto.address = this.address.getDto();
        }
        if (this.contact) {
            dto.person = this.contact.getDto();
        }
    }

    get isNew() {
        if (this.id || this.id === 0) {
            return true;
        }
        return false;
    }
}
