import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { TagBUComponent } from '../../../../model/tag-bu-component.class';
import { NavService } from '../../../../nav/nav.service';
import { IInvoiceHeaderDto } from '../../invoice-header.dto';
import { InvoiceHeader } from '../../invoice-header.class';
import { InvoicePickerPlugin } from '../../plugin/picker/invoice-picker.plugin';

@Component({
  selector: 'invoice-route-list',
  standalone: true,
  imports: [
    CommonModule,
    InvoicePickerPlugin,
  ],
  templateUrl: './invoice-list.route.html',
  styleUrl: './invoice-list.route.scss'
})
export class InvoiceListRoute extends TagBUComponent {
  selectedInvoiceId: number | undefined;

  constructor(
    private router: Router,
    navService: NavService,
  ) {
    super(navService);
  }

  navigateToEdit(invoiceHeaderDto: IInvoiceHeaderDto) {
    const invoiceHeader: InvoiceHeader = new InvoiceHeader(invoiceHeaderDto);
    this.router.navigate(['services/invoice/edit', invoiceHeader.invoiceId]);
  }

  onInvoiceSelected(invoiceHeaderDto: IInvoiceHeaderDto) {
    const invoiceHeader: InvoiceHeader = new InvoiceHeader(invoiceHeaderDto);
    this.selectedInvoiceId = invoiceHeader.invoiceId;
  }  

  addNewInvoice(): void {
    this.router.navigate(['services/invoice/edit', 0]);
  }  
}
