import { Injectable } from '@angular/core';
import { ApiService } from '../../api/api.service';
import { ContactEmail } from './contact-email.class';
import { HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { IContactEmailDto } from './contact-email.dto';
import { catchError, map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContactEmailService {

  constructor(
      private apiService: ApiService,
    ) {}
  
  // per  load person 0/1
  // idp  id person
  // ids liste des id de contact phones

  delete(id: number): Observable<void> {
    const url: string = `${environment.api.url}${environment.api.endpoint.contactEmailDelete}/${id}`;
    return this.apiService.delete<void>(url).pipe(
      catchError(this.apiService.handleError.bind(this))
    );
  }

  create(
    email: ContactEmail,
  ): Observable<ContactEmail | null> {
    const url: string = `${environment.api.url}${environment.api.endpoint.contactEmailCreate}`;
    return this.apiService.post<IContactEmailDto>(url, email.getDto()).pipe(
      map(dto => new ContactEmail(dto)),
      catchError(this.apiService.handleError.bind(this)) 
    );
  }

  list(
    options: IContactEmailRequestOptions,
  ): Observable<ContactEmail[] | null> {
    const url = `${environment.api.url}${environment.api.endpoint.contactEmailList}`;
    let params = new HttpParams()
      .set('per', options.loadPerson ? +options.loadPerson : 0);
    if (options.selectIds) {
      params = params.set('ids', options.selectIds.join(','));
    }
    if (options.selectIdPerson) {
      params = params.set('idp', options.selectIdPerson);
    }
    return this.apiService.get<IContactEmailDto[]>(url, { params }).pipe(
      map(dtos => dtos.map(dto => new ContactEmail(dto))),
      catchError(this.apiService.handleError.bind(this)),
    );
  }

  get(
    id: number, 
    options: IContactEmailRequestOptions = {},
  ): Observable<ContactEmail | null> {
    const url:string = `${environment.api.url}${environment.api.endpoint.contactEmailGet}/${id}`;
    let params: HttpParams = new HttpParams()
      .set('per', options.loadPerson ? 1 : 0);
    return this.apiService.get<IContactEmailDto>(url, { params }).pipe(
      map(dto => new ContactEmail(dto)), 
      catchError(this.apiService.handleError.bind(this)),
    )
  }
  
}

export interface IContactEmailRequestOptions{
  selectIds?: number[],
  selectIdPerson?: number,
  loadPerson?: boolean,
}
