import { BaseEntity } from "../../base-entity.class";
import { Contact } from "../contact.class";
import { IContactEmailDto } from "./contact-email.dto";

export class ContactEmail extends BaseEntity<IContactEmailDto> {
    id: number = 0;
    idContact: number = 0;
    emailType: string = '';
    emailAddress: string = '';
    isFavorite: boolean = false;
    contact: Contact = new Contact();

    constructor(dto?: IContactEmailDto) {
        super(dto);
        if (dto) this.loadDto(dto);
    }

    protected override getMappings(): Partial<Record<keyof IContactEmailDto, keyof this>> {
        return {
            id: 'id',
            idPerson: 'idContact',
            emailType: 'emailType',
            emailAddress: 'emailAddress',
            isFavorite: 'isFavorite',
        }
    }

    protected override loadComplexProperties(dto: IContactEmailDto): void {
        if (dto.person) {
            this.contact = new Contact(dto.person);
        }
    }

    protected override getComplexProperties(dto: IContactEmailDto): void {
        if (this.contact) {
            dto.person = this.contact.getDto();
        }
    }
}
