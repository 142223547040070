import { Component, OnInit } from '@angular/core';
import { INavItem } from '../nav.item';
import { Observable } from 'rxjs';
import { NavService } from '../nav.service';
import { AuthService } from '../../auth/auth.service';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'widget-sections',
  standalone: true,
  imports: [
    CommonModule,
  ],
  templateUrl: './sections.widget.html',
  styleUrl: './sections.widget.scss'
})
export class SectionsWidget implements OnInit {
  sections$: Observable<INavItem[]> | undefined;

  constructor(
    private navService: NavService, 
    private authService: AuthService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.navService.selectedTopicId$.subscribe(topicId => {
      this.authService.roles$.subscribe(roles => {
          if (topicId) {
              this.sections$ = this.navService.getFilteredSections(roles, topicId);
              // this.sections$.subscribe(sections => console.log('Loaded sections:', sections)); // Log sections
          }
      });
    });
  }

  onSectionSelect(section: INavItem) {
    this.router.navigate([section.route]);
  }
  
}
