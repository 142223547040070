import { Component, OnDestroy, OnInit } from '@angular/core';
import { Event, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from '../../auth.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'widget-profile-status',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './profile-status.widget.html',
  styleUrl: './profile-status.widget.scss'
})
export class ProfileStatusWidget implements OnInit, OnDestroy {
  isAuthenticated: boolean = false;
  username: string | null = null;
  private authSubscription: Subscription = new Subscription();

  ngOnInit() {
    // S'abonner à l'état d'authentification et du nom d'utilisateur
    this.authSubscription.add(
      this.authService.isLoggedIn$.subscribe(isLoggedIn => {
        this.isAuthenticated = isLoggedIn;
      })
    );

    this.authSubscription.add(
      this.authService.username$.subscribe(username => {
        this.username = username;
      })
    );
  }

  ngOnDestroy() {
    // Nettoyer les abonnements
    this.authSubscription.unsubscribe();
  }

  constructor(
    private router: Router,
    private authService: AuthService,
  ) {
    // Écouter les événements de navigation avec plus de détails
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        // console.log('Début de la navigation vers:', event.url);
      }

      if (event instanceof NavigationEnd) {
        // console.log('Navigation réussie vers:', event.url);
      }

      if (event instanceof NavigationCancel) {
        // console.warn('Navigation annulée vers:', event.url, event);
      }

      if (event instanceof NavigationError) {
        // console.error('Erreur de navigation vers:', event.url, event.error);
      }
    });
  }

  navigateToLogin() {
    // console.log('Tentative de navigation vers /login');
    this.router.navigate(['/login']).then(success => {
      if (success) {
        // console.log('Navigation réussie vers /login');
      } else {
        // console.error('Échec de la navigation vers /login');
      }
    });
  }

  navigateToProfile() {
    this.router.navigate(['/profile']).then(success => {
      if (success) {
        // console.log('Navigation réussie vers /profile');
      } else {
        // console.error('Échec de la navigation vers /profile');
      }
    });
  }

  logout() {
    this.authService.logout(); 
    
    // Réinitialiser l'état d'authentification
    this.isAuthenticated = false;

    // Rediriger vers la page de connexion après la déconnexion
    this.router.navigate(['/login']).then(success => {
      if (success) {
        // console.log('Navigation réussie vers /login après déconnexion');
      } else {
        // console.error('Échec de la navigation vers /login après déconnexion');
      }
    });
  }
}
