import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IUserRequestOptions } from '../../user.request';
import { IUserDto } from '../../user.dto';
import { User } from '../../user.class';
import { UserService } from '../../user.service';

@Component({
  selector: 'plugin-user-picker',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './user-picker.plugin.html',
  styleUrls: ['./user-picker.plugin.scss']
})
export class UserPickerPlugin {
  private _options: IUserRequestOptions = {};
  @Input()
  set options(value: IUserRequestOptions) {
    this._options = value;
    this.loadList();
  }
  get options(): IUserRequestOptions {
    return this._options;
  }

  // output
  @Output() selected: EventEmitter<IUserDto> = new EventEmitter();    // simple clic
  @Output() activated: EventEmitter<IUserDto> = new EventEmitter();   // double-click
  @Output() resetPwd: EventEmitter<IUserDto> = new EventEmitter();   
  @Output() userUpdate: EventEmitter<Partial<IUserDto>> = new EventEmitter();   

  list: User[] = [];
  _selectedItem: User = new User();
  set selectedItem(value: User) {
    this._selectedItem = value;
  }
  get selectedItem(): User {
    return this._selectedItem;
  }
  get selectedUsername(): string {
    return this.selectedItem.username;
  }
  
  constructor(
    private userService: UserService,
  ) {}

  loadList() {
    this.userService.list(this.options).subscribe(data => {
      if (data) {
        this.list = data;
      }
      console.log(data);
    })
    console.log(this.options);
  }

  selectUser(item: User) {
    this.selectedItem = item;
    this.selected.emit(this.selectedItem.getDto());
  }

  activateUser(item: User) {
    this.selectedItem = item;
    this.activated.emit(this.selectedItem.getDto());
  }

  toggleActive(event: Event, item: User) {
    event.preventDefault();
    this.selectedItem = item;
    this.selectedItem.isActive = !this.selectedItem.isActive;
    const userUpdateDto: Partial<IUserDto> = { 
      isActive: this.selectedItem.getDto().isActive,
    };
    this.userService.partialUpdate(this.selectedItem.username, userUpdateDto).subscribe(u => {
      if (u) {
        this.loadList();
      }
    })
  }

  viewUser(event: Event, item: User) {
    event.preventDefault();
    this.selectedItem = item;
    this.activateUser(this.selectedItem);
  }

  resetPassword(event: Event, item: User) {
    event.preventDefault();
    this.selectedItem = item;
    this.resetPwd.emit(this.selectedItem.getDto());
  }
}
