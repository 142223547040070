import { Injectable } from '@angular/core';
import { IUserRoleRequestOptions } from './userrole.request';
import { catchError, map, Observable, of, tap } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ApiService } from '../../api/api.service';
import { IUserDto } from '../user.dto';

@Injectable({
  providedIn: 'root'
})
export class UserRoleService {

  constructor(
    private apiService: ApiService,
  ) {}

  list(options: IUserRoleRequestOptions): Observable<string[] | null> {
    const url: string = `${environment.api.url}${environment.api.endpoint.userRoleCodes}/${options.username}/list/role`;

    let params = new HttpParams();
    if (options.available) {
      params = params.set('ava', options.available ? '1' : '0');
    }
    if (options.assigned) {
      params = params.set('ass', options.assigned ? '1' : '0');
    }

    return this.apiService.get<string[]>(url, { params }).pipe(
      map(response => response || null),
      catchError(this.apiService.handleError.bind(this))
    );
  }

  assignRole(options: IUserRoleRequestOptions): Observable<IUserDto | null> {
    const url: string = `${environment.api.url}${environment.api.endpoint.userAssignRole}/${options.username}/role/${options.roleCode}`;
    console.log('Request URL:', url);

    let params = new HttpParams();
    // Add any additional params here if necessary

    return this.apiService.put<IUserDto>(url, {}, { params }).pipe(
      map(response => {
        console.log('Response from server:', response);
        return response || null;
      }),
      catchError(this.apiService.handleError.bind(this))
    );
  }
  
  removeRole(options: IUserRoleRequestOptions): Observable<IUserDto | null> {
    const url: string = `${environment.api.url}${environment.api.endpoint.userRemoveRole}/${options.username}/role/${options.roleCode}`;
    let params = new HttpParams();
  
    return this.apiService.delete<IUserDto>(url, { params }).pipe(
      tap(response => {
        console.log('Role removed successfully:', response);
      }),
      catchError(error => {
        console.error('Error occurred while removing role:', error);
        return of(null); // Return null in case of error
      })
    );
  }
  
}
