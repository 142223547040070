<plugin-rent-edit [rentOperationDto]="rent.getDto()" (onCancel)="cancel()" (onUpdate)="update($event)"></plugin-rent-edit>
<plugin-status-view [operationDto]="rent.getDto()"></plugin-status-view>
<plugin-rent-status-next [statusCode]="rent.status || ''" (onChoose)="updateStatus($event)"></plugin-rent-status-next>
<plugin-occupancy-view [id]="rent.lease?.issuerOccupancyId || 0"></plugin-occupancy-view>
<plugin-rent-receipt-dl [idOperation]="rent.idOperation" (onPrint)="printReceipt($event)"></plugin-rent-receipt-dl>
<plugin-rent-receipt-gdrive [idOperation]="rent.idOperation" (onSendToGDrive)="sendToGDrive($event)"></plugin-rent-receipt-gdrive>
<div class="action-buttons">
    <button (click)="viewLease()">Voir le bail</button>
    <button (click)="deleteOperation()">Supprimer l'opération</button>
  </div>
