<div class="contact-view">
  <h2>Détails du contact</h2>

  <div *ngIf="contact.id">
    <div class="contact-field">
      <label>Titre :</label>
      <span>{{ contact.title?.longTitle || 'N/A' }}</span>
    </div>
    <div class="contact-field">
      <label>Prénom :</label>
      <span>{{ contact.firstName || 'N/A' }}</span>
    </div>
    <div class="contact-field">
      <label>Deuxième prénom :</label>
      <span>{{ contact.surname || 'N/A' }}</span>
    </div>
    <div class="contact-field">
      <label>Nom de famille :</label>
      <span>{{ contact.lastName || 'N/A' }}</span>
    </div>
    <div class="contact-field">
      <label>Entreprise :</label>
      <span>{{ contact.company || 'N/A' }}</span>
    </div>
    <div class="contact-field">
      <label>SIRET :</label>
      <span>{{ contact.siret || 'N/A' }}</span>
    </div>
  </div>

  <div class="action-buttons" *ngIf="showEdit || showDelete">
    <button *ngIf="showEdit" class="btn-edit" (click)="editContact()">Modifier</button>
    <button *ngIf="showDelete" class="btn-delete" (click)="deleteContact()">Supprimer</button>
  </div>
</div>
