<div class="invoice-edit">
  <h2>{{ invoice.invoiceId === 0 ? 'Créer une facture' : 'Éditer la facture' }}</h2>

  <form (ngSubmit)="saveInvoice()" #invoiceForm="ngForm">
    <div class="form-group">
      <label for="typeCode">Type de facture:</label>
      <select id="typeCode" [(ngModel)]="invoice.typeCode" name="typeCode" required>
        <option value="" disabled selected>Choisir un type</option>
        <option *ngFor="let type of invoiceTypes" [value]="type.code">
          {{ type.code }} - {{ type.labelLong || '' }}
        </option>
      </select>
    </div>

    <div class="form-group">
      <label for="idIssuer">Émetteur:</label>
      <plugin-contact-select 
        [(selectedContactId)]="invoice.idIssuer" 
        [buTagLabel]="tagBU?.label"
        name="idIssuer"
        (selectedContactIdChange)="onIssuerChange()">
      </plugin-contact-select>
    </div>

    <div class="form-group">
      <label for="idIssuerOccupancy">Occupation de l’émetteur:</label>
      <widget-occupancy-select 
        [personId]="invoice.idIssuer" 
        [(selectedOccupancyId)]="invoice.idIssuerOccupancy" 
        name="idIssuerOccupancy">
      </widget-occupancy-select>
    </div>

    <div class="form-group">
      <label for="idRecipient">Destinataire:</label>
      <plugin-contact-select 
        [(selectedContactId)]="invoice.idRecipient" 
        [buTagLabel]="tagBU?.label"
        name="idRecipient"
        (selectedContactIdChange)="onRecipientChange()">
      </plugin-contact-select>
    </div>

    <div class="form-group">
      <label for="idRecipientOccupancy">Occupation du destinataire:</label>
      <widget-occupancy-select 
        [personId]="invoice.idRecipient" 
        [(selectedOccupancyId)]="invoice.idRecipientOccupancy" 
        name="idRecipientOccupancy">
      </widget-occupancy-select>
    </div>

    <div class="form-group">
      <label for="dtStartLocale">Date de début:</label>
      <input type="date" id="dtStartLocale" [(ngModel)]="invoice.dtStartLocale" name="dtStartLocale" />
    </div>

    <div class="form-group">
      <label for="dtEndLocale">Date de fin:</label>
      <input type="date" id="dtEndLocale" [(ngModel)]="invoice.dtEndLocale" name="dtEndLocale" />
    </div>

    <div class="form-group">
      <label for="dtIssueLocale">Date d'émission:</label>
      <input type="date" id="dtIssueLocale" [(ngModel)]="invoice.dtIssueLocale" name="dtIssueLocale" required />
    </div>

    <div class="form-group">
      <label for="dtDueLocale">Date d'échéance:</label>
      <input type="date" id="dtDueLocale" [(ngModel)]="invoice.dtDueLocale" name="dtDueLocale" />
    </div>

    <div class="form-group">
      <label for="codePaymentTerms">Conditions de paiement:</label>
      <input type="text" id="codePaymentTerms" [(ngModel)]="invoice.codePaymentTerms" name="codePaymentTerms" />
    </div>

    <div class="form-group">
      <label for="internalRef">Référence interne:</label>
      <input type="text" id="internalRef" [(ngModel)]="invoice.internalRef" name="internalRef" />
    </div>

    <div class="form-group">
      <label for="externalRef">Référence externe:</label>
      <input type="text" id="externalRef" [(ngModel)]="invoice.externalRef" name="externalRef" />
    </div>
        
    <div class="form-group">
      <label for="codeStatus">Statut:</label>
      <input type="text" id="codeStatus" [(ngModel)]="invoice.codeStatus" name="codeStatus" />
    </div>

    <div class="form-group">
      <label for="total">Total:</label>
      <input type="number" id="total" [(ngModel)]="invoice.total" name="total" step="0.01" />
    </div>

    <div class="action-buttons">
      <button type="submit" class="btn-save">{{ invoice.invoiceId === 0 ? 'Créer' : 'Sauvegarder' }}</button>
      <button type="button" class="btn-cancel" (click)="cancel()">Annuler</button>
    </div>
  </form>
</div>