import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IAccommodationDto } from '../../accommodation.dto';
import { Accommodation } from '../../accommodation.class';
import { AccommodationService, IAccommodationRequestOptions } from '../../accommodation.service';

/**
 * plugin list accommodation
 * input : 
 * output : selected (idaccommodation)
 */
@Component({
  selector: 'plugin-accommodation-picker',
  templateUrl: './accommodation-picker.plugin.html',
  styleUrls: ['./accommodation-picker.plugin.scss'],
  standalone: true,
  imports: [
    CommonModule,
  ],
})
export class AccommodationPickerPlugin {
  private _options: IAccommodationRequestOptions = {};
  @Input() set options(value: IAccommodationRequestOptions) {
    this._options = value;
    this.loadData();
  }
  get options(): IAccommodationRequestOptions {
    return this._options;
  }

  @Output() onSelect = new EventEmitter<IAccommodationDto>();     
  @Output() onActivate = new EventEmitter<IAccommodationDto>();   
  @Output() onView = new EventEmitter<IAccommodationDto>();

  list: Accommodation[] = [];
  selected: Accommodation = new Accommodation();
  dataLoaded: boolean = false;

  constructor(
    private accommodationService: AccommodationService,
  ) {}

  loadData() {
    if (!this.dataLoaded) {
      this.accommodationService.list(this.options).subscribe(l => {
        this.list = l || [];
        this.dataLoaded = true;
      })
    }
  }

  click(dto: IAccommodationDto) {
    if (dto) {    
      this.selected = new Accommodation(dto);
      this.onSelect.emit(this.selected.getDto()); 
    }  
  }

  activate(dto: IAccommodationDto) {
    if (dto) {
      this.selected = new Accommodation(dto);
      this.onActivate.emit(this.selected.getDto());
    }
  }

  view(dto: IAccommodationDto) {
    if (dto) {
      this.selected = new Accommodation(dto);
      console.log('accommodation renvoyée', this.selected);
      this.onView.emit(this.selected.getDto());
    }
  }
}
