<div class="address-view">
    <h2>Détails de l'adresse</h2>
  
    <!-- Si une adresse est disponible -->
    <div *ngIf="address.idAddress">
      <p><strong>Numéro et rue :</strong> {{ address.streetNumber }} {{ address.streetType }} {{ address.streetName }}</p>
      <p><strong>Bâtiment/Localisation :</strong> {{ address.buildingLocation || 'N/A' }}</p>
      <p><strong>Service de livraison :</strong> {{ address.deliveryService || 'N/A' }}</p>
      <p><strong>Code postal :</strong> {{ address.postalCode }}</p>
      <p><strong>Ville :</strong> {{ address.city }}</p>
      <p><strong>Pays :</strong> {{ address.country }}</p>
      <p><strong>Fuseau horaire :</strong> {{ address.timezone || 'N/A' }}</p>
    </div>
  
    <!-- Si aucune adresse n'est disponible -->
    <p *ngIf="!address.idAddress">Aucune adresse disponible.</p>
  </div>
  