import { Component, Input, OnInit } from '@angular/core';
import { ContactService } from '../../contact.service';
import { Contact } from '../../contact.class';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'widget-contact-name-view',
  standalone: true,
  imports: [
    CommonModule,
  ],
  templateUrl: './contact-name-view.widget.html',
  styleUrl: './contact-name-view.widget.scss'
})
export class ContactNameViewPlugin implements OnInit {
  @Input() contactId: number | undefined;
  contact: Contact | undefined;

  constructor(private contactService: ContactService) {}

  ngOnInit(): void {
    if (this.contactId) {
      this.loadContact();
    }
  }

  private loadContact(): void {
    if (this.contactId) {
      this.contactService.get(this.contactId, { loadTitle: true }).subscribe(
        (contact: Contact | null) => {
          if (contact) {
            this.contact = contact;
            console.log('Contact loaded:', this.contact);
          } else {
            console.error('Contact not found for ID:', this.contactId);
          }
        },
        error => console.error('Error loading contact:', error)
      );
    }
  }

  getDisplayName(): string {
    if (!this.contact) return 'N/A';
    return this.contact.company || this.contact.formattedName() || 'Sans nom';
  }
}
