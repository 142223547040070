<div class="accommodation-view">
  <h2>Détails de l'hébergement</h2>

  <!-- Si un hébergement est disponible -->
  <div *ngIf="accommodation.id">
    <p><strong>Nom :</strong> {{ accommodation.name || 'N/A' }}</p>
    <p><strong>Numéro et rue :</strong> {{ accommodation.address?.streetNumber }} {{ accommodation.address?.streetName || 'N/A' }}</p>
    <p><strong>Bâtiment/Localisation :</strong> {{ accommodation.address?.buildingLocation || 'N/A' }}</p>
    <p><strong>Ville :</strong> {{ accommodation.address?.city || 'N/A' }}</p>
    <p><strong>Code postal :</strong> {{ accommodation.address?.postalCode || 'N/A' }}</p>
    <p><strong>Pays :</strong> {{ accommodation.address?.country || 'N/A' }}</p>
    <p><strong>Parties exclues :</strong> {{ accommodation.excludedParts || 'N/A' }}</p>
    <p><strong>Description complète :</strong> {{ accommodation.fullDescription || 'N/A' }}</p>
    <p><strong>Derniers travaux :</strong> {{ accommodation.recentWorkDescription || 'N/A' }}</p>
    <p><strong>Loyer de référence :</strong> {{ accommodation.rentByMonthReference || 'N/A' }} €/mois</p>
    <p><strong>Frais mensuels :</strong> {{ accommodation.feesByMonthReference || 'N/A' }} €/mois</p>
    <p><strong>Fuseau horaire :</strong> {{ accommodation.timezone || 'N/A' }}</p>
  </div>

  <!-- Si aucun hébergement n'est disponible -->
  <p *ngIf="!accommodation.id">Aucun hébergement disponible.</p>
</div>
