<div class="invoice-edit-container">
  <div class="invoice-header">
    <h2>Facture {{ invoiceId }}</h2>
    <div *ngIf="invoice; else noInvoice">
      <div *ngIf="!isEditMode">
        <plugin-invoice-view 
          [invoiceDto]="invoice.getDto()"
        ></plugin-invoice-view>
        <button (click)="toggleEditMode()" class="btn-modify">Modifier</button>
        <button (click)="downloadInvoice()" class="btn-download" [disabled]="isDownloading">
          {{ isDownloading ? 'Téléchargement...' : 'Télécharger' }}
        </button>
      </div>
      <div *ngIf="isEditMode">
        <plugin-invoice-edit 
          [invoiceId]="invoiceId" 
          [tagBUDto]="tagBU.getDto()"
          (saved)="onInvoiceSaved($event)"
          (onCancel)="toggleEditMode()">
        </plugin-invoice-edit>
      </div>
    </div>
    <ng-template #noInvoice>
      <p>Facture non trouvée ou ID manquant.</p>
    </ng-template>
  </div>

  <div class="invoice-items">
    <plugin-invoice-item-picker
      [invoiceId]="invoiceId"
      (itemSelected)="editItem($event)"
    ></plugin-invoice-item-picker>
    <button (click)="createNewItem()" class="btn-create-item">Nouvel élément</button>
    <div *ngIf="isCreatingNewItem || isEditingItem">
      <plugin-invoice-item-edit 
        [itemDto]="isCreatingNewItem ? getNewItemDto() : (selectedItem || getNewItemDto())" 
        (saved)="onNewItemSaved($event)" 
        (deleted)="cancelNewItem()"
        (totalsUpdated)="onTotalsUpdated($event)"
      ></plugin-invoice-item-edit>
    </div>
  </div>
</div>