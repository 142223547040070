import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InvoiceHeader } from '../../invoice-header.class';
import { IInvoiceHeaderDto } from '../../invoice-header.dto';
import { IInvoiceHeaderRequestOptions, InvoiceService } from '../../invoice-header.service';

@Component({
  selector: 'plugin-invoice-picker',
  standalone: true,
  imports: [
    CommonModule,
  ],
  templateUrl: './invoice-picker.plugin.html',
  styleUrl: './invoice-picker.plugin.scss'
})
export class InvoicePickerPlugin implements OnInit {
  invoices: InvoiceHeader[] = [];
  selectedInvoice: InvoiceHeader | null = null; // Pour garder une trace de la facture sélectionnée

  @Output() selected = new EventEmitter<IInvoiceHeaderDto>()
  @Output() activated = new EventEmitter<IInvoiceHeaderDto>()

  constructor(private invoiceService: InvoiceService) {}

  ngOnInit(): void {
    this.loadInvoices();
  }

  private loadInvoices(): void {
    const options: IInvoiceHeaderRequestOptions = {
      loadIssuer: true,
      loadRecipient: true,
    };
    this.invoiceService.list(options).subscribe(
      (invoices) => {
        this.invoices = invoices;
      },
      (error) => {
        console.error('Error loading invoices:', error);
      }
    );
  }

  onSelect(invoice: InvoiceHeader): void {
    this.selectedInvoice = invoice;
    this.selected.emit(invoice.getDto());
  }

  onActivate(invoice: InvoiceHeader): void {
    this.activated.emit(invoice.getDto());
  }
}