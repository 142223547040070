import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Occupancy } from '../../occupancy.class';
import { OccupancyService } from '../../occupancy.service';

@Component({
  selector: 'widget-occupancy-short-view',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './occupancy-short-view.widget.html',
  styleUrl: './occupancy-short-view.widget.scss'
})
export class OccupancyShortViewWidget implements OnInit {
  @Input() occupancyId: number | undefined; // Entrée pour l'ID d'occupation
  occupancy: Occupancy | undefined;

  constructor(private occupancyService: OccupancyService) {}

  ngOnInit(): void {
    if (this.occupancyId) {
      this.loadOccupancy();
    }
  }  

  private loadOccupancy(): void {
    if (this.occupancyId) {
      this.occupancyService.get(this.occupancyId, { loadPerson: true, loadAddress: true }).subscribe(
        (occupancy: Occupancy | null) => {
          if (occupancy) {
            this.occupancy = occupancy;
            console.log('Occupancy loaded:', this.occupancy);
          } else {
            console.error('Occupancy not found for ID:', this.occupancyId);
          }
        },
        error => console.error('Error loading occupancy:', error)
      );
    }
  }

  getDisplayText(): string {
    if (!this.occupancy || !this.occupancy.contact) return 'N/A';
    const name = this.occupancy.contact.company || this.occupancy.contact.formattedName() || 'Sans nom';
    const usage = this.occupancy.addressUsage || 'Usage non spécifié';
    return `${name} (${usage})`;
  }

  getAddressText(): string {
    if (!this.occupancy || !this.occupancy.address) return 'Adresse non disponible';
    return this.occupancy.address.formattedAddress(); // Utilisation de la méthode formattedAddress()
  }
}
