<div class="container login-container">
    <div class="login-form">
      <h2>Connexion</h2>
      <form [formGroup]="loginForm" (ngSubmit)="onSubmit()"> 
        <div class="form-group">
          <label for="username">Identifiant</label>
          <input type="text" id="username" class="form-control" formControlName="username" placeholder="Entrez votre identifiant" required>
        </div>
        <div class="form-group">
          <label for="password">Mot de passe</label>
          <input type="password" id="password" class="form-control" formControlName="password" placeholder="Entrez votre mot de passe" required>
        </div>
        <button type="submit" class="btn btn-primary w-100">Se connecter</button>
      </form>
    </div>
  </div>
  