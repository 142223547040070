<div class="lease-data-view">
    <h2>Détails du Bail</h2>
  
    <!-- Affichage des informations du bail -->
    <div *ngIf="lease.id">
      <p><strong>ID du bail :</strong> {{ lease.id }}</p>
      <p><strong>Status :</strong> {{ lease.status }}</p>
      <p><strong>Date de début :</strong> {{ lease.startLocale?.date | date }}</p>
      <p><strong>Date de fin :</strong> {{ lease.endLocale?.date | date }}</p>
      <p><strong>Loyer mensuel négocié :</strong> {{ lease.rentByMonthNegociated || 'N/A' }} €/mois</p>
      <p><strong>Frais mensuels négociés :</strong> {{ lease.feesByMonthNegociated || 'N/A' }} €/mois</p>
      <p><strong>Loyer mensuel listé :</strong> {{ lease.rentByMonthListed || 'N/A' }} €/mois</p>
      <p><strong>Frais mensuels listés :</strong> {{ lease.feesByMonthListed || 'N/A' }} €/mois</p>
      <p><strong>Statut de résidence principale :</strong> {{ lease.primaryResidence ? 'Oui' : 'Non' }}</p>
      <p><strong>Bail étudiant :</strong> {{ lease.studentLease ? 'Oui' : 'Non' }}</p>
      <p><strong>Renouvellement automatique :</strong> {{ lease.automaticRenewal ? 'Oui' : 'Non' }}</p>
      <p><strong>Fuseau horaire :</strong> {{ lease.timezone || 'N/A' }}</p>
    </div>
  
    <!-- Si aucun bail n'est disponible -->
    <p *ngIf="!lease.id">Aucune information de bail disponible.</p>
  </div>
  