import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { IContactPhoneDto } from '../../contact-phone.dto';
import { ContactPhone } from '../../contact-phone.class';

@Component({
  selector: 'plugin-contact-phone-edit',
  standalone: true,
  imports: [
    CommonModule, 
    ReactiveFormsModule
  ],
  templateUrl: './contact-phone-edit.plugin.html',
  styleUrls: ['./contact-phone-edit.plugin.scss']
})
export class ContactPhoneEditPlugin implements OnInit {
  @Input() phoneDto: IContactPhoneDto | null = null;  // Utilisation directe du DTO en entrée
  @Output() submitted: EventEmitter<IContactPhoneDto> = new EventEmitter();
  @Output() canceled: EventEmitter<void> = new EventEmitter();

  phoneForm: FormGroup;

  constructor(
    private fb: FormBuilder,
  ) {
    // Initialisation du formulaire avec des valeurs par défaut
    this.phoneForm = this.fb.group({
      phoneType: [''],
      phoneNumber: [''],
      isFavorite: [false],
    });
  }

  ngOnInit(): void {
    this.loadPhoneData();  // Charger les données du téléphone dès l'initialisation
  }

  loadPhoneData(): void {
    if (this.phoneDto) {
      // Si un DTO est fourni, on met à jour le formulaire avec ses valeurs
      this.phoneForm.patchValue({
        phoneType: this.phoneDto.phoneType,
        phoneNumber: this.phoneDto.phoneNumber,
        isFavorite: this.phoneDto.isFavorite,
      });
    }
  }

  save(): void {
    if (this.phoneDto) {
      // Mettre à jour le DTO avec les valeurs du formulaire
      const updatedPhone: IContactPhoneDto = {
        ...this.phoneDto,
        phoneType: this.phoneForm.get('phoneType')?.value,
        phoneNumber: this.phoneForm.get('phoneNumber')?.value,
        isFavorite: this.phoneForm.get('isFavorite')?.value,
      };

      console.log('save', updatedPhone);
      this.submitted.emit(updatedPhone);  // Émettre le DTO mis à jour
    }
  }

  cancel(): void {
    this.loadPhoneData();  // Réinitialiser le formulaire aux valeurs initiales
    this.canceled.emit();  // Émettre un événement d'annulation
  }
}
