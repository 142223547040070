import { BaseEntity } from "../../base-entity.class";
import { Contact } from "../contact.class";
import { IContactPhoneDto } from "./contact-phone.dto";

export class ContactPhone extends BaseEntity<IContactPhoneDto> {
    id: number = 0;
    idContact: number = 0;
    phoneType: string = '';
    phoneNumber: string = '';
    isFavorite: boolean = false;
    contact: Contact = new Contact();

    constructor(dto?: IContactPhoneDto) {
        super(dto);
        if (dto) this.loadDto(dto);
    }

    protected override getMappings(): Partial<Record<keyof IContactPhoneDto, keyof this>> {
        return {
            id: 'id',
            idPerson: 'idContact',
            phoneType: 'phoneType',
            phoneNumber: 'phoneNumber',
            isFavorite: 'isFavorite',
        }
    }

    protected override setComplexProperties(dto: IContactPhoneDto): void {
        if (dto.person) {
            this.contact = new Contact(dto.person);
        }
    }

    protected override getComplexProperties(dto: IContactPhoneDto): void {
        if (this.contact) {
            dto.person = this.contact.getDto();
        }
    }
}
