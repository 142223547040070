<div class="status-dropdown">
    <label for="statusSelect">Sélectionner le statut suivant :</label>
    <select id="statusSelect" (change)="updateSelectedStatus($event)">
      <option *ngFor="let status of list" [value]="status" [selected]="status === selectedStatus">
        {{ status }}
      </option>
    </select>
    
    <!-- Case à cocher pour voir tous les statuts -->
    <label>
      <input type="checkbox" (change)="toggleShowAllStatuses()" [checked]="showAllStatuses" />
      Voir tous les statuts
    </label>
  
    <!-- Bouton pour changer l'état -->
    <button class="btn-change-status" (click)="chooseStatus()">Changer l'état</button>
  </div>
  