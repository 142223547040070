<div class="contact-edit">
  <!-- Message de chargement -->
  <div *ngIf="!loaded" class="loading-message">
    Chargement en cours...
  </div>

  <!-- Contenu principal affiché quand loaded est true -->
  <ng-container *ngIf="loaded">
    <!-- Contact Details or Edit Mode -->
    <div *ngIf="!contactEditMode">
      <plugin-contact-view [contactDto]="contact.getDto()" (edit)="toggleContactMode()"></plugin-contact-view>
      <button (click)="toggleContactMode()">Modifier</button>
    </div>
    <div *ngIf="contactEditMode">
      <plugin-contact-edit [options]="contactOptions" (onSave)="saveContact($event)" (onCancel)="toggleContactMode()"></plugin-contact-edit>
    </div>

    <!-- Occupancy Picker Section -->
    <div class="occupancy-section">
      <h3>Adresses associées</h3>
      
      <!-- Afficher le plugin-occupancy-picker si occupancyEditMode est false -->
      <ng-container *ngIf="!occupancyEditMode">
        <plugin-occupancy-picker [options]="occupancyPickerOptions"></plugin-occupancy-picker>
        <button (click)="addNewAddress()" class="btn-add-address">Ajouter une adresse</button>
      </ng-container>

      <!-- Afficher le plugin-occupancy-edit si occupancyEditMode est true -->
      <ng-container *ngIf="occupancyEditMode">
        <plugin-occupancy-edit [options]="occupancyEditOptions" (onSaved)="occupancyEditSaved($event)" (onCanceled)="occupancyEditCanceled()"></plugin-occupancy-edit>
      </ng-container>
    </div>

    <!-- Phone Picker, displayed only if the contact is not new -->
    <div *ngIf="!contact.isNew" class="phone-section">
      <h3>Numéros de Téléphone</h3>
      <plugin-contact-phone-picker [options]="contactPhoneOptions" (onDelete)="deletePhone($event)"></plugin-contact-phone-picker>
      
      <!-- Hide the button when editing a phone -->
      <button *ngIf="!contactPhoneEditMode" (click)="togglePhoneEditMode()">Nouveau Téléphone</button>

      <!-- Phone Edit Plugin -->
      <div *ngIf="contactPhoneEditMode">
        <plugin-contact-phone-edit [phoneDto]="NewPhoneDto" (submitted)="savePhone($event)" (canceled)="togglePhoneEditMode()"></plugin-contact-phone-edit>
      </div>
    </div>

    <!-- Email Picker, displayed only if the contact is not new -->
    <div *ngIf="!contact.isNew" class="email-section">
      <h3>Emails</h3>
      <plugin-contact-email-picker [options]="contactEmailOptions" (onDelete)="deleteEmail($event)"></plugin-contact-email-picker>

      <!-- Hide the button when editing an email -->
      <button *ngIf="!contactEmailEditMode" (click)="toggleEmailEditMode()">Nouvel Email</button>

      <!-- Email Edit Plugin -->
      <div *ngIf="contactEmailEditMode">
        <plugin-contact-email-edit [emailDto]="NewEmailDto" (onSubmit)="saveEmail($event)" (onCancel)="toggleEmailEditMode()"></plugin-contact-email-edit>
      </div>
    </div>
  </ng-container>
</div>
