import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserPickerPlugin } from "../../plugin/picker/user-picker.plugin";
import { Router } from '@angular/router';
import { IUserRequestOptions } from '../../user.request';
import { UserService } from '../../user.service';
import { IUserDto } from '../../user.dto';
import { User } from '../../user.class';

@Component({
  standalone: true,
  imports: [
    CommonModule, 
    UserPickerPlugin,
  ],
  templateUrl: './user-list.route.html',
  styleUrls: ['./user-list.route.scss']
})
export class UserListRoute {
  options: IUserRequestOptions = {};

  constructor(
    private router: Router,
    private userService: UserService,
  ) { 
    console.log('UserListRoute component initialized');
  }

  editUser(dto: IUserDto) {
    const user: User = new User(dto);
    console.log('editUser called with user:', user);
    console.log('Navigating to /user/edit with username:', user.username);
    this.router.navigate(['/user/edit', user.username], { queryParams: { m: 0 } })
      .then(success => console.log('Navigation success:', success))
      .catch(error => console.error('Navigation error:', error));
  }

  newPassword(dto: IUserDto) {
    const user: User = new User(dto);
    console.log('newPassword called with user:', user);
    console.log('Navigating to /user/password with username:', user.username);
    this.router.navigate(['/user/password', user.username])
      .then(success => console.log('Navigation success:', success))
      .catch(error => console.error('Navigation error:', error));
  }
}
