import { Injectable } from '@angular/core';
import { ApiService } from '../../api/api.service';
import { catchError, map, Observable, of, throwError } from 'rxjs';
import { IInvoiceHeaderDto } from './invoice-header.dto';
import { HttpParams } from '@angular/common/http';
import { InvoiceHeader } from './invoice-header.class';
import { environment } from '../../../environments/environment';
import { saveAs } from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {

  constructor(
    private apiService: ApiService,
  ) {}

  // Méthode pour télécharger la facture
  downloadInvoice(invoiceId: number): Observable<boolean> {
    const url: string = `${environment.api.url}${environment.api.endpoint.invoicePdf}/${invoiceId}`;
    return this.apiService.getBlob(url).pipe(
      map((blob: Blob) => {
          const fileName = `facture_${invoiceId}.pdf`;
          saveAs(blob, fileName);
          console.log('Quittance téléchargée sous le nom de fichier :', fileName);
          return true; // Succès
      }),
      catchError((error) => {
          console.error('Erreur lors du téléchargement de la quittance :', error);
          return of(false); // Échec
      })
    );
  }

  list(
    options: IInvoiceHeaderRequestOptions = {}
  ): Observable<InvoiceHeader[]> {
    const url: string = `${environment.api.url}${environment.api.endpoint.invoiceList}`;
    let params = new HttpParams()
      .set('iss', options.loadIssuer ? +options.loadIssuer : 0)
      .set('rec', options.loadRecipient ? +options.loadRecipient : 0);
    return this.apiService.get<IInvoiceHeaderDto[]>(url, { params }).pipe(
      map(l => {
        return l.map(d => {
          const invoice = new InvoiceHeader(d);
          return invoice;
        });
      }),
      catchError((error) => {
        console.error('Error fetching contact list:', error);
        return throwError(error);
      })
    );
  }

  get(
    id: number, 
    options: IInvoiceHeaderRequestOptions = {},
  ): Observable<InvoiceHeader | null> {
    const url:string = `${environment.api.url}${environment.api.endpoint.invoiceGet}/${id}`;
    let params: HttpParams = new HttpParams()
    .set('iss', options.loadIssuer ? +options.loadIssuer : 0)
    .set('rec', options.loadRecipient ? +options.loadRecipient : 0);
  return this.apiService.get<IInvoiceHeaderDto>(url, { params }).pipe(
      map(dto => new InvoiceHeader(dto))
    )
  }    

  /**
   * Création d'une entête de facture
   * Le Tag BU doit être ajouté
   * @param invoice 
   * @returns 
   */
  create(invoice: InvoiceHeader): Observable<InvoiceHeader> {

    const url: string = `${environment.api.url}${environment.api.endpoint.invoiceCreate}`;
    return this.apiService.post<IInvoiceHeaderDto>(url, invoice.getDto()).pipe(
      map(dto => new InvoiceHeader(dto)),
      catchError((error) => {
        console.error('Error creating invoice:', error);
        return throwError(error);
      })
    );
  }

  update(id: number, invoice: InvoiceHeader): Observable<InvoiceHeader> {
    const url: string = `${environment.api.url}${environment.api.endpoint.invoiceUpdate}/${id}`;
    console.log('Updating invoice with ID:', id);
    console.log('Invoice object before conversion:', invoice);
    
    if (typeof invoice.getDto === 'function') {
      console.log('getDto is a function');
    } else {
      console.error('getDto is not a function on invoice object');
    }
  
    const dto = invoice.getDto();
    console.log('DTO after conversion:', dto);
  
    return this.apiService.put<IInvoiceHeaderDto>(url, dto).pipe(
      map(returnedDto => {
        console.log('DTO received from API after update:', returnedDto);
        const updatedInvoice = new InvoiceHeader(returnedDto);
        console.log('Updated Invoice object:', updatedInvoice);
        return updatedInvoice;
      }),
      catchError((error) => {
        console.error('Error updating invoice:', error);
        return throwError(error);
      })
    );
  }
}

export interface IInvoiceHeaderRequestOptions {
  loadIssuer?: boolean,
  loadRecipient?: boolean,
}