import { Contact } from "../../contact/contact.class";
import { Occupancy } from "../../contact/occupancy/occupancy.class";
import { Operation } from "../../operation/operation.class";
import { ILocaleDate, LocaleDate } from "../../time/locale";
import { Accommodation } from "../../wealth/accommodation/accommodation.class";
import { ILeaseDto } from "./lease.dto";
import { ELeaseStatus } from "./leasestatus.enum";


export class Lease {
    id?: number;
    status: string;
    idAccommodation?: number;
    studentLease?: boolean; 
    primaryResidence?: boolean;
    automaticRenewal?: boolean;
    startLocale?: ILocaleDate;
    endLocale?: ILocaleDate;
    timezone?: string;

    issuerOccupancyId?: number;
    addresseeOccupancyId?: number;

    rentByMonthNegociated?: number | null;
    feesByMonthNegociated?: number | null;    
    rentByMonthListed?: number | null;
    feesByMonthListed?: number | null;

    accommodation?: Accommodation;
    lessors?: Contact[];
    tenants?: Contact[];
    addressee?: Occupancy;
    issuer?: Occupancy;
    operations?: Operation[];

    constructor(dto?: ILeaseDto) {
        this.status = ELeaseStatus.NEW;
        if (dto) this.loadDto(dto);
    }

    get isNew(): boolean {
        return !this.id || this.id === 0;
    }

    public getDto(): ILeaseDto {
        const dto: ILeaseDto = {};
    
        const mappings: Partial<Record<keyof Lease, keyof ILeaseDto>> = {
            id: 'idLease',
            status: 'status',
            idAccommodation: 'idAccommodation',
            studentLease: 'studentLease',
            primaryResidence: 'primaryResidence',
            automaticRenewal: 'automaticRenewal',
            startLocale: 'startLocale',
            endLocale: 'endLocale',
            timezone: 'timezone',

            issuerOccupancyId: 'issuerOccupancyId',
            addresseeOccupancyId: 'receiptAddresseeId',

            rentByMonthNegociated: 'rentByMonthNegociated',
            feesByMonthNegociated: 'feesByMonthNegociated',
            rentByMonthListed: 'rentByMonthListed',
            feesByMonthListed: 'feesByMonthListed',
        };
    
        // console.log('lease class getDto avant mapping', this);
        (Object.keys(mappings) as (keyof Lease)[]).forEach(leaseKey => {
            const dtoKey = mappings[leaseKey];
            if (this[leaseKey] !== undefined && dtoKey !== undefined) {
                dto[dtoKey] = this[leaseKey] as any;
            }
        });
        // Traitement spécifique pour les objets complexes
        if (this.accommodation) dto.accommodation = this.accommodation.getDto();
        if (this.lessors) dto.lessors = this.lessors.map(l => l.getDto());
        if (this.tenants) dto.tenants = this.tenants.map(t => t.getDto());
        if (this.issuer) dto.issuer = this.issuer.getDto();
        if (this.addressee) dto.addressee = this.addressee.getDto();
        // IOperationDto[] => devrait donner des collections par type

        return dto;
    }

    loadDto(dto: ILeaseDto) {
        // console.log('chargement lease', dto);
        const mappings: Partial<Record<keyof ILeaseDto, keyof Lease>> = {
            idLease: 'id',
            status: 'status',
            idAccommodation: 'idAccommodation',
            studentLease: 'studentLease',
            primaryResidence: 'primaryResidence',
            automaticRenewal: 'automaticRenewal',
            startLocale: 'startLocale',
            endLocale: 'endLocale',
            timezone: 'timezone',

            issuerOccupancyId: 'issuerOccupancyId',
            receiptAddresseeId: 'addresseeOccupancyId',

            rentByMonthNegociated: 'rentByMonthNegociated',
            feesByMonthNegociated: 'feesByMonthNegociated',
            rentByMonthListed: 'rentByMonthListed',
            feesByMonthListed: 'feesByMonthListed',
        };

        (Object.keys(mappings) as (keyof ILeaseDto)[]).forEach(dtoKey => {
            const leaseKey = mappings[dtoKey];
            if (dto[dtoKey] !== undefined && leaseKey !== undefined) {
                (this[leaseKey] as any) = dto[dtoKey];
            }
        });
        
        // Traitement spécifique pour les objets complexes
        if (dto.accommodation) {
            this.accommodation = new Accommodation(dto.accommodation);
        }
        if (dto.lessors) {
            this.lessors = dto.lessors.map(l => new Contact(l));
        }
        if (dto.tenants) {
            this.tenants = dto.tenants.map(t => new Contact(t));
        }
    }

    mergeChangesFromLease(updatedLease: Lease): void {
        // fusion de id
        if (!this.id || this.id === 0) {
            this.id = updatedLease.id;
        }

        // Fusionne les propriétés primitives
        const fieldsToUpdate: (keyof Lease)[] = [
          'status', 'idAccommodation', 'studentLease', 'primaryResidence',
          'automaticRenewal', 'startLocale', 'endLocale', 'timezone',
          'issuerOccupancyId', 'addresseeOccupancyId',
          'rentByMonthNegociated', 'feesByMonthNegociated', 
          'rentByMonthListed', 'feesByMonthListed'
        ];
      
        fieldsToUpdate.forEach(field => {
          if (updatedLease[field] !== undefined) {
            (this[field] as any) = updatedLease[field];
          }
        });
      
        // Fusionne l'accommodation
        if (updatedLease.accommodation) {
          if (!this.accommodation) {
            this.accommodation = new Accommodation();
          }
          this.accommodation.loadDto(updatedLease.accommodation.getDto());
        }
      
        // Fusionne les lessors (bailleurs)
        if (updatedLease.lessors) {
          this.lessors = updatedLease.lessors.map(lessor => new Contact(lessor.getDto()));
        }
      
        // Fusionne les tenants (locataires)
        if (updatedLease.tenants) {
          this.tenants = updatedLease.tenants.map(tenant => new Contact(tenant.getDto()));
        }
      
        // Fusionne l'addressee et l'issuer (occupancies)
        if (updatedLease.addressee) {
          this.addressee = new Occupancy(updatedLease.addressee.getDto());
        }
      
        if (updatedLease.issuer) {
          this.issuer = new Occupancy(updatedLease.issuer.getDto());
        }
      
        // Les autres opérations complexes peuvent être ajoutées ici si nécessaire
    }
      
    // getLessorsIds : tableau des ids des lessors (bailleurs)

    // getTenantsIds : tableau des ids des tenants (locataires)
    public getTenantsIds(): number[] {
        if (!this.tenants || this.tenants.length === 0) {
            return [];
        };
        return this.tenants
            .map(tenant => tenant.id)
            .filter((id): id is number => id !== undefined);
    }
    // getLessorsIds : tableau des ids des lessors (bailleurs)
    public getLessorsIds(): number[] {
        if (!this.lessors || this.lessors.length === 0) {
            return [];
        };
        const ids: number[] = this.lessors
            .map(lessor => lessor.id)
            .filter((id): id is number => id !== undefined);
        console.log('Lessors IDs retrieved:', ids);
        return ids;
    }

    get startDate(): string {
        return this.startLocale?.date || '';
    }
    set startDate(value: string) {
        if (!this.startLocale) {
            this.startLocale = { date: value, timezone: this.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone };
        } else {
            this.startLocale.date = value;
        }
    }

    get endDate(): string {
        return this.endLocale?.date || '';
    }
    set endDate(value: string) {
        // Assurez-vous que `endLocale` est initialisé avec une date valide
        if (!this.endLocale) {
            this.endLocale = { date: value, timezone: this.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone };
        } else {
            this.endLocale.date = value;
        }
    }

    updateFromForm(values: Partial<Lease>) {
        Object.assign(this, values);
    }

    get hasAccommodation(): boolean { 
        return !!(this.idAccommodation && this.idAccommodation !== 0);
    }
    
    

    public allocate(accommodation: Accommodation, defaultId: number = 0) {
        this.idAccommodation = accommodation.id ? accommodation.id : defaultId;
        this.accommodation = accommodation;
    }

}
