html
<div class="label-picker">
  <h3>Labels {{ group ? 'du groupe ' + group : 'disponibles' }}:</h3>
  <select (change)="onLabelSelect($event)" class="label-select">
    <option value="" disabled selected>Choisir un label</option>
    <option *ngFor="let label of labels" [value]="label.code">
        {{ label.code }} - {{ label.labelLong || '' }}
      </option>  </select>

  <p *ngIf="selectedLabel">Label sélectionné: {{ selectedLabel.labelLong || selectedLabel.code }}</p>
</div>