import { ITagDto } from "../../tag/tag.dto"; // Assurez-vous que ce chemin est correct
import { Tag } from "../../tag/tag.class"; // Assurez-vous que ce chemin est correct
import { IInvoiceHeaderDto } from "./invoice-header.dto";
import { InvoiceItem } from "../item/invoice-item.class";
import { IInvoiceItemDto } from "../item/invoice-item.dto";
import { Contact } from "../../contact/contact.class";
import { IContactDto } from "../../contact/contact.dto";
import { EInvoiceStatus } from "../invoice-status.enum";
import { BaseEntity } from "../../base-entity.class";

export class InvoiceHeader extends BaseEntity<IInvoiceHeaderDto> {
  invoiceId: number = 0;
  typeCode: string = "";
  idIssuer?: number;
  idIssuerOccupancy?: number | null;
  idRecipient?: number;
  idRecipientOccupancy?: number | null;
  chrono?: number;
  timezone?: string;
  dtIssueLocale?: string;
  dtStartLocale?: string;
  dtEndLocale?: string;
  dtDueLocale?: string;
  codePaymentTerms?: string;
  codeStatus?: string;
  internalRef?: string;
  externalRef?: string;
  totalExcludingVAT?: number;
  totalVAT?: number;
  total?: number;

  // tags?: Tag[]; // Assuming Tag class exists to handle ITagDto
  issuer?: Contact;
  recipient?: Contact;

  // Ajout d'un tableau pour les items de la facture
  items?: InvoiceItem[];

  constructor(dto?: IInvoiceHeaderDto) {
    super(dto);
    this.codeStatus = EInvoiceStatus.NEW;
    if (dto) this.loadDto(dto);
  }

  get totalEUR(): string {
    const formattedAmount = this.formatAmount(this.total);
    return formattedAmount ? `${formattedAmount} €` : '';
  }

  public override loadDto(dto: IInvoiceHeaderDto): void {
    super.loadDto(dto);
    for (const key in dto) {
      if (dto.hasOwnProperty(key) && 
          key !== 'tags' && 
          key !== 'items' && 
          key !== 'issuer' && 
          key !== 'recipient') {
        (this as any)[key] = (dto as any)[key];
      }
      this.setComplexProperties(dto);
    }
  }
  
  protected override setComplexProperties(dto: IInvoiceHeaderDto): void {
    if (dto.tags) {
      this.tags = dto.tags.map(tagDto => new Tag(tagDto));
    }
    if (dto.items) {
      this.items = dto.items.map(itemDto => new InvoiceItem(itemDto));
    }
    if (dto.issuer) {
      this.issuer = new Contact(dto.issuer);
    }
    if (dto.recipient) {
      this.recipient = new Contact(dto.recipient);
    }
  }

  public override getDto(): IInvoiceHeaderDto {
    super.getDto();
    const dto: IInvoiceHeaderDto = {
      invoiceId: this.invoiceId,
      typeCode: this.typeCode,
      idIssuer: this.idIssuer !== undefined ? Number(this.idIssuer) : undefined,
      idIssuerOccupancy: this.idIssuerOccupancy !== undefined ? this.idIssuerOccupancy : null,
      idRecipient: this.idRecipient !== undefined ? Number(this.idRecipient) : undefined,
      idRecipientOccupancy: this.idRecipientOccupancy !== undefined ? this.idRecipientOccupancy : null,
      chrono: this.chrono,
      timezone: this.timezone || 'Europe/Paris',
      dtIssueLocale: this.dtIssueLocale,
      dtStartLocale: this.dtStartLocale,
      dtEndLocale: this.dtEndLocale,
      dtDueLocale: this.dtDueLocale,
      codePaymentTerms: this.codePaymentTerms,
      codeStatus: this.codeStatus,
      internalRef: this.internalRef,
      externalRef: this.externalRef,
      totalExcludingVAT: this.totalExcludingVAT,
      totalVAT: this.totalVAT,
      total: this.total
    };
    
    if (this.tags) dto.tags = this.tags.map(tag => tag.getDto() as ITagDto);
    if (this.items) dto.items = this.items.map(item => item.getDto() as IInvoiceItemDto);
    if (this.issuer) dto.issuer = this.issuer.getDto() as IContactDto;
    if (this.recipient) dto.recipient = this.recipient.getDto() as IContactDto;

    return dto;
  }

  get isNew(): boolean {
    return this.invoiceId === 0;
  }

  private formatAmount(amount: number | string | null | undefined): string {
    if (amount === null || amount === undefined) {
      return '';
    }
    
    const numericAmount = Number(amount);
    if (isNaN(numericAmount)) {
      return '';
    }
    return numericAmount.toFixed(2);
  }

  protected override getMappings(): Partial<Record<keyof IInvoiceHeaderDto, keyof this>> {
    return {
        // idPerson: 'id',
        // // typePerson: 'type',
        // firstName: 'firstName',
        // surname: 'surname',
        // lastName: 'lastName',
        // idTitle: 'idTitle',
        // company: 'company',
        // siret: 'siret',
    }
}  
}
